import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useCart } from '../../contexts/CartContext';
import './CartIcon.css';

const CartIcon = () => {
  const navigate = useNavigate();
  const { cartItems } = useCart();
  const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);

  const handleShoppingCartClick = () => {
    navigate('/cart');
  };

  return (
    <Link to="/cart" className="cart-badge" data-count={totalItems}>
      <ShoppingCartIcon className="shopping-cart-icon" onClick={handleShoppingCartClick} />
    </Link>
  );
};

export default CartIcon;
