import React, { useState } from 'react';
import Button from '../../shared/Button';
import ProductGrid from '../../products/shared/ProductGrid/ProductGrid';
import { useTranslation } from '../../contexts/TranslationContext';
import './Products.css';


const ProductsList = ({ triggerHeader }) => {
  const { translate, getConstants } = useTranslation();
  const productData = getConstants('productData');
  const [visibleProducts, setVisibleProducts] = useState(3);

  const loadMoreProducts = () => {
    setVisibleProducts(visibleProducts + 3);
  };

  return (
    <>
      <div className="products">
        <div className="product-grid-container">
          {productData.slice(0, visibleProducts).map(product => (
            <ProductGrid key={product.id} product={product} triggerHeader={triggerHeader} />
          ))}
        </div>
      </div>
      {visibleProducts < productData.length && (
        <Button onClick={loadMoreProducts} className="load-more-btn">
          {translate('home.products.loadMore.button')}
        </Button>
      )}
    </>
  );
};

const Products = ({ triggerHeader }) => {
  const { translate } = useTranslation();

  return (
    <section id="products" className="products-section">
      <div className="products-header">
        <h2>{translate('home.products.title')}</h2>
        <p>{translate('home.products.subtitle.p1')}</p>
        <p>{translate('home.products.subtitle.p2')}</p>
      </div>
      <ProductsList triggerHeader={triggerHeader} />
    </section>
  );
};

export default Products;
