import React from 'react';
import { useTranslation } from '../contexts/TranslationContext';
import './Footer.css';

const Footer = () => {
  const { translate } = useTranslation();

  return (
    <footer className="footer">
      <img className='footer-logo' src={process.env.PUBLIC_URL + '/name_card_crop.png'} alt="footer logo" />
      <div className='footer-texts'>
        <h3 className="footer-title">{translate('company.title')}</h3>
        <p className="footer-subtitle">{translate('footer.subtitle')}</p>
      </div>
    </footer>
  );
};

export default Footer;
