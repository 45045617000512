export const flyToCart = (
  element,
  setFlyToCartStyles,
  setShowFlyAnimation,
  onAnimationEnd
) => {
  if (!element) return;

  // Get the element's bounding rectangle relative to the viewport
  const productRect = element.getBoundingClientRect();
  const cartIcon = document.querySelector('.cart-badge');
  const cartRect = cartIcon.getBoundingClientRect();

  // Calculate absolute positions based on the closest positioned ancestor
  const productAbsoluteLeft = productRect.left + (element.offsetParent?.offsetLeft || document.documentElement.scrollLeft);
  const productAbsoluteTop = productRect.top + (element.offsetParent?.offsetTop || document.documentElement.scrollTop);

  // Calculate the cart’s position relative to the viewport
  const cartAbsoluteLeft = cartRect.left + document.documentElement.scrollLeft;
  const cartAbsoluteTop = cartRect.top + document.documentElement.scrollTop;

  // Calculate translation values
  const translateX = cartAbsoluteLeft - productAbsoluteLeft;
  const translateY = cartAbsoluteTop - productAbsoluteTop;

  // Set the initial styles for animation with absolute positioning
  setFlyToCartStyles({
    position: 'absolute',
    top: `${productAbsoluteTop}px`,
    left: `${productAbsoluteLeft}px`,
    width: `${productRect.width}px`,
    height: `${productRect.height}px`,
    transition: 'transform 0.8s ease-in-out, opacity 0.8s ease-in-out',
    transform: `translate(${translateX}px, ${translateY}px) scale(0.3)`,
    opacity: 1,
    zIndex: 1000,
    pointerEvents: 'none',
  });

  setShowFlyAnimation(true);

  // End the animation and optionally trigger the header
  setTimeout(() => {
    setShowFlyAnimation(false);
    if (onAnimationEnd) onAnimationEnd();
  }, 1000); // Duration of the animation
};
