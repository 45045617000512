import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import emailjs from '@emailjs/browser';
import { useCart } from '../../../contexts/CartContext';
import { displayNumWithCommas } from '../../../utils';
import { getProductOrderEmails } from '../../../../emailTemplates/productOrderEmails';
import { useTranslation } from '../../../contexts/TranslationContext';
import enConstants from '../../../../constants/enConstants';
import zhConstants from '../../../../constants/zhConstants';
import PaymentMethod from '../PaymentMethod';
import './CheckoutPage.css';

const CheckoutPage = () => {
  const { translate, getConstants } = useTranslation();
  const { cartItems, totalAmount, clearCart } = useCart();
  const productData = getConstants('productData'); // Product data in the current language
  const enProductData = enConstants.productData;
  const zhProductData = zhConstants.productData;
  const translatedCartItems = cartItems.map((item) => {
    const product = productData.find((product) => product.id === item.id);
    const enProduct = enProductData.find((product) => product.id === item.id);
    const zhProduct = zhProductData.find((product) => product.id === item.id);
    return {
      ...item,
      name: product.name,
      enName: enProduct.name,
      zhName: zhProduct.name,
    };
  });
  const [isLoading, setIsLoading] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({ name: '', address: '', email: '', phone: '' });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleOrderSubmit = (e) => {
    e.preventDefault();

    // Validation: Check for empty fields and update errors
    const newErrors = {};
    if (!customerInfo.name) newErrors.name = translate('checkoutPage.form.name.errorMessage');
    if (!customerInfo.email) newErrors.email = translate('checkoutPage.form.email.errorMessage');
    if (!customerInfo.phone) newErrors.phone = translate('checkoutPage.form.phone.errorMessage');
    if (!customerInfo.address) newErrors.address = translate('checkoutPage.form.address.errorMessage');

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      // Scroll to top when validation fails
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });

      return;
    }

    setIsLoading(true);

    const params = {
      cartItems: translatedCartItems,
      customerInfo,
      totalAmount: displayNumWithCommas(totalAmount.toFixed(2)),
    };

    const templateParams = {
      clientData: customerInfo,
      emails: getProductOrderEmails(params),
    };

    emailjs
      .send('cs_private_email', 'cs_notification', templateParams, {
        publicKey: 'w08V_HWwnOiEdWyYD',
      })
      .then(
        (response) => {
          clearCart();
          navigate('/order-confirmation');
        },
        (err) => {
          console.log('FAILED...', err);
          navigate('/order-cancellation');
        },
      )
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="checkout-container">
      <div className="checkout-content">
        <div className="checkout-details">
          <h2>{translate('checkoutPage.contactInfo.header.title')}</h2>
          <form className="checkout-form">
            <div className="checkout-field">
              <label>{translate('checkoutPage.form.name.label')}</label>
              <input
                type="text"
                name="name"
                placeholder={translate('checkoutPage.form.name.placeholder')}
                value={customerInfo.name}
                onChange={handleInputChange}
                required
              />
              {errors.name && <p className="error-message">{errors.name}</p>}
            </div>
            <div className="checkout-field">
              <label>{translate('checkoutPage.form.email.label')}</label>
              <input
                type="email"
                name="email"
                placeholder={translate('checkoutPage.form.email.placeholder')}
                value={customerInfo.email}
                onChange={handleInputChange}
                required
              />
              {errors.email && <p className="error-message">{errors.email}</p>}
            </div>
            <div className="checkout-field">
              <label>{translate('checkoutPage.form.phone.label')}</label>
              <input
                type="text"
                name="phone"
                placeholder={translate('checkoutPage.form.phone.placeholder')}
                value={customerInfo.phone}
                onChange={handleInputChange}
                required
              />
              {errors.phone && <p className="error-message">{errors.phone}</p>}
            </div>
            <div className="checkout-field">
              <label>{translate('checkoutPage.form.address.label')}</label>
              <input
                type="text"
                name="address"
                placeholder={translate('checkoutPage.form.address.placeholder')}
                value={customerInfo.address}
                onChange={handleInputChange}
                required
              />
              {errors.address && <p className="error-message">{errors.address}</p>}
            </div>
          </form>
          <hr className="checkout-divider" />
          <PaymentMethod />
        </div>
        <div className="order-summary">
          <h3>{translate('checkoutPage.orderSummary.title')}</h3>
          <ul>
            {translatedCartItems.map(item => (
              <li key={item.id}>
                <p>{item.name} </p>
                <div className="order-summary-item-details">
                  <p>{translate('checkoutPage.orderSummary.qty')}: {item.quantity}</p>
                  <p>${displayNumWithCommas(item.price * item.quantity)}</p>
                </div>
              </li>
            ))}
          </ul>
          <hr />
          <h2 className="order-summary-total-amount">
            {translate('checkoutPage.orderSummary.totalAmount')}: ${displayNumWithCommas(totalAmount.toFixed(2))}
          </h2>
        </div>
      </div>
      <button
        type="submit"
        className="confirm-order-btn"
        onClick={handleOrderSubmit}
      >
        {isLoading ? <CircularProgress size="1rem" /> : translate('checkoutPage.confirmOrderBtn')}
      </button>
    </div>
  );
};

export default CheckoutPage;
