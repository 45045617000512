import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';
import { useTranslation } from '../../contexts/TranslationContext';
import { displayNumWithCommas } from '../../utils';
import './ClassCard.css';

const ClassCard = ({ classData, isDisplayOnHover = true }) => {
  const { translate } = useTranslation();
  const {
    id,
    name,
    description,
    descriptionDetails,
    price,
    imgPath,
    dates,
    duration,
  } = classData;
  const navigate = useNavigate();

  const onClassCardClick = () => {
    navigate(`/class/${id}`);
  };

  const handleBtnClicked = (e) => {
    e.stopPropagation();
    const classData = { name: name, id: id };
    navigate('/classes/book-now', { state: classData });
  };

  return (
    <div className="class-card" onClick={onClassCardClick}>
      <img src={process.env.PUBLIC_URL + imgPath[0]} alt={name} />
      <h3>{name}</h3>
      <p>{description}</p>
      {descriptionDetails && <div>{descriptionDetails}</div>}
      <hr className='class-card-divider' />
      <div className='class-card-pricing'>
        <p>${displayNumWithCommas(price)}</p>
        <p>{duration}</p>
      </div>
      {dates && <hr className='class-card-divider' />}
      {dates && <p>{dates}</p>}
      <Button
        className={`class-card-button ${isDisplayOnHover ? 'display-on-hover' : ''}`}
        size="small"
        onClick={handleBtnClicked}
      >
        {dates ? translate('classCard.bookNow.button') : translate('classCard.viewCourse.button')}
      </Button>
    </div>
  );
};

export default ClassCard;
