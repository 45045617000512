export default {
  productData: [
    {
      id: 1,
      name: '終極套裝：易經塔羅牌（1大 + 1小）+ 能量燈 + 牌陣墊',
      price: 1288,
      imgPath: ['/I Ching Cards n Gameboard.jpg', '/I Ching Cards.jpg', '/Energy Lamp.JPG'],
      description:
        <>
          <p>
            易經的智慧概述了 64 種生活情境，指導如何策略性地應對各種情況。利用這智慧，
            我們有了一種獨特的方法，使個人不單可以利用能量場提升生活品質，更可解決風水問題。
          </p>
          <p>
            我們的系統讓用家能夠輕鬆應用風水原則，營造出平衡而和諧的生活環境。
            將引導您走向更加美好、更加富足、更有意義的生活。
          </p>
          <b>
            <div>
              本套裝包含：
              <ul>
                <li>易經塔羅牌（大）80 x 140 mm</li>
                <li>易經塔羅牌（小）57 x 87 mm</li>
                <li>能量燈（可通過 USB Type C 充電）</li>
                <li>牌陣墊 60 x 60 mm</li>
              </ul>
            </div>
          </b>
        </>,
      ribbon: '暢銷',
      category: 'I Ching Oracle Cards',
    },
    {
      id: 2,
      name: '初學者套裝：易經塔羅牌（大）+ 能量燈',
      price: 888,
      imgPath: ['/I Ching Cards.jpg', '/Energy Lamp.JPG'],
      description:
        <>
          <p>
            易經的智慧概述了 64 種生活情境，指導如何策略性地應對各種情況。利用這智慧，
            我們有了一種獨特的方法，使個人不單可以利用能量場提升生活品質，更可解決風水問題。
          </p>
          <p>
            我們的系統讓用家能夠輕鬆應用風水原則，營造出平衡而和諧的生活環境。
            將引導您走向更加美好、更加富足、更有意義的生活。
          </p>
          <b>
            <div>
              本套裝包含：
              <ul>
                <li>易經塔羅牌（大）80 x 140 mm</li>
                <li>能量燈（可通過 USB Type C 充電）</li>
              </ul>
            </div>
          </b>
        </>,
      ribbon: '暢銷',
      category: 'I Ching Oracle Cards',
    },
    {
      id: 3,
      name: '基本套裝：易經塔羅牌（大）+ 能量燈 + 牌陣墊',
      price: 1088,
      imgPath: ['/I Ching Cards n Gameboard.jpg', '/I Ching Cards.jpg', '/Energy Lamp.JPG'],
      description:
        <>
          <p>
            易經的智慧概述了 64 種生活情境，指導如何策略性地應對各種情況。利用這智慧，
            我們有了一種獨特的方法，使個人不單可以利用能量場提升生活品質，更可解決風水問題。
          </p>
          <p>
            我們的系統讓用家能夠輕鬆應用風水原則，營造出平衡而和諧的生活環境。
            將引導您走向更加美好、更加富足、更有意義的生活。
          </p>
          <b>
            <div>
              本套裝包含：
              <ul>
                <li>易經塔羅牌（大）80 x 140 mm</li>
                <li>能量燈（可通過 USB Type C 充電）</li>
                <li>牌陣墊 60 x 60 mm</li>
              </ul>
            </div>
          </b>
        </>,
      category: 'I Ching Oracle Cards',
    },
    {
      id: 4,
      name: '易經塔羅牌（大）',
      price: 599,
      imgPath: ['/I Ching Cards.jpg'],
      description:
        <>
          <p>
            易經的智慧概述了 64 種生活情境，指導如何策略性地應對各種情況。利用這智慧，
            我們有了一種獨特的方法，使個人不單可以利用能量場提升生活品質，更可解決風水問題。
          </p>
          <p>
            我們的系統讓用家能夠輕鬆應用風水原則，營造出平衡而和諧的生活環境。
            將引導您走向更加美好、更加富足、更有意義的生活。
          </p>
          <b><li>易經塔羅牌（大）80 x 140 mm</li></b>
        </>,
      category: 'I Ching Oracle Cards',
    },
    {
      id: 5,
      name: '易經塔羅牌（小）',
      price: 399,
      imgPath: ['/I Ching Cards.jpg'],
      description:
        <>
          <p>
            易經的智慧概述了 64 種生活情境，指導如何策略性地應對各種情況。利用這智慧，
            我們有了一種獨特的方法，使個人不單可以利用能量場提升生活品質，更可解決風水問題。
          </p>
          <p>
            我們的系統讓用家能夠輕鬆應用風水原則，營造出平衡而和諧的生活環境。
            將引導您走向更加美好、更加富足、更有意義的生活。
          </p>
          <b><li>易經塔羅牌（小）57 x 87 mm</li></b>
        </>,
      category: 'I Ching Oracle Cards',
    },
    {
      id: 6,
      name: '能量燈',
      price: 399,
      imgPath: ['/Energy Lamp.JPG'],
      description:
        <>
          <p>
            易經的智慧概述了 64 種生活情境，指導如何策略性地應對各種情況。利用這智慧，
            我們有了一種獨特的方法，使個人不單可以利用能量場提升生活品質，更可解決風水問題。
          </p>
          <p>
            我們的系統讓用家能夠輕鬆應用風水原則，營造出平衡而和諧的生活環境。
            將引導您走向更加美好、更加富足、更有意義的生活。
          </p>
          <b><li>能量燈（可通過 USB Type C 充電）</li></b>
        </>,
      category: 'I Ching Oracle Cards',
    },
    {
      id: 7,
      name: '牌陣墊',
      price: 299,
      imgPath: ['/I Ching Cards n Gameboard.jpg'],
      description:
        <>
          <p>
            易經的智慧概述了 64 種生活情境，指導如何策略性地應對各種情況。利用這智慧，
            我們有了一種獨特的方法，使個人不單可以利用能量場提升生活品質，更可解決風水問題。
          </p>
          <p>
            我們的系統讓用家能夠輕鬆應用風水原則，營造出平衡而和諧的生活環境。
            將引導您走向更加美好、更加富足、更有意義的生活。
          </p>
          <b><li>牌陣墊 60 x 60 mm</li></b>
        </>,
      category: 'I Ching Oracle Cards',
    },
  ],
  servicesData: [
    {
      id: 1,
      name: '根源能量重塑治療',
      price: 1800,
      duration: '60 分鐘',
      imgPath: ['/energy_reform_landscape.webp'],
      description: '純潛能的領域當中，根源能量能夠越過我們習慣性的有限感知與無限潛能能量的網格相連接，轉化我們的能量狀態。根源能量可以應⽤於各種事物：身體疾病、過去的創傷、情緒和⼼理模式、⼈際關係問題、商業關切等等無論你想要轉變什麼，根源能量重塑後都能以快速輕鬆的⽅式幫助你實現。',
      descriptionDetails:
        <>
          <div>
            60分鐘治療服務包括：
            <ul>
              <li>個案諮詢</li>
              <li>協助個案找出問題根源</li>
              <li>解決不同痛症和情緒問題</li>
              <li>在平衡宇宙和解</li>
              <li>創造你所想</li>
            </ul>
          </div>
        </>,
      category: 'Subconscious Integration',
    },
    {
      id: 2,
      name: '風水分析',
      price: 15,
      priceUnit: '每平方呎',
      priceNotes: '最低收費 $6000',
      duration: '',
      imgPath: ['/fengshui.jpeg'],
      description: '風水是一種源自中國古代的實踐方法，揭示如何平衡空間的能量，以確保居住其中的人們的健康和好運。「玄空飛星學」斷事準確，以客人風水命格與家居佈局相融，設置流年飛星佈局，順應「九運」立向，平衡家居方位、陰陽及五行元素，趨旺運財、人緣、健康。',
      descriptionDetails:
        <>
          <div>
            風水分析服務包括：
            <ul>
              <li>「玄空⾶星學」 斷事準確</li>
              <li>以客⼈風⽔命格與家居佈局相融</li>
              <li>設置流年⾶星佈局</li>
              <li>順應「九運」⽴向</li>
              <li>平衡家居⽅位、陰陽及五⾏元素</li>
              <li>趨旺運財、⼈緣、健康</li>
            </ul>
          </div>
        </>,
    },
    {
      id: 3,
      name: '八字命理分析',
      price: 2200,
      duration: '60 分鐘',
      imgPath: ['/ba_zi.png'],
      description: '八字命理分析是一種古老的中國占卜方法，通過對出生日期和時間的分析，可以了解一個人的性格、運勢、事業、財運、健康等。八字命理分析可以幫助人們更好地了解自己，找到自己的優勢和劣勢，從而更好地規劃自己的生活和事業。',
    },
    {
      id: 4,
      name: '易經占卜',
      price: 980,
      duration: '30 分鐘',
      imgPath: ['/iching_cards_analysis.png'],
      description: '易經是中國古代的一本占卜經書，是中國經典中最古老的書之一。我們可透過易經占卜，了解自己的現況，找到解決問題的方法，並預測未來的發展。',
    },
    {
      id: 5,
      name: '音聲療癒工作坊（團體）',
      price: 380,
      priceUnit: '每人',
      priceNotes: '4人以上可自組時間',
      duration: '60 分鐘',
      imgPath: ['/singing_bowls.jpeg'],
      description: '音聲療癒是一種透過各種樂器的聲音頻率和振動來促進身體、心理和靈性的整體健康和平衡的療法。聲音的共鳴和共振作用有助於釋放情緒壓力、平衡身體能量、提升情緒狀態，並促進心靈的成長和覺知，產生療癒效果。 促進身心靈平衡：調整身體的能量場，幫助身體深度放鬆、釋放內在的情緒和緊張、緩解壓力、焦慮、憂鬱、失眠等身心健康問題。精油卡牌：從精油卡牌中抽取你當下最需要的精油，卡牌還會告訴你所需的能量，導師會用適合你的精油來增強治療效果。',
      category: 'Singing Bowls',
    },
  ],
  classesData: [
    {
      id: 1,
      name: '動物傳心',
      description: '你是否想知道家中的動物為什麼表現出奇怪的行為？或者你對它們對當下生活的看法感到好奇嗎？如果動物能說話，它們會告訴你什麼呢？你有興趣聽聽嗎？在人類語言發展之前，我們主要使用腦電波、心靈感應和身體語言來進行溝通。然而，隨著語言的成熟，我們的原始溝通能力逐漸減弱或消失。因此，通過這樣的課程，我們重新開啟心靈溝通的能力，這樣我們就能夠與動物、甚至植物進行溝通。溝通可以為關係帶來更積極的效果。透過心靈傳遞的方式，你可以了解動物和其他人的想法，進行輔導和思想交流，達成共識，讓雙方的關係變得更加和諧。',
      price: 3400,
      imgPath: ['/spiritual_communication_class1.webp'],
      duration: '4小時',
      category: 'Spiritual Communication',
    },
    {
      id: 2,
      name: '易經塔羅牌占卜班（初級）',
      description: '在人生中，我們經歷了無數次的抉擇，但正確與否只有在未來才能知道，而那時一切都已成為定局。易經塔羅牌提供了一種積極而有效的決策方法。特別是在已經全面考慮了主觀願望和客觀環境的情況下仍然難以取捨時，它們可以給予你明確的啟示。《易經》是中華民族文化的源頭之一，被尊為一種占卜的應用形式，實際上包含了宇宙哲理，是一部自成體系的哲學著作。它不僅體現了宇宙中的象、數、理，同時也闡述了人的生存哲學。對於人生事業、前途，它為人們樹立了遠大、正確的人生觀和理想，對培養健康的生理素質等方面起到了巨大的作用。《易經塔羅牌》以六十四幅簡單的畫作簡要闡述了每一卦的重點和其中的哲理，希望使用者能夠輕鬆自如地做出決定，從而解決問題。',
      descriptionDetails:
        <>
          <b>
            <div>
              基礎班：
              <ul>
                <li>八卦起原和背景</li>
                <li>64卦解說</li>
                <li>圖象簡單輕鬆學習64卦</li>
                <li>易經塔羅牌應用包括風水能量，流年運程</li>
              </ul>
            </div>
          </b>
        </>,
      price: 6280,
      imgPath: ['/Iching_card_class1.png'],
      duration: '10小時',
    },
    {
      id: 3,
      name: '易經塔羅牌占卜班/梅花易數（高級）',
      description: '在人生中，我們經歷了無數次的抉擇，但正確與否只有在未來才能知道，而那時一切都已成為定局。易經塔羅牌提供了一種積極而有效的決策方法。特別是在已經全面考慮了主觀願望和客觀環境的情況下仍然難以取捨時，它們可以給予你明確的啟示。《易經》是中華民族文化的源頭之一，被尊為一種占卜的應用形式，實際上包含了宇宙哲理，是一部自成體系的哲學著作。它不僅體現了宇宙中的象、數、理，同時也闡述了人的生存哲學。對於人生事業、前途，它為人們樹立了遠大、正確的人生觀和理想，對培養健康的生理素質等方面起到了巨大的作用。《易經塔羅牌》以六十四幅簡單的畫作簡要闡述了每一卦的重點和其中的哲理，希望使用者能夠輕鬆自如地做出決定，從而解決問題。',
      descriptionDetails:
      <>
        <b>
          <div>
            進階班：
            <ul>
              <li>運用易經64卦，利用數字，文字，時間去占卜運程</li>
              <li>應用和練習</li>
            </ul>
          </div>
        </b>
      </>,
      price: 3400,
      imgPath: ['/Iching_card_class1.png'],
      duration: '5小時',
    },
    {
      id: 4,
      name: '沈氏玄空飛星班',
      description: '在人生中，命運、運氣和風水都扮演著重要的角色。風水在這三者當中佔有第三的位置，這顯示了它的重要性。風水實際上是指宇宙的能量，並對地球上的人和事產生影響。如果我們能夠瞭解這種宇宙能量並運用它，就能夠達到趨吉避凶的效果！這將大大提升個人的運氣，使事情順利進行，事半功倍！',
      descriptionDetails:
        <>
          <b>
            <div>
              風水班：
              <ul>
                <li>什麼是風水 / 沈氏玄空飛星</li>
                <li>如何起命卦 / 風水盤</li>
                <li>三元九運與二元八運</li>
                <li>怎樣選擇風水化物</li>
                <li>解說雙星斷事 / 陽宅三十則</li>
                <li>如何應用沈氏玄空飛星去趨吉避凶</li>
                <li>實習堂</li>
              </ul>
            </div>
          </b>
        </>,
      price: 12000,
      imgPath: ['/feng_shui_class.png'],
      duration: '20小時',
    },
    {
      id: 5,
      name: '八字命理學（基礎）',
      description: '命理學的核心在於命局，簡單來說，一個人的命運取決於其出生年月日時的天干地支的組合，也就是所謂的「八字」。這些八字不僅代表著一個人的命運，也是他們人生劇本的基礎。通過學習命理方法並觀察命局中的五行元素，我們可以靈活運用八字命理來管理情緒健康、改善人際關係以及制定生活計劃。一旦深入理解了自己的人生劇本，我們可以運用相應的方法去詮釋，讓我們的生活變得豐盛多彩。',
      descriptionDetails:
        <>
          <b>
            <div>
              基礎班：
              <ul>
                <li>八字來由、基礎</li>
                <li>五行相生相剋 /五行性情編</li>
                <li>排八字大運 /神煞篇</li>
                <li>八字與宮位的關係</li>
                <li>十神關係和格局</li>
                <li>八字判強弱 /判喜忌</li>
                <li>五行與疾病</li>
                <li>名人八字個案分析（網課：共6小時）</li>
              </ul>
            </div>
          </b>
        </>,
      price: 8400,
      imgPath: ['/ba_zi_class1.png'],
      duration: '24小時',
    },
    {
      id: 6,
      name: '八字命理學（進階）',
      description: '命理學的核心在於命局，簡單來說，一個人的命運取決於其出生年月日時的天干地支的組合，也就是所謂的「八字」。這些八字不僅代表著一個人的命運，也是他們人生劇本的基礎。通過學習命理方法並觀察命局中的五行元素，我們可以靈活運用八字命理來管理情緒健康、改善人際關係以及制定生活計劃。一旦深入理解了自己的人生劇本，我們可以運用相應的方法去詮釋，讓我們的生活變得豐盛多彩。',
      descriptionDetails:
        <>
          <b>
            <div>
              進階班：
              <ul>
                <li>實習批判八字</li>
                <li>姓名學</li>
                <li>如何擇日</li>
                <li>通勝應用</li>
                <li>真實八字個案分析（網課：共6小時）</li>
              </ul>
            </div>
          </b>
        </>,
      price: 8400,
      imgPath: ['/ba_zi_class2.webp'],
      duration: '24小時',
    },
  ],
  testimonialsData: [
    {
      id: 1,
      text: '我非常推薦風水諮詢，它給我的生活空間和能量帶來了正面的轉變。',
      name: 'Jane Doe',
    },
    {
      id: 2,
      text: '靈性舒緩課程非常平靜且令人振奮。我感覺自己更能與內在的自我聯繫。',
      name: 'John Smith',
    },
    {
      id: 3,
      text: '易靈科學研究所的全方位健康方法非常出色。我在生活中經歷了深刻的改變。',
      name: 'Emily Chen',
    },
    {
      id: 4,
      text: '易靈科學研究所的全方位健康方法非常出色。我在生活中經歷了深刻的改變。',
      name: 'Emily Chen',
    },
    {
      id: 5,
      text: '易靈科學研究所的全方位健康方法非常出色。我在生活中經歷了深刻的改變。',
      name: 'Emily Chen',
    },
  ],
  iChingCardsData: [
    {
      id: 1,
      imgPath: '/I Ching Cards Hexagram1.jpeg',
      title: '第一卦：《乾為天》- 乾宮',
      subTitle: '凜凜皇者之象；自強不息之意。',
      overview: '事業有成，地位卓越。',
      relationships: '剛強好勝，容易產生摩擦。',
      career: '能力強，能夠獨當一面。',
      wealth: '財富豐裕，處於興隆時期。',
      advice: '得此卦者在能力上並不是問題，最大挑戰是如何維持現有地位。持續創造自身 價值非常重要，這樣才能實現長期穩定成功。',
    },
    {
      id: 2,
      imgPath: '/I Ching Cards Hexagram2.jpeg',
      title: '第二卦：《坤為地》- 坤宮',
      subTitle: '相容萬物之象；以柔克剛之意。',
      overview: '順其自然，穩扎穩打地前進。低調謙遜的人往往獲得更多利益，寬容包容的人也會獲得更多回報。',
      relationships: '雙方互相理解包容，感情能夠穩定發展。',
      career: '穩扎穩打，腳踏實地努力拼搏，尊重既有制度，就一定能取得成功。',
      wealth: '適合求取正財，不宜投機炒作。',
      advice: '謙虛退讓的人會得到福祉，而過於積極主動的人反而容易一事無成。對於既定的目標和想要實現的事情，應該保持恆心和堅持，這樣才能最終成功。過於急躁和急於求成很容易導致失敗。更適合跟隨他人，而不宜獨自擔當重任。',
    },
    {
      id: 3,
      imgPath: '/I Ching Cards Hexagram3.jpeg',
      title: '第三卦：《水雷屯》- 坎宮',
      subTitle: '春木更生之象；起頭艱難之意。',
      overview: '凡事起頭難。在艱難時刻，適宜培養實力，奠定根基，為未來成功做好準備。',
      relationships: '困難接踵而來，一波未平，一波又起。處於風雨飄搖之中，前景未明。',
      career: '創業難，應更注重自身實力的培養和基礎的奠定。',
      wealth: '需要更加謹慎地管理財務。',
      advice: '屯卦代表著「困難」，但並非代表失敗。如果能夠積累實力，度過「凡事起頭難」的階段，未來仍有無限的可能。',
    },
    {
      id: 4,
      imgPath: '/I Ching Cards Hexagram4.jpeg',
      title: '第四卦：《山水蒙》- 離宮',
      subTitle: '童蒙啟智之象；迷惘摸索之意。',
      overview: '能求教於人，則有助於事情的進展。',
      relationships: '需要更多時間去理解和認識對方。',
      career: '能力不足以應付，需要再多方學習，向他人求教。',
      wealth: '應謹慎投資，避免冒險。',
      advice: '能力不足以應對危機，最佳策略是向有經驗與智慧的人請益。但請益之前，請先做好準備，誠心前往，避免反覆詢問。',
    },
    {
      id: 5,
      imgPath: '/I Ching Cards Hexagram5.jpeg',
      title: '第五卦：《水天需》- 坤宮',
      subTitle: '密雲不雨之象；靜待時機之意。',
      overview: '龍困淺灘，時機未到，耐心等待時機為宜。',
      relationships: '需要耐心等待和溝通。',
      career: '雖有能力，但目前還沒有合適的機會。',
      wealth: '耐心等待收益，直到負面因素消除後，財運自然通泰。',
      advice: '耐心等待好機會降臨，再全力以赴，必定最終成功。靜待佳機，才是較好的策略。',
    },
    {
      id: 6,
      imgPath: "/I Ching Cards Hexagram6.jpeg",
      title: "第六卦：《天水訟》- 離宮",
      subTitle: "背道而馳之象；無端訟起之意。",
      overview: "是非之爭不休。",
      relationships: "陷入衝突和紛爭。",
      career: "面臨競爭和糾紛，亦有法律風險。",
      wealth: "需謹慎處理財務事宜，避免陷入爭議和危險。",
      advice: "注意福禍相依，得到的未必是福，失去的未必是禍。過度堅持是危險的，凡事不可走向極端，不要頑固己見。能夠放棄爭鬥才是吉祥之兆，安定為福。若一意孤行到底，即便取得勝利，也可能遭致禍患。"
    },
    {
      id: 7,
      imgPath: "/I Ching Cards Hexagram7.jpeg",
      title: "第七卦：《地水師》- 坎宮",
      subTitle: "地勢臨淵之象；以德服眾之意。",
      overview: "任重道遠，憂慮重重。然正道必將戰勝邪惡。",
      relationships: "雙方各執一詞，需有一方願意引導支持，否則難免紛爭。",
      career: "責任重大，挑戰重重。必須持之以恆奮鬥，長期經營才能有所成就。",
      wealth: "勞苦則獲，當求取正當之財，切忌投機取巧。唯有艱辛付出方能獲得世間財富。",
      advice: "帶兵打仗首先要師出有名，必需合情合理合法，名正言順。用人之際，當選擇老練有經驗者，絕不可用無經驗者。"
    },
    {
      id: 8,
      imgPath: "/I Ching Cards Hexagram8.jpeg",
      title: "第八卦：《水地比》- 坤宮",
      subTitle: "眾星拱月之象；安逸進取之意。",
      overview: "乘風而行，和諧共生。以愛心待人，福澤眾生。",
      relationships: "男方地位尊崇，女方恭順賢淑。夫婦和諧，家庭興旺。未婚女子要防範第三者介入。",
      career: "得到貴人相助，事業有所成就。",
      wealth: "合作投資有利，循序漸進，互利共贏。",
      advice: "凡事多為他人着想，廣結善緣，必能贏得人心。切忌目中無人，見利忘義，以身試法，最後眾叛親離。"
    },
    {
      id: 9,
      imgPath: "/I Ching Cards Hexagram9.jpeg",
      title: "第九卦：《風天小畜》- 巽宮",
      subTitle: "密雲無雨之象；蓄養實力之意。",
      overview: "小事亨通，滴水成河，持續努力，終能大成。",
      relationships: "需要耐心積累感情和經驗。",
      career: "謹慎做好準備，等待時機來臨。",
      wealth: "似有若無，以守為攻，方為上策。",
      advice: "未來充滿不確定，當今之計可以退守，放下遠大的目標，活在當下，專注於眼前可實現的小目標。"
    },
    {
      id: 10,
      imgPath: "/I Ching Cards Hexagram10.jpeg",
      title: "第十卦：《天澤履》- 艮宮",
      subTitle: "如履薄冰之象；險中求勝之意。",
      overview: "伴君如伴虎，謹言慎行，安分守己。",
      relationships: "不好服侍的母老虎/老太爺，須以相敬如賓的態度對待。",
      career: "必須嚴格遵循規矩，事事依規定而行，注意上下級職責分工，否則必定出現重大錯誤。",
      wealth: "隨順時勢而動，見好就收。",
      advice: "凡事都需格外謹慎小心，戒懼如履薄冰，一切務必依規而行，才能順遂亨通。尤其要注重人際關係，清楚分辨上下尊卑，恪守應對之道。"
    },
    {
      id: 11,
      imgPath: "/I Ching Cards Hexagram11.jpeg",
      title: "第十一卦：《地天泰》- 坤宮",
      subTitle: "萬事通泰之象；上下和睦之意。",
      overview: "事業及生活平順亨通，成功安泰之大吉卦。",
      relationships: "雙方情投意合，伴侶關係穩定，天生一對。",
      career: "幹事乾淨利索，上下和諧合作無間。",
      wealth: "資產豐厚，財務狀況穩健有餘。",
      advice: "雖然眼下一切井然有序，但仍需防患未然，不可掉以輕心。應保持警惕，時刻做好風險防控，以免陷入危機。只有如此，才能夠長期維持現有的美好局面， 福澤綿延。"
    },
    {
      id: 12,
      imgPath: "/I Ching Cards Hexagram12.jpeg",
      title: "第十二卦：《天地否》- 乾宮",
      subTitle: "閉塞不通之象；陰陽不交之意。",
      overview: "目前情況閉塞不通，面臨諸多困難和分歧。",
      relationships: "雙方難以進行有效溝通和互相理解。",
      career: "事業進展不順利，遇到諸多阻礙和挫折。",
      wealth: "正遭遇經濟困難和財務上的損失。",
      advice: "除了靜待機會，等待否極泰來，更要主動檢視和調整自己的行為和計劃。同時要積極尋找新的解決方法來克服當前的困難。通過堅持不懈的努力、靈活應變的態度和保持冷靜沉著，定能夠克服眼前的困難，找到解決方案，並最終取得進步。"
    },
    {
      id: 13,
      imgPath: "/I Ching Cards Hexagram13.jpeg",
      title: "第十三卦：《天火同人》- 離宮",
      subTitle: "君子同心之象；共同合作之意。",
      overview: "與人結盟，協助而成功。",
      relationships: "感情和睦，夫妻和諧，相互關懷和支持，為彼此創造美好的生活。",
      career: "有利於團隊合作，共同努力達成目標。各方通力合作，定能取得成功。",
      wealth: "得益於良好的人際關係，有利於獲得財富。也善於維護人際網絡，可以左右逢源，財源廣進。",
      advice: "這是一個非常吉祥的卦象，鼓勵你要堅定信心，重視與他人的合作和互助。通過團隊協作，你一定能夠順利實現自己的目標，並獲得豐碩的回報。同時也要懂得與他人分享成果，回饋社會。"
    },
    {
      id: 14,
      imgPath: "/I Ching Cards Hexagram14.jpeg",
      title: "第十四卦：《火天大有》- 乾宮",
      subTitle: "日麗中天之象；辛勤豐收之意。",
      overview: "吉兆連連，百事亨通。運勢如日中天，大吉大利。",
      relationships: "愛情甜蜜，幸福美滿。",
      career: "吉星高照，事業順利。",
      wealth: "財源廣進，收益豐碩。",
      advice: "大有是極其吉祥的卦象，凡事皆大吉。這是一個鼓勵你要堅信自己的潛力，勇敢追求夢想並實現目標的卦象。只要你以勤勉工作、卓越領導力和慷慨大方的態度，定能成就輝煌，獲得豐碩回報。同時，更要懂得與他人分享你的成就和財富，為社會貢獻自己的一份力量。"
    },
    {
      id: 15,
      imgPath: "/I Ching Cards Hexagram15.jpeg",
      title: "第十五卦：《地山謙》- 兌宮",
      subTitle: "空谷藏玉之象；虛懷處世之意。",
      overview: "凡事謙遜小心，學會以退為進，以守為攻，才能順利開展。",
      relationships: "要學會退讓，理解對方需求。",
      career: "功成不居，虛心退讓，需要謹慎行事，避免冒險。",
      wealth: "細水長流之財，穩扎穩打求財為上策。",
      advice: "謙卦是修養自己，以德服人的態度。因為虛心，所以更為富足；因為謙遜，所以崇高；功成不居，所以更偉大。以此態度，必能獲得更多智慧、成長和人際關係上的豐盛。"
    },
    {
      id: 16,
      imgPath: "/I Ching Cards Hexagram16.jpeg",
      title: "第十六卦：《雷地豫》- 震宮",
      subTitle: "雷奮大地之象；事見新機之意。",
      overview: "順利開展，順從民意和民意做事即可成功。",
      relationships: "愉快融洽的關係。但要避免桃花困擾。",
      career: "順利進展和取得成功。",
      wealth: "收入增加和財富穩定。",
      advice: "面對困難時要保持警惕，防範潛在風險。同時保持樂觀心態，相信自己能克服困難，找到解決方法。請順應變化，保持和諧平衡，以積極態度面對挑戰。以這樣的態度，定能克服難關，創造更好的結果。"
    },
    {
      id: 17,
      imgPath: "/I Ching Cards Hexagram17.jpeg",
      title: "第十七卦：《澤雷隨》- 震宮",
      subTitle: "順水行舟之象；隨遇而安之意。",
      overview: "因為得失無常，所以要清靜無為，順其自然，把握適當的時機，選擇最有利的狀況去進行。",
      relationships: "夫唱婦隨。",
      career: "出現了兩難情況，很難取捨。不要太在意得失，隨順自然而行就好。",
      wealth: "和氣生財，不應冒險從事不擅長的投資，應該務實一些。",
      advice: "凡事都要順其自然，隨緣而行，做讓自己開心就好，不要強求過度。但切記不要把隨緣當成隨便行事，否則事情會走樣。"
    },
    {
      id: 18,
      imgPath: "/I Ching Cards Hexagram18.jpeg",
      title: "第十八卦：《山風蠱》- 巽宮",
      subTitle: "物腐蟲生之象；不進則退之意。",
      overview: "已經出現腐敗和停滯不前的低迷局面，要當心受騙上當。",
      relationships: "受到蠱惑影響，意亂情迷，一定要小心被騙。",
      career: "危險重重、陷阱叢生，絕對不能輕舉妄動。",
      wealth: "一蹶不振，也要小心被騙。",
      advice: "蠱卦面對的是一種積重難返的深層問題，不僅是解決眼前的事。所以在力求解決眼前問題的同時，更重要的是要重新擬定新的計劃，建立嶄新的未來。"
    },
    {
      id: 19,
      imgPath: "/I Ching Cards Hexagram19.jpeg",
      title: "第十九卦：《地澤臨》- 坤宮",
      subTitle: "居高臨下之象；正道漸盛之意。",
      overview: "上位者應親自下去檢查督促下屬，了解民情。有了互動交流，事情才能順利進行。",
      relationships: "有一方表現過於強硬的跡象。應該學會和悅及柔順的態度。",
      career: "前景樂觀，有貴人提供幫助。發展空間很大，只要努力經營定能有所收穫。",
      wealth: "正財方面吉祥，不宜過於追求偏財。",
      advice: "請保持內心的喜悅，在關注和督促事情時也要保持溫和的態度和方式。通過貴人的幫助，你將能夠完成任務，並且要抓住適當的時機才能成功。"
    },
    {
      id: 20,
      imgPath: "/I Ching Cards Hexagram20.jpeg",
      title: "第二十卦：《風地觀》- 乾宮",
      subTitle: "風雲莫測之象；觀察入微之意。",
      overview: "凡事都應該虔誠認真地觀察、學習，自然就能有所收穫。",
      relationships: "是一種遙望而單戀的愛情。",
      career: "應該用心觀察上司的做事方式，這將有助於你自己的發展。",
      wealth: "應該謹慎考慮，不宜過於激進。",
      advice: "這個卦象吉祥的關鍵在於能夠靠近事物的本質，認真地觀察事物的發展變化，從中充實自己。相反，如果無法近距離仔細觀察，就會是凶兆。但即使能夠接近，如果只是外行看熱鬧，也是徒然的。"
    },
    {
      id: 21,
      imgPath: "/I Ching Cards Hexagram21.jpeg",
      title: "第二十一卦：《火雷噬嗑》- 巽宮",
      subTitle: "嚴刑峻法之象；防患未然之意。",
      overview: "相互之間存在對抗和競爭，可能引發衝突和破壞。",
      relationships: "有摩擦，需要摩合。留意有第三者介入。",
      career: "內部存在小人從中作梗，公司內部人事不和，是非頻生。",
      wealth: "投資不利。",
      advice: "在這個卦象中，最需要注意的是避免衝動和情緒用事，避免陷入無謂的口舌之爭。相反，需要冷靜思考和採取恰當的措施來消除問題的根源，並將可能的損害降到最低。這需要理性分析和明智的行動。"
    },
    {
      id: 22,
      imgPath: "/I Ching Cards Hexagram22.jpeg",
      title: "第二十二卦：《山火賁》- 艮宮",
      subTitle: "華而不實之象；粉飾裝扮之意。",
      overview: "表面光鮮亮麗，但內在缺乏實質。",
      relationships: "虛偽的交往關係。",
      career: "需要展現才華和魅力，才能取得成功。",
      wealth: "流行的投資項目都不要去做，保守謹慎為上策。",
      advice: "請注意培養自己的外在形象和內在修養，不斷提升自己，追求卓越。同時，保持自信和自尊，培養內心的平靜與平衡。透過這樣的態度，你將能展現自己的美麗和吸引力，實現內外的和諧。"
    },
    {
      id: 23,
      imgPath: "/I Ching Cards Hexagram23.jpeg",
      title: "第二十三卦：《山地剝》- 乾宮",
      subTitle: "群陰剝陽之象；必有所損之意。",
      overview: "諸事不佳，運勢低迷沮喪，切勿輕舉妄動。",
      relationships: "風流不專一，有許多情人且缺乏節制。小心人財兩失。",
      career: "小心遇到意圖騙錢的公司或事情。",
      wealth: "血本無歸。",
      advice: "在此不利的形勢下，正能量即將被耗盡。現在不是採取重大行動的時候。最好的選擇是冷靜觀察局勢，等待形勢的轉變，當環境進入新的循環時再重新開始。這需要耐心等待和在適當時候採取行動。"
    },
    {
      id: 24,
      imgPath: "/I Ching Cards Hexagram24.jpeg",
      title: "第二十四卦：《地雷復》- 坤宮",
      subTitle: "萬物更新之象；重修破舊之意。",
      overview: "逆境逐漸退去，好運漸漸而來。",
      relationships: "出現複雜情況或回到過去。",
      career: "尋找新的機會，現在正是最佳時機。",
      wealth: "之前被限制的部分逐步得到解決。",
      advice: "在逆境中保持韌性和希望。重新整理自己，並從過去的經驗中獲得智慧和見解。請保持希望和信心，相信困難只是暫時的，未來會有轉機和改善。通過這樣的視角，你將能夠克服挑戰，經歷復興與重生，迎接新的機會和挑戰。堅持不懈，積極面對困難將帶給你更大的成長和成功。"
    },
    {
      id: 25,
      imgPath: "/I Ching Cards Hexagram25.jpeg",
      title: "第二十五卦：《天雷無妄》- 巽宮",
      subTitle: "飛來橫禍之象；守正安常之意。",
      overview: "無可逃的災難，最睿智的做法是保持冷靜沉著，以平常心接受現狀。不當的行為只會招致更大的禍患。",
      relationships: "誤解多於理解，好事變成壞事，波折重重。",
      career: "接二連三的意外困難，遠超你的應變能力。",
      wealth: "投資理財越多，財務損失也就越大。",
      advice: "面對災難時，不要陷入怨恨不平的情緒，也不要做出魯莽的舉動，因為那只會使局勢雪上加霜、無法挽回。保持鎮定，恪遵道德規範 - 這才是渡過難關的道路。切記不要被貪慾和超出自身能力的不切實際夢想所蠱惑。"
    },
    {
      id: 26,
      imgPath: "/I Ching Cards Hexagram26.jpeg",
      title: "第二十六卦：《山天大畜》- 艮宮",
      subTitle: "淺水行舟之象；積少成大之意。",
      overview: "活力充沛，積蓄力量，時機成熟後大有所為。",
      relationships: "愛情事業雙豐收，兩者相輔相成。",
      career: "備受重用，事業有成，功名顯赫。",
      wealth: "穩中有進，發展前景廣闊。",
      advice: "您的事業前景極佳。勇敢前行，定能獲得重用。務必積極努力，堅持不懈地追求目標。通過不斷積累智慧與經驗，您將能夠在成功與富裕中茁壯成長，為未來的挑戰做好準備。保持謹慎冷靜的態度，將有助於您在事業上取得更大成就。"
    },
    {
      id: 27,
      imgPath: "/I Ching Cards Hexagram27.jpeg",
      title: "第二十七卦：《山雷頤》- 巽宮",
      subTitle: "慎言節食之象；休養生息之意。",
      overview: "行事應保持低調謙遜，不宜過於張揚。",
      relationships: "應關注和照顧好伴侶的情感需求。",
      career: "需要鞏固基本能力，防範口舌是非，同時不斷充實自我技能。",
      wealth: "投資理財上應偏向保守，選擇低風險的方式為佳。",
      advice: "如果謀事的時機還未到，最好再閉關修練。在各個方面都要保持沉靜、自制的態度，小心防範可能引起口舌的事項，無論言語還是飲食。保持克制和慎重的心態，有利於避免不必要的困擾。"
    },
    {
      id: 28,
      imgPath: "/I Ching Cards Hexagram28.jpeg",
      title: "第二十八卦：《澤風大過》- 震宮",
      subTitle: "不勝負荷之象；壓力太大之意。",
      overview: "壓力過大，難以承擔。需要另尋出路和解決之道。",
      relationships: "過於遷就和忍讓，關係難以長久。",
      career: "有將無兵，難以成就大事。身心俱備，卻仍風波不斷。",
      wealth: "存在經濟壓力和困境，需要仔細的理財規劃和謹慎投資。",
      advice: "凡事不可勉強，也不可為了表面的和諧而逆來順受，更不能因為想當個好人而盲目地一味接受。遇事必須量力而為，明智地判斷何時進退。如果不切實際地做出超出自身能力的挑戰，最終只會得到勞而無功的結果。"
    },
    {
      id: 29,
      imgPath: "/I Ching Cards Hexagram29.jpeg",
      title: "第二十九卦：《坎為水》- 坎宮",
      subTitle: "重重險陷之象；險象環生之意。",
      overview: "內外皆充滿危險，處境險峻艱難。",
      relationships: "陷入僵局，難以突破。",
      career: "前進後退皆有困難重重。",
      wealth: "陷入困境，有破財之象。輸少而當贏多，宜及早止損，免致家財耗盡。",
      advice: "在此逆境中，需以冷靜堅定的態度，方能克服重重難關，獲得成長。"
    },
    {
      id: 30,
      imgPath: "/I Ching Cards Hexagram30.jpeg",
      title: "第三十卦：《離為火》- 離宮",
      subTitle: "麗日當天之象；光明遠大之意。",
      overview: "知曉柔順中庸之道，事事吉祥如意。若失此，則事多不順。",
      relationships: "展現最動人之姿，互生情愫，正值溫馨蜜月。",
      career: "人脈廣闊，得貴人相助，一帆風順。但務須腳踏實地，不可雄心壯志過高。",
      wealth: "營業額尚可，然實際收益卻有待改善。",
      advice: "此卦雖有吉兆，然有所條件。若能循序漸進，柔順中庸，自然事事如意;否則必有變數。亦須留意外表華美，內在是否實在充實，避免陷於巧取豪奪之道；或者人是否流於小聰明，而缺乏圓融的智慧。"
    },
    {
      id: 31,
      imgPath: "/I Ching Cards Hexagram31.jpeg",
      title: "第三十一卦：《澤山咸》- 兌宮",
      subTitle: "兩性交感之象；往來無阻之意。",
      overview: "前景吉祥，桃花盛開，人緣熱絡。善用和諧之力，必將事業有成。",
      relationships: "男女情深，恩愛綿綿，婚姻幸福美滿。",
      career: "財富日增，經濟局勢大好。投資選擇與內心契合的項目，將更加有利。",
      wealth: "財富增長，經濟形勢改善。選擇與內心特別契合的投資項目會更有利。",
      advice: "已與事物產生共鳴共振。無論內心想的是什麼，都很可能在現實中實現。順勢而為，必能逢凶化吉。"
    },
    {
      id: 32,
      imgPath: "/I Ching Cards Hexagram32.jpeg",
      title: "第三十二卦：《雷風恆》- 震宮",
      subTitle: "夫婦恆常之象；努力不懈之意。",
      overview: "安定穩定，細水長流。",
      relationships: "穩定幸福，可以白頭偕老。",
      career: "穩定，各司其職，但缺乏變革與創新，缺少成長的動力。",
      wealth: "財富持續穩健增長，經濟保持繁榮。",
      advice: "如果你所希望的就是穩定，那麼這個卦象是大吉之兆;但如果你渴望挑戰與成長，那麼這個卦相則隱含未來成長的障礙。因為過於穩定，將導致缺乏變革與創新。"
    },
    {
      id: 33,
      imgPath: "/I Ching Cards Hexagram33.jpeg",
      title: "第三十三卦：《天山遯》- 乾宮",
      subTitle: "明哲保身之象；退隱泉林之意。",
      overview: "遁卦之吉祥之處在於能夠置身事外，遠離紛爭是非，而非因為有所成就。",
      relationships: "對方並不適合，能夠避開就盡量避開為好。",
      career: "無法取得成就，只能設法保全自己。以退為進是最佳選擇。",
      wealth: "必將有經濟方面的收縮和節制，暫時休息一下是最好的。",
      advice: "小人得志的時候，不要想著功成名就，而是要盡快遁遠遁深，躲得越快越遠越好。放下功名利祿，隱居深山，才是最佳出路，不要固執己見。"
    },
    {
      id: 34,
      imgPath: "/I Ching Cards Hexagram34.jpeg",
      title: "第三十四卦：《雷天大壯》- 坤宮",
      subTitle: "雷電交加之象；聲威大壯之意。",
      overview: "小吉，氣勢不凡，但若名氣過高必須經得起考驗。",
      relationships: "嘴巴上說很愛，但卻無心，甚至此亂終棄。",
      career: "宜低調保守持平。忌大張旗鼓行事，否則必將失敗。",
      wealth: "表面風光，但內心卻感到不太滿足。",
      advice: "此卦為雷嗚天下，景象壯盛，勢頭銳不可當。但凡事應保持冷靜，審慎分析形勢，不要魯莽行事，這樣便能避免不必要的錯誤和災難。"
    },
    {
      id: 35,
      imgPath: "/I Ching Cards Hexagram35.jpeg",
      title: "第三十五卦：《火地晉》- 乾宮",
      subTitle: "滿地錦繡之象；良臣遇君之意。",
      overview: "吉，勇往直前，無往不利。",
      relationships: "獲得異性的喜愛和青睞。",
      career: "受到重視，能發揮所長，連續晉升三級，升官加薪。",
      wealth: "無需承擔任何風險，能夠穩定持續獲利。",
      advice: "此為大吉之卦，聰明才智必將受到肯定。這是一個極為吉祥的時期，您可以放開手腳，勇敢前行。無論感情、事業還是財富，都會有不錯的表現和發展。只要秉持謹慎而積極的態度，定能在各方面都獲得圓滿的成就。"
    },
    {
      id: 36,
      imgPath: "/I Ching Cards Hexagram36.jpeg",
      title: "第三十六卦：《地火明夷》- 坎宮",
      subTitle: "大地無光之象；棄明投暗之意。",
      overview: "在黑暗的環境中，一切事務都無法順利進行。這是陷入困頓的不利境遇。",
      relationships: "一切都不順遂。局勢變動不定，最好是退避三舍，以保全自己。",
      career: "百般不順，凡事明哲保身，絕對不要輕舉妄動。",
      wealth: "收支失衡，難免財資逐漸消耗殆盡。",
      advice: "局勢混亂，無論求財或事業都毫無進展。現階段不應過分追求功名利祿，而是應當以自保、維生為首要任務。明哲保身是明智之策。雖然現在的處境艱難，但請不要失去希望。只要保持堅韌的意志，定能渡過難關，找到新的機遇，相信光明定能降臨。",
    },
    {
      id: 37,
      imgPath: "/I Ching Cards Hexagram37.jpeg",
      title: "第三十七卦：《風火家人》- 巽宮",
      subTitle: "齊家治國之象；開花結果之意。",
      overview: "因人際關係良好而運勢好。",
      relationships: "婚姻幸福，女人賢慧，可以旺夫。",
      career: "有貴人相助，獲上級關愛。諸事自然順利，水到渠成。",
      wealth: "有貴人相助，財運亨通。",
      advice: "家人團結一致，其力量堅如鋼鐵。秉持道德倫理，盡責履職，利己利他。凡事以團隊精神以對，超越凡俗的力量倍增。但若自私自利，則難免遭至親朋好友的背棄。",
    },
    {
      id: 38,
      imgPath: "/I Ching Cards Hexagram38.jpeg",
      title: "第三十八卦：《火澤睽》- 艮宮",
      subTitle: "兩相乖違之象；意氣相爭之意。",
      overview: "人事不和，若能獨力完成任務則會有一些好運，否則應該努力解決人際關係的問題。",
      relationships: "雙方的價值觀不合，不如早日分手。",
      career: "慎防小人中傷，人緣差，孤軍奮戰。",
      wealth: "求財是非多，和氣才可生財。",
      advice: "人與人之間的意見不合並不一定是壞事，重要的是學會包容和諧，尊重彼此的不同，保持和睦相處。讓「不同」不變成「不和」是需要學習的智慧。",
    },
    {
      id: 39,
      imgPath: "/I Ching Cards Hexagram39.jpeg",
      title: "第三十九卦：《水山蹇》- 兌宮",
      subTitle: "舉步維艱之象；前路難行之意。",
      overview: "運途運勢困頓，面臨重重險阻，切勿輕舉妄動。",
      relationships: "目前感情困頓，無法有進展。",
      career: "面臨著困難和挑戰，應該明智地知難而退，重新閉關學習和成長。",
      wealth: "要提防破財風險，保本為先。",
      advice: "在各方面都不宜急於行動。儘管前路艱辛，但只要堅持到底，成功是有希望的。",
    },
    {
      id: 40,
      imgPath: "/I Ching Cards Hexagram40.jpeg",
      title: "第四十卦：《雷水解》- 震宮",
      subTitle: "逃離厄運之象；遇困可解之意。",
      overview: "惡運正在消退，好運即將降臨。",
      relationships: "之前的誤解和隔閡可以得到解決。對於長久相愛的情侶來說，可以考慮談婚論嫁。否則，可能會分手而無法修復。",
      career: "困難的路已經走過，現在正是重新出發的時候。",
      wealth: "谷底反彈回升。",
      advice: "當面臨各種情況時要積極主動，因為困難在短期內自然可以應對成功。但切記，在時機未成熟時不要急於魯莽行事，同樣也不要在平日無事時自找麻煩。",
    },
    {
      id: 41,
      imgPath: "/I Ching Cards Hexagram41.jpeg",
      title: "第四十一卦：《山澤損》- 艮宮",
      subTitle: "損下益上之象；先損後益之意。",
      overview: "只要你能夠擁有長遠的眼光，願意暫時做出一些小小的犧牲，並明智地管理資源和能量，那麼美好的日子仍然可以期待。",
      relationships: "勞民傷財的戀愛。",
      career: "宜犧牲個人以成就長官，放長線釣大魚。",
      wealth: "財務上會有損失。",
      advice: "當面對各種事情時，要有長遠的眼光，不要只計較眼前的得失和利害。如果能放下這種狹隘的觀念，則可以享受順利而無需承受任何過失的好運。然而，如果只關注眼前的利益，而忽視了更大的局勢，則這將成為一個重大的不祥之兆。",
    },
    {
      id: 42,
      imgPath: "/I Ching Cards Hexagram42.jpeg",
      title: "第四十二卦：《風雷益》- 巽宮",
      subTitle: "損上益下之象；貴人輔助之意。",
      overview: "情場得意，官財兩旺之好運。",
      relationships: "男歡女愛，天作之合。",
      career: "積極投入工作，多機會，自我充實，得到上司賞識，將有益於未來升遷。",
      wealth: "順水行舟，財運亨通。",
      advice: "這個卦象代表著上升的利益超越了下降的損失。它提醒你抓住機會，異性貴人眾多。在自己能力範圍內多幫助他人，這樣可以建立廣泛的良好關係。無論是在工作、事業還是感情上，都有望取得成功，實現雙贏的最終目標，既有利於自己也有利於他人。但是切忌自私自利，只追求享受而不付出。如果只顧著自己而不願意付出，最終好運也會消逝無蹤。",
    },
    {
      id: 43,
      imgPath: "/I Ching Cards Hexagram43.jpeg",
      title: "第四十三卦：《澤天夬》- 坤宮",
      subTitle: "水滿成災之象；果斷決策之意。",
      overview: "面臨棘手的人或事問題，需要勇敢面對並快速做出決定。",
      relationships: "對象任性而難纏，宜畫清界限。",
      career: "出現人事鬥爭問題。如果可能，可以施恩來拉攏人心，但要小心不要自食其果。",
      wealth: "需要謹慎考慮和評估風險。在需要的時候要果斷行動，並懂得止損。",
      advice: "內心要果斷，但手段要委婉。這個卦提醒你快速做出重要的抉擇，才能解決問題。",
    },
    {
      id: 44,
      imgPath: "/I Ching Cards Hexagram44.jpeg",
      title: "第四十四卦：《天風姤》- 乾宮",
      subTitle: "男女邂逅之象；聚散隨緣之意。",
      overview: "這只是一時的偶遇，無法持久。",
      relationships: "這只是一場短暫的相遇，不應當真。對於女性而言，若不忠或過於強勢，不宜嫁娶。",
      career: "偶爾會有些運氣。女性在事業上較為順利，而男性則需要順從女性的指揮。",
      wealth: "短期內會有好運，但長期而言會帶來不吉利的結果。對女性有利。",
      advice: "既然這只是一場「邂逅」，任何好運都只是暫時的，不應該基於此做出長遠的計劃。姤卦是專屬於女性的卦象，男性在諸事上不宜主導，可能需要聽從女性的指示。女性則有能力掌握整個局勢。女性可能會遇到有助益的人，但應當警惕桃花糾纏。",
    },
    {
      id: 45,
      imgPath: "/I Ching Cards Hexagram45.jpeg",
      title: "第四十五卦：《澤地萃》- 兌宮",
      subTitle: "鯉躍龍門之象；精英會萃之意。",
      overview: "盡享天時地利人和的優勢，名氣日盛。",
      relationships: "受眾人歡迎，經常被人所包圍。",
      career: "有貴人相助，事事亨通。",
      wealth: "聚集各方錢財，財運興隆。",
      advice: "萃卦預示著個人事務能夠順利進展，對於團結眾人共同謀事或尋求德高望重的長者的見識會帶來好處。在各方面的事情上，宜採取隆重而盛大的舉動，不宜過於節約吝嗇。這個卦象對於長途旅行是有利的，但不適合退縮守舊。",
    },
    {
      id: 46,
      imgPath: "/I Ching Cards Hexagram46.jpeg",
      title: "第四十六卦：《地風升》- 震宮",
      subTitle: "風從地起之象；從弱成強之意。",
      overview: "宜積極進取，如有神助，萬事亨通。",
      relationships: "彼此遷就，互相體諒。感情升溫。",
      career: "腳踏實地可以穩定成長，若有貴人提攜，則可加快晉升。",
      wealth: "正財運穩定上升，但這不是偏財運。",
      advice: "成長原本就是緩慢而需要時間的，但只要耐心等候，有朝一日一定可成。若能找到貴人相助，也可加快事情的進展。切忌眼高手低。",
    },
    {
      id: 47,
      imgPath: "/I Ching Cards Hexagram47.jpeg",
      title: "第四十七卦：《澤水困》- 兌宮",
      subTitle: "龍游淺水之象；艱困險阻之意。",
      overview: "山窮水盡，窮困潦倒。",
      relationships: "無緣莫強求。",
      career: "舉步為艱，沒有前途可言。",
      wealth: "窮困潦倒，有破產甚至負債的危險。",
      advice: "我們應當堅守道德原則，謹慎行，才能夠在困境中尋求變通。 在這種情況下，不僅過多言語會導致失利，還可能招來羞辱。只有展現出無論貧窮與否都保持高尚品德的氣節，經過時間的累積必將獲得轉機。",
    },
    {
      id: 48,
      imgPath: "/I Ching Cards Hexagram48.jpeg",
      title: "第四十八卦：《水風井》- 震宮",
      subTitle: "汲水育民之象；井井有條之意。",
      overview: "互助互利有利，乃平安之運。 必須開發資源，提攜推薦人才，方能人盡其才、物盡其用。",
      relationships: "兩人互相扶持，是一對好伴侶。",
      career: "培養好自己的專業能力，日積月累自然可成。",
      wealth: "財運一般，保守為宜。",
      advice: "井道重在自清，水清則人群至，德善則人心服。 凡事抱著互助之心、廣結善緣，重視團隊合作、默契與精神，於平凡的日常生活中彰顯個人價值，為社會人群做出貢獻，福報必然如影隨形。 需要團隊合作和資源共享。 最怕急功近利，往往災害由此而生。",
    },
    {
      id: 49,
      imgPath: "/I Ching Cards Hexagram49.jpeg",
      title: "第四十九卦：《澤火革》- 坎宮",
      subTitle: "煉金成器之象；破舊立新之意。",
      overview: "革新則吉無過，守舊則兇惡。",
      relationships: "舊歡如夢，新愛將至。",
      career: "必須透過改革手段，方可成事。先苦後甜，努力有成。",
      wealth: "創新求財，才有希望。",
      advice: "若能自我反省並進行改革，將會帶來吉祥。若自己不願意革新，固守舊有不變，期待別人改變，結果會導致革命的發生。改革成功之後，必須採取懷柔政策和溫和手段來安撫民心，才能使一切圓滿順利。",
    },
    {
      id: 50,
      imgPath: "/I Ching Cards Hexagram50.jpeg",
      title: "第五十卦：《火風鼎》- 離宮",
      subTitle: "鼎器烹調之象；去故取新之意。",
      overview: "大吉，凡事宜同心協力，合作共同成就偉業，將會迎來新的景象。會有貴人提供協助。",
      relationships: "對於那些正在期待愛情的人來說，很快就可能會出現一段新的戀情。對於已經有伴侶的人來說，要小心有第三者加入的可能性。",
      career: "有貴人相扶，成功上位。有得權之象。",
      wealth: "大利合作求財，財運亨通。",
      advice: "若能以穩健的行事態度，坦誠正直地行事，信守承諾，並贏得他人的認同和大力支持，成功將會是必然的。但切忌表面光鮮而內心虛假，過於追求功績和虛榮，否則很可能會先取得一時的成功，卻最終以失敗告終。",
    },
    {
      id: 51,
      imgPath: "/I Ching Cards Hexagram51.jpeg",
      title: "第五十一卦：《震為雷》- 震宮",
      subTitle: "雷驚百里之象；驚恐不屈之意。",
      overview: "先恐懼，後喜慶。變化無常，大好大壞。",
      relationships: "有情變的危險，若能謹慎小心處理，則能夠化解。",
      career: "事情有突然的變動。戒慎恐懼則吉。",
      wealth: "如在雲霄飛車，起伏不定。",
      advice: "面對變動若能因為恐懼而有所警惕，謹慎處事，則有驚無險，因此而得福。反之，若對變動麻木不仁，則可能有後來的災害。",
    },
    {
      id: 52,
      imgPath: "/I Ching Cards Hexagram52.jpeg",
      title: "第五十二卦：《艮為山》- 艮宮",
      subTitle: "重山關鎖之象；步步為營之意。",
      overview: "原地打轉，毫無進展，一事無成。",
      relationships: "擦身而過，無法見面。阻礙重重。",
      career: "停頓，發展非常困難。",
      wealth: "財運不開，一動不如一靜。",
      advice: "應當停止的時候就停止，千萬不要輕舉妄動。守住本份，求得不犯錯，才是最佳策略。",
    },
    {
      id: 53,
      imgPath: "/I Ching Cards Hexagram53.jpeg",
      title: "第五十三卦：《風山漸》- 艮宮",
      subTitle: "旭日東昇之象；循序漸進之意。",
      overview: "勤力進取可以有功，但應當循序漸進。",
      relationships: "從友人開始，戀情可穩步發展。",
      career: "分段擬定目標，按部就班，循序漸進，可以有所獲得。",
      wealth: "越來越好，適合搵中求勝。",
      advice: "不要急於尋求捷徑，不能急躁地進取。 遵循禮法的原則，按部就班地逐步前進，那麼一切事情都會大吉大利。",
    },
    {
      id: 54,
      imgPath: "/I Ching Cards Hexagram54.jpeg",
      title: "第五十四卦：《雷澤歸妹》- 兌宮",
      subTitle: "少女追男之象；錯失端正之意。",
      overview: "在受種委屈的折磨下，內心充滿了鬱結，無法平息的憤怒與難過。",
      relationships: "這是一段段苦戀，犧牲、受傷的總是自己。",
      career: "工作範圍與職位不相符，難免心生委屈。",
      wealth: "小心因財失義。",
      advice: "在每件事情上都要謹守道德準則，符合人情世故。在採取行動之前，我們應該先了解周遭的客觀環境和情緒，謹慎地前進，以免因為自己的一廂情願而落得自食苦果。",
    },
    {
      id: 55,
      imgPath: "/I Ching Cards Hexagram55.jpeg",
      title: "第五十五卦：《雷火豐》- 坎宮",
      subTitle: "豐滿盛大之象；享通得利之意。",
      overview: "大有所獲，亨通得利之豐收吉卦。",
      relationships: "熱戀中，羡煞旁人。",
      career: "當時得令，如日中天。",
      wealth: "財運豐厚。",
      advice: "豐收的時刻令人振奮，也讓人羨慕不已。在善用他人的同時，我們需要不斷努力並保持謙遜，才能讓成功和豐收的狀態持續下去。然而，從古至今，事物都存在著極端必然帶來反面效應的規律。因此，我們必須時刻警惕並堅守中庸之道。要懂得在享受幸福的同時，保持節制。若我們變得傲慢自大、貪得無厭，甚至背信棄義，種下種種惡因緣，當好運耗盡時，將面臨自取滅亡的結果。",
    },
    {
      id: 56,
      imgPath: "/I Ching Cards Hexagram56.jpeg",
      title: "第五十六卦：《火山旅》- 離宮",
      subTitle: "居無定所之象；漂泊尋安之意。",
      overview: "舟車勞頓、頻撲，前途充滿了不確定性。",
      relationships: "歷盡千辛萬苦，要好好珍惜。",
      career: "不穩定。前面有一段崎嶇不平的路要走。",
      wealth: "財運不濟，守財為上。",
      advice: "代表事情狀況不穩定，外在形勢與條件不利於己，小事可以亨通，大事則凶。吉道在於守靜，多看多觀察而少行動。",
    },
    {
      id: 57,
      imgPath: "/I Ching Cards Hexagram57.jpeg",
      title: "第五十七卦：《巽為風》- 巽宮",
      subTitle: "隨風飄蕩之象；瞬息萬變之意。",
      overview: "順應風向擺動，隨著情勢而行動，都能取得成功。時運變化多端，我們應該謹慎應對。",
      relationships: "感情狀況多變化。需要注重細節和表達愛意。",
      career: "順勢而為，而多參考正確的建議。",
      wealth: "好壞一瞬間，保守為上。",
      advice: "凡事應以柔順的態度應對，逐漸進行，都有可能取得成功。宜適應外在的變化，內心保持著方寸之地，手段成熟而圓融，不要迷失自我，記住保持主見，以免被他人利用。",
    },
    {
      id: 58,
      imgPath: "/I Ching Cards Hexagram58.jpeg",
      title: "第五十八卦：《兌為澤》- 兌宮",
      subTitle: "與眾同樂之象；有譽有譏之意。",
      overview: "人緣好，能以喜悅來驅動人，無往不利。",
      relationships: "感情融洽。",
      career: "人氣旺。適於娛樂事業。",
      wealth: "財運好，心情佳。",
      advice: "喜悅是一股驅使人們前進的強大力量，如果能善加利用，人們甚至會冒著生命危險為之奮鬥。然而，我們應該避免阿諛奉承和不真誠的言辭。在喜悅中忘記自我節制，很容易說出失態的話語，從而引發麻煩。",
    },
    {
      id: 59,
      imgPath: "/I Ching Cards Hexagram59.jpeg",
      title: "第五十九卦：《風水渙》- 離宮",
      subTitle: "風吹水盪之象；隨波渙散之意。",
      overview: "當下成功得意的人，需防運勢正在轉弱之中。",
      relationships: "正在熱戀中的戀人，突然間冷下來。",
      career: "臨時生變，故力量散渙。好事多磨，大家都不積極，在短期內沒成功的希望。",
      wealth: "財務上的波動和變化，需要謹慎管理和理性決策。",
      advice: "危險可以克服和解決。然而，要在各方面獲得成功相對較為困難，主要問題在於人們的心思容易分散。若能對此問題有針對性地處理，將整體利益放在首位，先暫時抑制情緒，全力以赴，努力完成自己的責任和承諾。最忌諱消極的態度。如此一來，就能夠稍微取得一些成就。",
    },
    {
      id: 60,
      imgPath: "/I Ching Cards Hexagram60.jpeg",
      title: "第六十卦：《水澤節》- 坎宮",
      subTitle: "蓄水成塘之象；勤檢克己之意。",
      overview: "適當節制可小亨通。不節制則凶，需要謹守分制，兼具了調節功效。",
      relationships: "進退有時，忌操之過急。",
      career: "適合從低做起，穩健的邁開腳步才是成功之道。",
      wealth: "量入為出。樂觀謹慎穩中求，切忌貪得無厭。",
      advice: "若缺乏適當的節制，將會導致散漫和混亂；而過度的節制則可能使人變得過於嚴苛和吝嗇，最終走向貧困。因此，我們應留意如何達到平衡和中庸之道。在長遠來看，建立制度是最佳的節制方式。",
    },
    {
      id: 61,
      imgPath: "/I Ching Cards Hexagram61.jpeg",
      title: "第六十一卦：《風澤中孚》- 艮宮",
      subTitle: "以誠待人之象；一諾千金之意。",
      overview: "注重誠信的人，運勢亨通，自有貴人相助。",
      relationships: "忠誠的伴侶，互相信賴。",
      career: "只要實在、誠懇的工作，必定漸入佳境景。",
      wealth: "正財運強，偏財運無望。",
      advice: "若本著誠信與人相交，並經常創造愉快的工作氛圍與他人合作，無論從事何種事務都會獲益良多。最忌利用他人對自己的信任，言而無信，甚至虛情假意，這樣的行為最終必將自毀前程。",
    },
    {
      id: 62,
      imgPath: "/I Ching Cards Hexagram62.jpeg",
      title: "第六十二卦：《雷山小過》- 兌宮",
      subTitle: "貪多務失之象；矯枉過正之意。",
      overview: "注意人際關係，謹言慎行，不要得罪人。退守、以靜制動為宜。",
      relationships: "給雙方時間同空間，待冷靜後再說。",
      career: "凡事退一步為吉，進一步、往上爬則凶。凡事以和為貴。",
      wealth: "小心因為失去人和而破財。",
      advice: "當發現錯誤時，若能及早納改、回歸正道，則萬事亨通。在糾正的過程中，以柔和委婉的態度提醒對方是合適的，同時應細心觀察對方的反應，隨時察言觀色，留意對方是否感到不快。若能及時將重要的事情變得輕微，次要的事情視為無足輕重，才能避免引發不和諧的負面後果。",
    },
    {
      id: 63,
      imgPath: "/I Ching Cards Hexagram63.jpeg",
      title: "第六十三卦：《水火既濟》- 坎宮",
      subTitle: "陰陽和諧之象；上下相通之意。",
      overview: "天時、地利、人和配合得宜，無往不利。",
      relationships: "感情基礎穩固、金童玉女的絕配。",
      career: "時空環境相配，重要的人事和方案皆得宜，深得民心。從而使計劃成功，凡事如以償，大功告成。",
      wealth: "財運亨通。",
      advice: "若只顧及眼前的完美狀態，而缺乏遠見，必然會帶來近期的困憂；離遭遇凶險的境地也不會太遠。",
    },
    {
      id: 64,
      imgPath: "/I Ching Cards Hexagram64.jpeg",
      title: "第六十四卦：《火水未濟》- 離宮",
      subTitle: "陰陽失調之象；上下不通之意。",
      overview: "所謀求之事，一時間難望成功、相去甚遠的現象。",
      relationships: "無論單戀、苦戀、總不適合。大家背道而馳。",
      career: "尚未成功，仍需努力不懈。",
      wealth: "無財運可言，小心破財。",
      advice: "當遇到意見不合的情況時，切記不要各自堅持己見。應互相包容，追求差異的共同點，進而無障礙地進行溝通，方能達成目標。",
    },
  ],
};
