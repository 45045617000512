import React, { createContext, useContext, useState, useEffect } from 'react';
import en from '../../locales/en';
import zh from '../../locales/zh';
import enConstants from '../../constants/enConstants';
import zhConstants from '../../constants/zhConstants';

const translations = { en, zh };
const TranslationContext = createContext();

export const useTranslation = () => useContext(TranslationContext);

export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'zh');

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  const translate = (key) => {
    return key.split('.').reduce((acc, part) => acc?.[part], translations[language]) || key;
  };

  const getConstants = (dataName) => {
    if (language === 'en') {
      return enConstants[dataName];
    }
    return zhConstants[dataName];
  };

  const switchLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <TranslationContext.Provider value={{ translate, getConstants, switchLanguage, language }}>
      {children}
    </TranslationContext.Provider>
  );
};
