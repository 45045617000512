import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import emailjs from '@emailjs/browser';
import { useTranslation } from '../../contexts/TranslationContext';
import Button from '../Button';
import enConstants from '../../../constants/enConstants';
import zhConstants from '../../../constants/zhConstants';
import { getClassesEmails } from '../../../emailTemplates/classesEmails';
import { getServicesEmails } from '../../../emailTemplates/servicesEmails';
import './ApptPage.css';

const ApptPage = ({ apptType }) => {
  const { translate, getConstants } = useTranslation();
  const servicesData = getConstants('servicesData');
  const enServicesData = enConstants.servicesData;
  const zhServicesData = zhConstants.servicesData;
  const classesData = getConstants('classesData');
  const enClassesData = enConstants.classesData;
  const zhClassesData = zhConstants.classesData;
  const location = useLocation();
  const incomingData = location.state;
  const [isLoading, setIsLoading] = useState(false);

  const [apptInfo, setApptInfo] = useState({
      name: '',
      email: '',
      phone: '',
      [apptType === 'service' ? 'serviceType' : 'classType']: incomingData ? incomingData.name : '',
      message: ''
    });
  const navigate = useNavigate();

  const getTranslatedData = () => {
    const translatedData = {};
    if (apptType === 'service') {
      const enService = enServicesData.find(service => service.id === incomingData.id);
      const zhService = zhServicesData.find(service => service.id === incomingData.id);
      translatedData.enType = enService.name ? enService.name : '';
      translatedData.zhType = zhService.name ? zhService.name : '';
    } else if (apptType === 'class') {
      const enClass = enClassesData.find(classInfo => classInfo.id === incomingData.id);
      const zhClass = zhClassesData.find(classInfo => classInfo.id === incomingData.id);
      translatedData.enType = enClass.name ? enClass.name : '';
      translatedData.zhType = zhClass.name ? zhClass.name : '';
    }
    return translatedData;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setApptInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handleApptFormSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const params = {
      ...apptInfo,
      ...getTranslatedData(),
    };

    const templateParams = {
      clientData: {
        ...apptInfo,
      },
      emails: apptType === 'service' ?
        getServicesEmails(params) : getClassesEmails(params),
    };

    emailjs
      .send('cs_private_email', 'cs_notification', templateParams, {
        publicKey: 'w08V_HWwnOiEdWyYD',
      })
      .then((response) => {
        if (apptType === 'service') {
          setApptInfo({
            name: '',
            email: '',
            phone: '',
            serviceType: incomingData ? incomingData.name : '',
            message: ''
          });
          navigate('/services/booking-confirmation');
        } else {
          setApptInfo({
            name: '',
            email: '',
            phone: '',
            classType: incomingData ? incomingData.name : '',
            message: ''
          });
          navigate('/classes/booking-confirmation');
        }
      }, (err) => {
        console.log('FAILED...', err);
        if (apptType === 'service') {
          navigate('/services/booking-cancellation')
        } else {
          navigate('/classes/booking-cancellation');
        }
      });
  };

  const getDropdownOptions = () => {
    if (apptType === 'service') {
      return servicesData.map(service => <option key={service.id} value={service.name}>{service.name}</option>);
    }
    if (apptType === 'class') {
      return classesData.map(classInfo => <option key={classInfo.id} value={classInfo.name}>{classInfo.name}</option>);
    }
    return null;
  };

  const getStringsKey = () => {
    if (apptType === 'service') return 'servicesAppointmentPage';
    if (apptType === 'class') return 'classesAppointmentPage';
    return '';
  };
  
  const stringsKey = getStringsKey();

  return (
    <div className="appt-container">
      <h1>{translate(stringsKey + '.header.title')}</h1>
      <form className="appt-form">
        <div className="appt-form-field">
          <label>{translate(stringsKey + '.form.name.label')}</label>
          <input
            type="text"
            name="name"
            placeholder={translate(stringsKey + '.form.name.placeholder')}
            value={apptInfo.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="appt-form-field">
          <label>{translate(stringsKey + '.form.email.label')}</label>
          <input
            type="email"
            name="email"
            placeholder={translate(stringsKey + '.form.email.placeholder')}
            value={apptInfo.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="appt-form-field">
          <label>{translate(stringsKey + '.form.phone.label')}</label>
          <input
            type="text"
            name="phone"
            placeholder={translate(stringsKey + '.form.phone.placeholder')}
            value={apptInfo.phone}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="appt-form-field">
          <label>{translate(stringsKey + '.form.apptType.label')}</label>
          <select
            type="text"
            name={apptType === 'service' ? 'serviceType' : 'classType'}
            value={apptInfo[apptType === 'service' ? 'serviceType' : 'classType']}
            onChange={handleInputChange}
            required
          >
            {getDropdownOptions()}
          </select>
        </div>
        <div className="appt-form-field">
          <label>{translate(stringsKey + '.form.message.label')}</label>
          <p>{translate(stringsKey + '.form.message.caption')}</p>
          <textarea
            type= "text"
            name="message"
            rows="8"
            cols="50"
            value={apptInfo.message}
            onChange={handleInputChange}
            required
          />
        </div>
        <p className="appt-form-btn-caption">{translate(stringsKey + '.disclaimer.message')}</p>
        <Button
          size="medium"
          className="appt-form-submit-btn"
          onClick={handleApptFormSubmit}
        >
          {isLoading ? <CircularProgress size="1rem" /> : translate(stringsKey + '.sendBtn')}
        </Button>
      </form>
    </div>
  );
};

export default ApptPage;
