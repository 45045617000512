import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../../contexts/CartContext';
import { useTranslation } from '../../../contexts/TranslationContext';
import { displayNumWithCommas } from '../../../utils';
import Button from '../../../shared/Button';
import './ShoppingCartPage.css';


const ShoppingCartPage = () => {
  const { translate, getConstants } = useTranslation();
  const { cartItems, removeFromCart, updateQuantity, totalAmount } = useCart();
  const productData = getConstants('productData');
  const translatedCartItems = cartItems.map((item) => {
    const product = productData.find((product) => product.id === item.id);
    return {
      ...item,
      name: product.name,
      description: product.description,
    };
  });
  const navigate = useNavigate();

  return (
    <div className="cart-container">
      <h1>{translate('shoppingCart.header.title')}</h1>
      <div className="cart-items">
        {translatedCartItems.length > 0 ? (
          translatedCartItems.map((item) => (
            <div key={item.id} className="cart-item">
              <img src={process.env.PUBLIC_URL + item.imgPath[0]} alt={item.name} className="cart-item-image" />
              <div className="cart-item-info">
                <h3>{item.name}</h3>
                <div className="cart-item-description">
                  <p>${displayNumWithCommas(item.price)}</p>
                  <div className="cart-item-actions">
                    <button onClick={() => updateQuantity(item.id, item.quantity - 1)}>-</button>
                    <span>{item.quantity}</span>
                    <button onClick={() => updateQuantity(item.id, item.quantity + 1)}>+</button>
                  </div>
                  <button onClick={() => removeFromCart(item.id)} className="remove-item-btn">{translate('shoppingCart.removeItemBtn')}</button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>{translate('shoppingCart.emptyCart.message')}</p>
        )}
      </div>
      {translatedCartItems.length > 0 && (
        <>
          <div className="cart-total-amount">{translate('shoppingCart.totalAmount')}: ${displayNumWithCommas(totalAmount)}</div>
          <Button size="large" onClick={() => navigate('/checkout')} className="checkout-btn">{translate('shoppingCart.checkoutBtn')}</Button>
        </>
      )}
    </div>
  );
};

export default ShoppingCartPage;
