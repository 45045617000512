import React from 'react';
import { useTranslation } from '../../../contexts/TranslationContext';
import './Header.css';

const Header = () => {
  const { translate } = useTranslation();

  return (
    <div className="header-section">
      <img
        src={process.env.PUBLIC_URL + '/energy_reform_landscape.webp'}
        alt="Products Page Header"
        className="header-image"
      />
      <div className="header-content">
        <h1>{translate('productsPage.header.title')}</h1>
        <p>{translate('productsPage.header.subtitle')}</p>
      </div>
    </div>
  );
};

export default Header;
