import React from 'react';
import YouTube from 'react-youtube';
import {  useTranslation } from '../../contexts/TranslationContext';
import './SubconsciousIntegration.css';

const SubconsciousIntegration = () => {
  const options = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1,
      controls: 1,
    },
  };

  const _onReady = (event) => {
    event.target.pauseVideo();
  };

  const { translate } = useTranslation();

  return (
    <div className="subconscious-integration-section">
      <div className="subconscious-integration-container">
        <div className="subconscious-integration-sub-section">
          <h2 className="subconscious-integration-header">
            {translate('products.category.subconsciousIntegration.about.header')}
          </h2>
          <p>{translate('products.category.subconsciousIntegration.about.content.p1')}</p>
          <p>{translate('products.category.subconsciousIntegration.about.content.p2')}</p>
          <div className="youtube-player-container">
            <YouTube videoId="L2T7w6wnBBA" options={options} onReady={_onReady}/>
          </div>
        </div>
        <div className="subconscious-integration-sub-section">
          <h2 className="subconscious-integration-header">
            {translate('products.category.subconsciousIntegration.demo.header')}
          </h2>
          <p>{translate('products.category.subconsciousIntegration.demo.content')}</p>
          <div className="youtube-player-container">
            <YouTube videoId="hCDrt2tiQBA" options={options} onReady={_onReady}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubconsciousIntegration;