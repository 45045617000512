import React from 'react';
import { useNavigate } from "react-router-dom";
import Button from '../../shared/Button';
import { useTranslation } from '../../contexts/TranslationContext';
import './AboutUs.css';

const AboutUs = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();

  const handleLearnMoreClick = () => {
    navigate('/about');
  };

  return (
    <section id="about" className="about-us">
      <h2>{translate('home.about.title')}</h2>
      <div className="about-content">
        <img src={process.env.PUBLIC_URL + '/pallas_profile_pic.jpg'} alt="Founder" />
        <div className="text-content">
          <h3>{translate('home.about.subtitle')}</h3>
          <p>{translate('home.about.content.p1')}</p>
          <p>{translate('home.about.content.p2')}</p>
          <Button size="medium" onClick={handleLearnMoreClick}>{translate('home.about.button')}</Button>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
