export const getProductOrderEmails = (params) => {
  const emailSubject = `${params.customerInfo.name}的新產品訂單 Has a New Product Order`;
  const emailContent = `<p>您好 易靈科學研究所：</p>
    <p>&nbsp;</p>
    <p>您收到了一份來自 ${params.customerInfo.name} 的產品訂單。請確認客戶已付款後，把訂單寄出。<br>以下是聯絡資訊和訂單摘要：</p>
    <p>聯絡資訊：</p>
    <ul>
      <li style="font-weight: bold;"><strong>姓名: ${params.customerInfo.name}</strong></li>
      <li style="font-weight: bold;"><strong>電郵: ${params.customerInfo.email}</strong></li>
      <li style="font-weight: bold;"><strong>電話: ${params.customerInfo.phone}</strong></li>
      <li style="font-weight: bold;"><strong>地址: ${params.customerInfo.address}</strong></li>
    </ul>
    <p>訂單摘要：</p>
    <table style="width: 80.8623%; height: 101.5px;" border="1">
      <thead>
        <tr style="height: 28.5px;">
          <th scope="col" style="text-align: center;">產品名稱</th>
          <th scope="col" style="text-align: center;">數量</th>
          <th scope="col" style="text-align: center;">單價</th>
          <th scope="col" style="text-align: center;">總價</th>
        </tr>
      </thead>
      <tbody>
        ${params.cartItems.map((item) => `
          <tr style="height: 48.5px;">
            <th scope="row" style="text-align: center;">${item.zhName}</th>
            <td style="text-align: center;">${item.quantity}</td>
            <td style="text-align: center;">${item.price}</td>
            <td style="text-align: center;">${item.price * item.quantity}</td>
          </tr>
        `).join('')}
      </tbody>
      <tfoot>
        <tr>
          <th scope="row" colspan="3" style="text-align: right;"><strong>總計:</strong></th>
          <td style="text-align: center;"><strong>$${params.totalAmount}</strong></td>
        </tr>
      </tfoot>
    </table>
    <p><span style="text-decoration: underline;"><strong>總計金額: $${params.totalAmount}</strong></span></p>
    <p>&nbsp;</p>
    <p><br>祝好，<br>EmailJS 團隊</p>
    <p>&nbsp;</p>
    <hr />
    <p>&nbsp;</p>
    <p>Hello SpiritualScience Institute,</p>
    <p>&nbsp;</p>
    <p>You got a new product order from ${params.customerInfo.name}. Please confirm payment has been received and ship out the products. <br>Below is the contact information and order summary:</p>
    <p>Contact Information:</p>
    <ul>
      <li style="font-weight: bold;"><strong>Name: ${params.customerInfo.name}</strong></li>
      <li style="font-weight: bold;"><strong>Email: ${params.customerInfo.email}</strong></li>
      <li style="font-weight: bold;"><strong>Phone: ${params.customerInfo.phone}</strong></li>
      <li style="font-weight: bold;"><strong>Address: ${params.customerInfo.address}</strong></li>
    </ul>
    <p>Order Summary: </p>
    <table style="width: 80.8623%; height: 101.5px;" border="1">
      <thead>
        <tr style="height: 28.5px;">
          <th scope="col" style="text-align: center;">Product Name</th>
          <th scope="col" style="text-align: center;">Quantity</th>
          <th scope="col" style="text-align: center;">Unit Price</th>
          <th scope="col" style="text-align: center;">Total Price</th>
        </tr>
      </thead>
      <tbody>
        ${params.cartItems.map((item) => `
          <tr style="height: 48.5px;">
            <th scope="row" style="text-align: center;">${item.enName}</th>
            <td style="text-align: center;">${item.quantity}</td>
            <td style="text-align: center;">${item.price}</td>
            <td style="text-align: center;">${item.price * item.quantity}</td>
          </tr>
        `).join('')}
      </tbody>
      <tfoot>
        <tr>
          <th scope="row" colspan="3" style="text-align: right;"><strong>Total:</strong></th>
          <td style="text-align: center;"><strong>$${params.totalAmount}</strong></td>
        </tr>
      </tfoot>
    </table>
    <p><strong><span style="text-decoration: underline;">Total: $${params.totalAmount}</span></strong></p>
    <p>&nbsp;</p>
    <p>Best wishes,<br>EmailJS team</p>
    <p>&nbsp;</p>
  `;

  const emailAutoReplySubject = `已收到您的新產品訂單 Your New Product Order has been received`;
  const emailAutoReplyContent = `<p>您好 ${params.customerInfo.name}：</p>
    <p>&nbsp;</p>
    <p>您的訂單已成功提交。請透過 PayMe，FPS，支付寶或微信支付支付訂單金額，並於 24 小時內通過電郵或 WhatsApp 傳回付款憑證完成訂單。</p>
    <p>我們將在收到付款後開始處理您的訂單。</p>
    <p>聯絡資訊：</p>
    <ul>
      <li style="font-weight: bold;"><strong>姓名: ${params.customerInfo.name}</strong></li>
      <li style="font-weight: bold;"><strong>電郵: ${params.customerInfo.email}</strong></li>
      <li style="font-weight: bold;"><strong>電話: ${params.customerInfo.phone}</strong></li>
      <li style="font-weight: bold;"><strong>地址: ${params.customerInfo.address}</strong></li>
    </ul>
    <p>訂單摘要：</p>
    <table style="width: 80.8623%; height: 101.5px;" border="1">
      <thead>
        <tr style="height: 28.5px;">
          <th scope="col" style="text-align: center;">產品名稱</th>
          <th scope="col" style="text-align: center;">數量</th>
          <th scope="col" style="text-align: center;">單價</th>
          <th scope="col" style="text-align: center;">總價</th>
        </tr>
      </thead>
      <tbody>
        ${params.cartItems.map((item) => `
          <tr style="height: 48.5px;">
            <th scope="row" style="text-align: center;">${item.zhName}</th>
            <td style="text-align: center;">${item.quantity}</td>
            <td style="text-align: center;">${item.price}</td>
            <td style="text-align: center;">${item.price * item.quantity}</td>
          </tr>
        `).join('')}
      </tbody>
      <tfoot>
        <tr>
          <th scope="row" colspan="3" style="text-align: right;"><strong>總計:</strong></th>
          <td style="text-align: center;"><strong>$${params.totalAmount}</strong></td>
        </tr>
      </tfoot>
    </table>
    <p><strong><span style="text-decoration: underline;">總計金額: $${params.totalAmount}</span></strong></p>
    <p>祝您有美好的一天！</p>
    <p>&nbsp;</p>
    <p>祝好，<br>易靈科學研究所</p>
    <p><span style={{ fontSize: '10pt' }}>電子郵件：<a href="mailto:cs@ichingspiritual.com">cs@ichingspiritual.com</a></span></p>
    <p>&nbsp;</p>
    <hr />
    <p>&nbsp;</p>
    <p>Hello ${params.customerInfo.name},</p>
    <p>&nbsp;</p>
    <p>Your order has been successfully been placed. Please complete the order by sending payment through PayMe, FPS, Alipay or WeChatPay and send us a proof back through Email or WhatsApp within 24 hours.&nbsp;</p>
    <p>We will start preparing your order once payment is received.&nbsp;</p>
    <p>Contact Information:</p>
    <ul>
      <li style="font-weight: bold;"><strong>Name: ${params.customerInfo.name}</strong></li>
      <li style="font-weight: bold;"><strong>Email: ${params.customerInfo.email}</strong></li>
      <li style="font-weight: bold;"><strong>Phone: ${params.customerInfo.phone}</strong></li>
      <li style="font-weight: bold;"><strong>Address: ${params.customerInfo.address}</strong></li>
    </ul>
    <table style="width: 80.8623%; height: 101.5px;" border="1">
      <thead>
        <tr style="height: 28.5px;">
          <th scope="col" style="text-align: center;">Product Name</th>
          <th scope="col" style="text-align: center;">Quantity</th>
          <th scope="col" style="text-align: center;">Unit Price</th>
          <th scope="col" style="text-align: center;">Total Price</th>
        </tr>
      </thead>
      <tbody>
        ${params.cartItems.map((item) => `
          <tr style="height: 48.5px;">
            <th scope="row" style="text-align: center;">${item.enName}</th>
            <td style="text-align: center;">${item.quantity}</td>
            <td style="text-align: center;">${item.price}</td>
            <td style="text-align: center;">${item.price * item.quantity}</td>
          </tr>
        `).join('')}
      </tbody>
      <tfoot>
        <tr>
          <th scope="row" colspan="3" style="text-align: right;"><strong>Total:</strong></th>
          <td style="text-align: center;"><strong>$${params.totalAmount}</strong></td>
        </tr>
      </tfoot>
    </table>
    <p><span style="text-decoration: underline;"><strong>Total: $${params.totalAmount}</strong></span></p>
    <p>Have a great day.</p>
    <p>&nbsp;</p>
    <p>Best wishes,<br>SpiritualScience Institute</p>
    <p><span style={{ fontSize: '10pt' }}>Email: <a href="mailto:cs@ichingspiritual.com">cs@ichingspiritual.com</a></span></p>
    <p>&nbsp;</p>
  `;

  return {
    info: {
      subject: emailSubject,
      content: emailContent,
    },
    autoReplyInfo: {
      subject: emailAutoReplySubject,
      content: emailAutoReplyContent,
    },
  };
};
