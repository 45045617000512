export default {
  company: {
    title: "SpiritualScience Institute",
  },
  header: {
    nav: {
      home: "Home",
      about: {
        label: "About Us",
        dropdown: {
          ourMission: "Our Mission",
          backgroundAndStory: "Background & Brand Story",
        },
      },
      products: {
        label: "Products",
        dropdown: {
          allProducts: "All Products",
          iChingCardsInstructions: "I Ching Oracle Cards Guide Book",
          iChingCardsExplanations: "Explanations of I Ching Oracle Cards",
        },
      },
      services: {
        label: "Services",
        dropdown: {
          allServices: "All Services",
          moreInfo: "More About Our Services",
        },
      },
      classes: {
        label: "Classes",
        dropdown: {
          allClasses: "All Classes",
          moreInfo: "More About Our Classes",
        },
      },
      testimonials: "Testimonials",
      contact: "Contact Us",
    },
  },
  footer: {
    subtitle: "© 2024 SpiritualScience Institute all rights reserved",
  },
  home: {
    hero: {
      title: "Discover Inner Harmony",
      subtitle: "Do you have experience that the same unfortunate things keep happening? Transform your life through personalized guidance in metaphysics, psychology, and holistic healing. Let us help you attract positive energy, overcome challenges, and discover the path to lasting happiness and fulfillment.",
    },
    about: {
      title: "About Us",
      subtitle: "Embrace Spiritual Wellness",
      content: {
        p1: "If you want to change your life, you must begin by changing your thoughts and behaviors. Only by actively making these changes can you embrace a better future.",
        p2: "Welcome to the SpiritualScience Institute, a sanctuary of spiritual soothing and feng shui practices in the heart of Hong Kong founded by Pallas Ng. We are dedicated to promoting harmony and balance through our services and products, designed to elevate your spiritual journey.",
      },
      button: "Learn More",
    },
    products: {
      title: "Our Offerings",
      subtitle: {
        p1: "Everyone possesses unique energy, and we influence one another, generating new energy which directly impacts our emotions, physical well-being and overall fortune.",
        p2: "We have unique at-home methods that allow you to harness the power of this new energy, promote auspiciousness, and achieve balance in various aspects of your life.",
      },
      loadMore: {
        button: "Load More",
      },
    },
    services: {
      title: "Services",
      readMore: {
        button: "Read More",
      },
      bookNow: {
        button: "Book Now",
      },
    },
    classes: {
      title: "Classes",
      subtitle: "Wonder how to do it? Come and learn it yourselves",
    },
    testimonials: {
      title: "Testimonials",
      subtitle: "The services provided by the SpiritualScience Institute have truly transformed my life. I have found inner peace and balance like never before.",
    },
    contactUs: {
      title: "Contact Us",
      phoneNumber: "+852 4414 2989",
      email: "cs@ichingspiritual.com",
      website: "https://www.ichingspiritual.com",
      location: "Hong Kong",
    },
  },
  shared: {
    productGrid: {
      quickViewBanner: "Quick View",
      addToCartButton: "Add to cart",
    },
    quickViewModal: {
      quantityLabel: "Quantity: ",
      addToCartButton: "Add to cart",
      viewDetailsLink: "View More Details",
    },
  },
  products: {
    iChingCardsInstructions: {
      title: "I Ching Oracle Cards Guide Book",
      about: {
        title: "About I Ching Oracle Cards",
        content: {
          p1: "Many people have experienced the challenge of finding a traditional Feng Shui master for Feng Shui consultations, requiring appointments, and often coming at a high cost...",
          p2: "Everyone possesses unique energy, and when we gather in shared environments, we influence one another, generating new energy. This phenomenon is known as Feng Shui energy, which directly impacts our emotions, physical well-being, career success, and overall fortune.",
          p3: "Now, we introduce a unique method that utilizes the Fengshui Oracle and energy lamps, allowing you to harness the power of Feng Shui energy, promote auspiciousness, and achieve balance in various aspects of your life. The best part is, you can do it yourself without relying on others, and it is both effective and affordable.",
          p4: "With our Fengshui Oracle and energy lamps, you can now easily enhance the Feng Shui energy of your living or working space, leading to an improved quality of life.",
        },
      },
      howTo: {
        header: "How to use I Ching Oracle Cards",
      },
      instructions: {
        title: "How to use I Ching Oracle Cards",
        content: {
          p1: "The Fengshui Oracle, consisting of 64 hexagrams, can be used to consult on various matters ranging from daily to within a year.",
          p2: "Areas of divination: Feng shui energy in the home and office, relationships, work and career, wealth, inner world, study, life direction, travel, health.",
        },
      },
      steps: {
        title: "Steps",
        1: "Choose a card layout.",
        2: "While shuffling the cards, meditate on your question and envision the possible scenarios for the specified time period, which can range from 3 to 12 months in the future.",
        3: "Once the shuffling is complete, intuitively draw cards and place them sequentially in the designated positions within the layout. During the card-drawing process, focus sincerely on the imagery related to your question. The Fengshui Oracle do not differentiate between upright and reversed positions.",
        4: "Place the drawn cards on the table, and the interpretation of the card layout is the answer to your question. Please refer to the 'Fengshui Oracle Interpretation' guide for assistance in interpreting the cards.",
        5: "If the answer is unsatisfactory, you can draw an additional card as a solution. The Fengshui Oracle are not categorized as good or bad; they are considered either suitable or unsuitable.",
        p1: "Please note that closed-ended questions cannot be asked with the Fengshui Oracle. Avoid questions that 'Yes or no?' 'Good or bad?' Instead, focus on open-ended questions such as 'How should I handle...' 'What are the potential developments in the future?' 'What are the circumstances surrounding this event?'",
      },
      cardSpread: {
        title: "Card Spread",
        oneCardDivination: {
          title: "One-Card Divination Method",
          content: "Suitable for obtaining random inspiration.",
        },
        twoCardDivination: {
          title: "Two-Card Divination Method",
          content: {
            header: "Suitable for predicting scenarios with two stages or comparing two situations.",
            p1: "Example 1: Current situation and future circumstances or developments.",
            p2: "Example 2: Development of Company A compared to Company B.",
          },
        },
        threeCardDivination: {
          title: "Three-Card Divination Method",
          content: {
            li1: "Suitable for predicting scenarios with three stages or comparing three situations.",
            li2: "Suitable for predicting scenarios involving a problem, its cause, and its outcome.",
            p1: "Example 1: Present, past, and future.",
            p2: "Example 2: Prospects of personal career development in Country A, Country B, and Country C.",
            p3: "Example 3: The issue at hand, its cause, and the resulting outcome.",
          },
        },
        fengShuiDivination: {
          title: "Feng Shui Energy Divination Method",
          content: {
            li1: "Draw nine cards, representing the floor plan of your home or office.",
            li2: "Choose one card to represent the main entrance in your floor plan and other places accordingly.",
            li3: "When negative energy is detected in a specific direction, an additional card can be drawn as a solution. Please note that a complete set of cards is required to draw the solution.",
            li4: "Place the solution card along with an energy lamp in the corresponding position in your physical living or working space to initiate a change in the Feng Shui energy.",
          },
        },
        astrologyDivination: {
          title: "Chinese Astrology Divination Method",
          content: {
            li1: "Suitable for predicting various aspects of fortune within the next twelve months.",
            li2: {
              main: "Draw twelve cards, each representing the fortune in one of twelve areas.",
              life: "Life: 	Overall personal fortune and unexpected life events.",
              parents: "Parents: 	Relationships with parents and elders.",
              psychic: "Psychic: 	Mental well-being, psychological state, and inner cultivation.",
              residence: "Residence: Personal assets, living and working environment.",
              career: "Career: 	Personal work and interaction with superiors.",
              friends: "Friends: 	Relationships with subordinates and/or friends. Friends' situations.",
              travel: "Travel: 	External image of individual, auspiciousness or adversity in travel and relocation.",
              health: "Health: 	Personal health and the strength of the internal organs.",
              wealth: "Wealth: 	Financial fortune and the flow of funds.",
              children: "Children: 	Situations of children and/or pets.",
              spouse: "Spouse: 	Spouse's situation and/or relationship dynamics.",
              siblings: "Siblings: 	Siblings' situations and/or interactions with partners.",
            },
            li3: "Choose one card to represent the “Life”. When selecting the Life’s card, you can consider the current time or month.",
            li4: "Distribute the drawn cards according to the positions of the twelve houses. Refer to the chart in the circular diagram below for the allocation of the twelve houses.",
            li5: "If an unfavorable card is drawn, an additional card can be drawn as a solution.  Please be aware that a complete set of cards is necessary to draw the solution.",
          },
        },
      },
      nextStepsGuide: {
        text: "For I Ching Oracle Cards interpretation and recommendations. Please visit ",
        link: "here.",
      },
    },
    category: {
      iChingCards: {
        header: "What are I Ching Oracle Cards",
      },
      subconsciousIntegration: {
        about: {
          header: "What is Subconscious Integration Healing",
          content: {
            p1: "Pallas' Subconscious Integration Healing helps you reconcile with the parallel universe ✨ and attract what you desire into your life. It can treat physical illnesses, past traumas, emotional and psychological patterns, relationship issues, business concerns, and more‼️",
            p2: "No matter what changes you want to make, it can help you achieve them quickly and easily 🌈.",
          },
        },
        demo: {
          header: "Subconscious Integration Healing Demo",
          content: "Curious how it is done? Take a look at the Demo below and come try communicate to your subconsciousness.",
        },
      },
      singingBowls: {
        header: "Take a peek at how singing bowls can heal",
      },
      spiritualCommunication: {
        header: "Hear what others have to say about Spiritual Communication",
      },
    },
    iChingCardsExplanations: {
      title: "I Ching Oracle Cards Interpretation and Recommendations",
      subTitle: "Check out what each card represents and how it can help you in your daily life",
      overview: "Overview",
      relationships: "Relationships",
      career: "Career",
      wealth: "Wealth",
      advice: "Advice",
      search: {
        placeholder: "Search for a card by card number",
        errorMessage: "No card found. Please enter a number between 1 and 64",
      },
    },
  },
  productDetailsPage: {
    productQuantity: {
      label: "Quantity:",
    },
    addToCartBtn: "Add to cart",
    productInfo: {
      header: "PRODUCT INFO",
    },
    shippingInfo: {
      header: "SHIPPING INFO",
      content: "For shipping within Hong Kong, we provide free shipping for orders over $500. For orders under $500, a shipping fee of $20 applies. Additional fees apply for international shipping. Please contact us for details.",
    },
  },
  detailViewPage: {
    contactDetails: {
      title: "Contact Details",
      phone: "+852 4414 2989",
      email: "cs@ichingspiritual.com",
      location: "Hong Kong",
    },
  },
  classCard: {
    bookNow: {
      button: "Book Now",
    },
    viewCourse: {
      button: "Book Now",
    },
  },
  about: {
    backgroundAndStory: {
      preTitle: "Our",
      title: "Background & Brand Story",
      brandStory: {
        title: "Brand Story",
        content: {
          p1: "Since childhood, I have had a deep interest in metaphysics. In addition to being captivated by Jin Yong’s novels and romantic fiction, my reading focus has always centered on books about metaphysics and psychology. In my youth, I wasn’t good at expressing my feelings and often kept my dissatisfaction to myself, which sometimes led to extreme behaviors. However, all of this began to change when I went to study in New Zealand.",
          p2: "By chance, I came across \"Chicken Soup for the Soul\" a book that made me realize the differences in values between myself and my family. These differences were affecting my mental state and behavioral patterns. Metaphysics attracted me because it helped me understand the script of life, while psychology offered solutions to current issues, allowing me to reflect on and adjust my values and actions. The concept of energy linked these two fields, creating a comprehensive framework of understanding.",
          p3: "Therefore, I founded the SpiritualScience Institute, dedicated to connecting the logical thinking of the left brain with the soulful, emotional domain of the right brain, a pursuit that led me to become today’s \"Soul Scientist.\" Through this platform, I hope to guide each participant to find their unique happiness and to effectively face life’s challenges."
        },
      },
      logoBackgroundStory: {
        content: {
          title: "Origin of the Company Logo",
          p1: "Our company logo is composed of two unique energy symbols, representing our core values and vision.",
          p2: {
            bold: "Abundance:",
            text: "This symbol is formed by eight interwoven infinity signs, symbolizing the abundance of life and limitless possibilities. Each infinity symbol represents continuous growth and prosperity, reminding us to cherish every richness in life.",
          },
          p3: {
            bold: "Excellence:",
            text: "The other energy symbol consists of three interconnected 'E's, symbolizing strong interpersonal relationships and good rapport. This design emphasizes the importance of mutual support and harmonious communication, reflecting our commitment to building positive connections.",
          },
          p4: "Together, these two symbols form our brand image, conveying the ideals of pursuing an abundant life and excellent connections."
        },
      },
    },
  },
  aboutUsPage: {
    preTitle: "The Full Story",
    title: "About Us",
    subtitle: "Everyone is a ball of energy. In our daily lives, we encounter others' energies, and these energies intervene with one another, affecting how we perceive this world. Understanding these energies helps you stay positive and harvest the most out of your journey.",
    ourMission: {
      title: "Our Mission",
      content: {
        p1: "Pallas Ng founded SpiritualScience Institute to help everyone understand and enhance their energies. It is our mission that every person can find their own unique happiness and satisfaction.",
        p2: "As a metaphysician, my role is to help you attract good fortune and avoid misfortune, guiding you to discover your greatest inner potential for a better life. As a psychological counselor, I can support you in confronting life’s challenges, reconciling with your inner self, and finding the most suitable attitude toward life. As a mind-body-spirit guide, I will assist you in enhancing your self-awareness, enabling your soul to achieve fulfillment and happiness. Through techniques such as Ba Zi, Feng Shui, I Ching Tarot divination, heart transmission, root energy restructuring, and singing bowl sound therapy, I aim to help you gain insight into yourself and open up a new path in life.",
      },
    },
    aboutPallas: {
      title: "About Pallas",
      content: {
        p1: "Pallas graduated from the University of New South Wales in Australia, majoring in Psychology. Over the 19 years of her career, she held senior management positions at Cathay Pacific Airways and in freight forwarding and courier companies. With more than 14 years of experience in spiritual communication and fortune-telling, she specializes in:",
        list: {
          item1: "BaZi",
          item2: "Feng Shui",
          item3: "Palm Reading",
          item4: "Face Reading",
          item5: "Iching Fortune-telling / divination",
          item6: "Subconscious Integration Healing",
          item7: "Inner Child Spiritual Communication",
          item8: "Singing Bowl Healing",
        },
      },
    },
  },
  productsPage: {
    header: {
      title: "All Products",
      subtitle: "Empowering your journey with wisdom, balance, and enriching tools for life's every step. Elevate your energy, enhance harmony, and create abundance in life through our products.",
    },
  },
  servicesPage: {
    header: {
      title: "Our Services",
    },
    serviceDetails: {
      bookNow: {
        button: "Book Now",
      },
    },
  },
  services: {
    moreServicesInfo: {
      title: "More About Our Services",
      subTitle: "Hear what our founder has to say about our services",
      content: {
        subconsciousIntegration: {
          header: "Subconscious Integration Healing",
          intro: {
            caption: "INTRODUCTION: Helps you reconcile with the parallel universe ✨",
          },
          caseSharing: {
            caption: "CASE SHARING: Releases negative energy from the body 👍🏻, with noticeable and rapid improvement!",
          },
        },
        singingBowls: {
          header: "Singing Bowls Healing",
          caption: "Sneek peek at how singing bowls can heal",
        },
      },
    },
  },
  classesPage: {
    header: {
      title: "Our Classes / Courses",
      subtitle: "Have you ever wondered how does Fortune-telling work? Come and learn it yourselves! Having an instructor to teach you step-by-step of what you need to know. The best thing about this is that all our classes are unlimited to repeating taking classes you have taken.",
    },
  },
  classDetailsPage: {
    bookNow: {
      button: "Book Now",
    },
  },
  classes: {
    moreClassesInfo: {
      title: "More About Our Classes",
      subTitle: "Hear what our founder has to say about our classes",
      content: {
        spiritualCommunication: {
          header: "Spiritual Communication",
          caseSharing: {
            caption: "CASE SHARING: Spiritual Communication not only allow you to communicate with your pets, but also with people too.",
          },
        },
      },
    },
  },
  testimonialsPage: {
    header: {
      title: "Testimonials",
      subtitle: '"Listen what the others have to say walking down this journey"',
    },
  },
  contactUsPage: {
    header: {
      title: "Contact Us",
      subtitle: "We're here to help you on your journey! Whether you have questions, need guidance, or want to explore more about our products, feel free to reach out. Let’s connect and grow together!",
    },
    successToaster: "Your request to SpiritualScience Institute has been submitted successfully!",
    errorToaster: "There's an error with your submission. Please try again later.",
    form: {
      name: "Name",
      email: "Email",
      phoneNumber: "Phone Number",
      phoneNumberHelperText: "Please include country code if not in Hong Kong",
      message: "Message",
      submitButton: "Submit",
    },
  },
  checkoutPage: {
    contactInfo: {
      header: {
        title: "Contact information",
      },
    },
    form: {
      name: {
        label: "Name",
        placeholder: "Enter your name",
        errorMessage: "Name is required",
      },
      email: {
        label: "Email",
        placeholder: "Enter your email",
        errorMessage: "Email is required",
      },
      phone: {
        label: "Phone Number",
        placeholder: "Enter your phone number",
        errorMessage: "Phone number is required",
      },
      address: {
        label: "Address",
        placeholder: "Enter your address",
        errorMessage: "Address is required",
      },
    },
    payment: {
      header: {
        title: "Payment",
        instruction: {
          p1: "Please send us the payment through PayMe or FPS within 24 hours to secure your order.",
          p2a: "After sending us the payment, please ",
          p2b: "screenshot or take a picture and send us back as proof",
          p2c: " to confirm the order.",
        },
      },
      payMe: {
        title: "PayMe",
      },
      fps: {
        title: "FPS",
        details: "company FPS no: 112381983",
      },
      alipay: {
        title: "Alipay / WeChat Pay",
      },
    },
    orderSummary: {
      title: "Order Summary",
      qty: "Qty",
      totalAmount: "Total",
    },
    confirmOrderBtn: "Place Order",
  },
  orderConfirmationPage: {
    header: {
      title: "Thank you for your order!",
    },
    content: {
      p1: "Your order has been successfully placed.",
      p2: "Please remember to send us the payment to receive your order as soon as possible.",
    },
    returnToHome: "Return to Home Page",
  },
  orderCancellationPage: {
    header: {
      title: "Sorry... Something went wrong",
    },
    content: {
      p1: "We are experiencing some technical difficulties with your order.",
      p2: "Please contact us through WhatsApp to place your order. Sorry for the inconvenice caused.",
    },
    returnToHome: "Return to Home Page",
  },
  shoppingCart: {
    header: {
      title: "Shopping Cart",
    },
    removeItemBtn: "Remove",
    emptyCart: {
      message: "Your cart is empty.",
    },
    totalAmount: "Total",
    checkoutBtn: "Proceed to Checkout",
  },
  servicesAppointmentPage: {
    header: {
      title: "Book your service appointment",
    },
    form: {
      name: {
        label: "Name",
        placeholder: "Enter your name",
      },
      email: {
        label: "Email",
        placeholder: "Enter your email",
      },
      phone: {
        label: "Phone",
        placeholder: "Enter your phone number",
      },
      apptType: {
        label: "Service Type",
      },
      message: {
        label: "Message",
        caption: "Please tell us your available dates and times. And tell us anything you want us to know more about. We will contact you shortly to confirm the appointment.",
      },
    },
    disclaimer: {
      message: "By clicking 'Send', you are agreeing to our terms and conditions.",
    },
    sendBtn: "Send",
  },
  servicesApptConfirmationPage: {
    header: {
      title: "Thank you for sending us your service appointment request!",
    },
    content: {
      p1: "We will contact you shortly to confirm the appointment.",
      p2: "Please feel free to contact us if you have any questions.",
    },
    returnBtn: "Return to Services Page",
  },
  servicesApptCancellationPage: {
    header: {
      title: "Sorry... Something went wrong",
    },
    content: {
      p1: "We are experiencing some technical difficulties with your service appointment request.",
      p2: "Please contact us through email or WhatsApp to book your service appointment. Sorry for the inconvenice caused.",
    },
    returnBtn: "Return to Services Page",
  },
  classesAppointmentPage: {
    header: {
      title: "Reserve your spot in the class",
    },
    form: {
      name: {
        label: "Name",
        placeholder: "Enter your name",
      },
      email: {
        label: "Email",
        placeholder: "Enter your email",
      },
      phone: {
        label: "Phone",
        placeholder: "Enter your phone number",
      },
      apptType: {
        label: "Class Type",
      },
      message: {
        label: "Message",
        caption: "Please tell us your available dates and times. And tell us anything you want us to know more about. We will contact you shortly to confirm the classes times.",
      },
    },
    disclaimer: {
      message: "By clicking 'Send', you are agreeing to our terms and conditions.",
    },
    sendBtn: "Send",
  },
  classesApptConfirmationPage: {
    header: {
      title: "Thank you for being interested in our classes/courses!",
    },
    content: {
      p1: "We will contact you shortly to confirm the classes times.",
      p2: "Please feel free to contact us if you have any questions.",
    },
    returnBtn: "Return to Classes Page",
  },
  classesApptCancellationPage: {
    header: {
      title: "Sorry... Something went wrong",
    },
    content: {
      p1: "We are experiencing some technical difficulties with your classes request.",
      p2: "Please contact us through email or WhatsApp to book your classes or courses. Sorry for the inconvenice caused.",
    },
    returnBtn: "Return to Classes Page",
  },
};
