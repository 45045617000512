import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';
import { MAX_SERVICES_PAGE_INITIAL_DESCRIPTION } from '../../../constants/constants';
import { useTranslation } from '../../contexts/TranslationContext';
import { displayNumWithCommas } from '../../utils';
import './ServiceCard.css';

const ServiceCard = ({ service }) => {
  const { translate } = useTranslation();
  const {
    id,
    name,
    description,
    descriptionDetails,
    duration,
    price,
    priceUnit,
    priceNotes,
    imgPath,
    index
  } = service;
  const navigate = useNavigate();

  const handleServiceCardClick = () => {
    navigate(`/service/${id}`);
  };

  const handleBookNow = (e) => {
    e.stopPropagation();
    const serviceData = { name: name, id: id };
    navigate('/services/book-now', { state: serviceData });
  };

  return (
    <div
      className={`service-card ${index % 2 === 0 ? 'image-right' : 'image-left'}`}
      onClick={handleServiceCardClick}
    >
      <div className="service-card-image">
        <img src={process.env.PUBLIC_URL + imgPath[0]} alt={name} />
      </div>
      <div className="service-card-content">
        <h3>{name}</h3>
        <p>
          {description.length > MAX_SERVICES_PAGE_INITIAL_DESCRIPTION ?
          `${description.substring(0, MAX_SERVICES_PAGE_INITIAL_DESCRIPTION)}...` :
          description}
        </p>
        {descriptionDetails && <div className="service-card-description-details">{descriptionDetails}</div>}
        <hr className="service-card-divider" />
        <div className="service-card-info">
          {duration && <p>{duration}</p>}
          <p>${displayNumWithCommas(price)} {priceUnit ? priceUnit : ''} {priceNotes ? `; ${priceNotes}` : ''}</p>
        </div>
        <Button
          className="book-now"
          onClick={handleBookNow}
        >
          {translate('servicesPage.serviceDetails.bookNow.button')}
        </Button>
      </div>
    </div>
  );
};

export default ServiceCard;
