import React from 'react';
import { useTranslation } from '../../../contexts/TranslationContext';
import './PaymentMethod.css';

const PaymentMethod = () => {
  const { translate } = useTranslation();

  return (
    <div className="checkout-payment-method">
      <h2>{translate('checkoutPage.payment.header.title')}</h2>
      <p>{translate('checkoutPage.payment.header.instruction.p1')}</p>
      <p>
        {translate('checkoutPage.payment.header.instruction.p2a')}
        <b>{translate('checkoutPage.payment.header.instruction.p2b')}</b>
        {translate('checkoutPage.payment.header.instruction.p2c')}
      </p>
      <ul>
        <li>
          <h4>{translate('checkoutPage.payment.payMe.title')}</h4>
          <img
            src={process.env.PUBLIC_URL + '/payme_qrcode.jpeg'}
            alt="PayMe QR code"
            className="checkout-payment-payme-qr-code"
          />
        </li>
        <li>
          <h4>{translate('checkoutPage.payment.fps.title')}</h4>
          <p>{translate('checkoutPage.payment.fps.details')}</p>
        </li>
        <li>
          <h4>{translate('checkoutPage.payment.alipay.title')}</h4>
          <img
            src={process.env.PUBLIC_URL + '/alipay_qrcode.jpeg'}
            alt="Alipay WeChat Pay QR code"
            className="checkout-payment-payme-qr-code"
          />
        </li>
      </ul>
    </div>
  );
};

export default PaymentMethod;
