import React from 'react';
import ReactPlayer from 'react-player';
import YouTube from 'react-youtube';
import { useTranslation } from '../../contexts/TranslationContext';
import './IchingOracleCards.css';

const IchingOracleCardsInfo = () => {
  const options = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1,
      controls: 1,
    },
  };

  const _onReady = (event) => {
    event.target.pauseVideo();
  };

  const { translate } = useTranslation();

  return (
    <div className="iching-cards-info-section">
      <h2 className="iching-cards-info-header">{translate('products.category.iChingCards.header')}</h2>
      <div className="youtube-player-container">
        <YouTube videoId="tdYz0zbgmT8" options={options} onReady={_onReady}/>
      </div>
      <p>{translate('products.iChingCardsInstructions.about.content.p1')}</p>
      <p>{translate('products.iChingCardsInstructions.about.content.p2')}</p>
      <p>{translate('products.iChingCardsInstructions.about.content.p3')}</p>
      <p>{translate('products.iChingCardsInstructions.about.content.p4')}</p>
      <div className="react-player">
        <ReactPlayer
          url={process.env.PUBLIC_URL + '/I Ching 1 mins video.MP4'}
          controls={true}
          width='100%'
          height='100%'
        />
      </div>
      <h2 className="iching-cards-info-header">{translate('products.category.iChingCards.howTo.header')}</h2>
      <div className="youtube-player-container">
        <YouTube videoId="F8LjnvWI0vI" options={options} onReady={_onReady}/>
      </div>
      <div className="youtube-player-container">
        <YouTube videoId="AmU1eq20SgY" options={options} onReady={_onReady}/>
      </div>
    </div>
  );
};

export default IchingOracleCardsInfo;