import React from 'react';
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player';
import { useTranslation } from '../../contexts/TranslationContext';
import './MoreClassesInfoPage.css';

const MoreClassesInfoPage = () => {
  const options = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1,
      controls: 1,
    },
  };

  const _onReady = (event) => {
    event.target.pauseVideo();
  };

  const { translate } = useTranslation();

  return (
    <div className="more-classes-info-page">
      <div className="more-classes-info-page-header">
        <h1>{translate('classes.moreClassesInfo.title')}</h1>
        <h3>{translate('classes.moreClassesInfo.subTitle')}</h3>
      </div>
      <div className="more-classes-info-page-content-container">
        <div className="more-classes-info-page-content">
          <div className="youtube-player-container">
            <YouTube videoId="2ncMS49-jZU" options={options} onReady={_onReady}/>
          </div>
          <hr />
          <div className="more-classes-info-page-section">
            <h2>{translate('classes.moreClassesInfo.content.spiritualCommunication.header')}</h2>
            <h5>{translate('classes.moreClassesInfo.content.spiritualCommunication.caseSharing.caption')}</h5>
            <div className="react-player">
              <ReactPlayer
                url={process.env.PUBLIC_URL + '/inner-child-tvb.mp4'}
                controls={true}
                width='100%'
                height='100%'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreClassesInfoPage;
