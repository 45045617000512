import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../contexts/TranslationContext';
import Button from '../Button';
import './ApptConfirmationPage.css';

const ApptConfirmationPage = ({ apptType }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();

  const handleReturnBtnClicked = () => {
    if (apptType === 'service') {
      navigate('/services');
    } else if (apptType === 'class') {
      navigate('/classes');
    }
  };

  const getStringsKey = () => {
    if (apptType === 'service') {
      return 'servicesApptConfirmationPage';
    } else if (apptType === 'class') {
      return 'classesApptConfirmationPage';
    }
  };
  const stringsKey = getStringsKey();

  return (
    <div className="appt-confirmation-container">
      <h1>{translate(stringsKey + '.header.title')}</h1>
      <p>{translate(stringsKey + '.content.p1')}</p>
      <p>{translate(stringsKey + '.content.p2')}</p>
      <Button size="medium" onClick={handleReturnBtnClicked}>
        {translate(stringsKey + '.returnBtn')}
      </Button>
    </div>
  );
};

export default ApptConfirmationPage;
