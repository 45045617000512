import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Button from '../../shared/Button';
import { useTranslation } from '../../contexts/TranslationContext';
import { displayNumWithCommas } from '../../utils';
import './DetailsViewPage.css';

const IMG_CAROUSEL_SETTINGS = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true
};

const DetailsView = ({
  data,
  detailsViewBtnText,
  onDetailsViewBtnClicked
}) => {
  const { translate } = useTranslation();
  const {
    name,
    description,
    descriptionDetails,
    duration,
    price,
    priceUnit,
    priceNotes,
    imgPath,
    dates,
  } = data;

  return (
    <div className="details-view-container">
      <div className="details-view">
        <div className="details-view-carousel">
          {imgPath && imgPath.length > 1 ? (
            <Slider {...IMG_CAROUSEL_SETTINGS}>
              {imgPath.map((path, index) => (
                <div key={index}>
                  <img
                    className="carousel-image"
                    src={process.env.PUBLIC_URL + path}
                    alt={name + ' image ' + index}
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <img
              className="details-view-image"
              src={process.env.PUBLIC_URL + imgPath[0]}
              alt={name}
            />
          )}
        </div>
        <div className="details-view-info">
          <h1>{name}</h1>
          <div className="details-view-price">
            {duration && <p>{duration}</p>}
            <p>${displayNumWithCommas(price)} {priceUnit ? priceUnit : ''} {priceNotes ? `; ${priceNotes}` : ''}</p>
          </div>
          {dates && <p>{dates}</p>}
          <p>{description}</p>
          {descriptionDetails && <div>{descriptionDetails}</div>}
          <Button
            className="details-view-btn"
            size="large"
            onClick={onDetailsViewBtnClicked}
          >
            {detailsViewBtnText}
          </Button>
        </div>
      </div>

      <div className="details-view-contacts">
        <h3>{translate('detailViewPage.contactDetails.title')}</h3>
        <div className="details-view-contact-info">
          <p>{translate('detailViewPage.contactDetails.phone')}</p>
          <p>{translate('detailViewPage.contactDetails.email')}</p>
          <p>{translate('detailViewPage.contactDetails.location')}</p>
        </div>
      </div>
    </div>
  );
};

export default DetailsView;
