import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../contexts/TranslationContext';
import Button from '../../../shared/Button';
import PaymentMethod from '../PaymentMethod';
import './OrderConfirmationPage.css';

const OrderConfirmationPage = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();

  const handleReturnToHome = () => {
    navigate('/');
  };

  return (
    <div className="confirmation-container">
      <h1>{translate('orderConfirmationPage.header.title')}</h1>
      <p>{translate('orderConfirmationPage.content.p1')}</p>
      <p>{translate('orderConfirmationPage.content.p2')}</p>
      <Button size="medium" onClick={handleReturnToHome}>
        {translate('orderConfirmationPage.returnToHome')}
      </Button>
      <hr />
      <PaymentMethod />
    </div>
  );
};

export default OrderConfirmationPage;
