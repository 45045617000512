export default {
  productData: [
    {
      id: 1,
      name: 'The Ultimate Set: I Ching Oracle Cards (1 Large + 1 Small) + Energy Lamp + Playing Mat',
      price: 1288,
      imgPath: ['/I Ching Cards n Gameboard.jpg', '/I Ching Cards.jpg', '/Energy Lamp.JPG'],
      description:
        <>
          <p>
            The wisdom of the I Ching outlines 64 life situations, guiding how to strategically navigate 
            through various circumstances. Leveraging this wisdom, we have developed a unique feng shui 
            method that enables individuals to harness the energy field and elevate their quality of life.
          </p>
          <p>
            Our system empowers users to easily apply feng shui principles, cultivating a balanced and 
            harmonious living environment. This will guide you towards a more beautiful, more abundant, 
            and more enriching life.
          </p>
          <b>
            <div>
              This set includes:
              <ul>
                <li>I Ching Oracle Cards (Large) 80 x 140 mm</li>
                <li>I Ching Oracle Cards (Small) 57 x 87 mm</li>
                <li>Portable Energy Lamp (Rechargeable through USB Type C head)</li>
                <li>Playing Mat 60 x 60 mm</li>
              </ul>
            </div>
          </b>
        </>,
      ribbon: 'Best Seller',
      category: 'I Ching Oracle Cards',
    },
    {
      id: 2,
      name: 'The Beginner Set: I Ching Oracle Cards (Large) + Energy Lamp',
      price: 888,
      imgPath: ['/I Ching Cards.jpg', '/Energy Lamp.JPG'],
      description:
        <>
          <p>
            The wisdom of the I Ching outlines 64 life situations, guiding how to strategically navigate 
            through various circumstances. Leveraging this wisdom, we have developed a unique feng shui 
            method that enables individuals to harness the energy field and elevate their quality of life.
          </p>
          <p>
            Our system empowers users to easily apply feng shui principles, cultivating a balanced and 
            harmonious living environment. This will guide you towards a more beautiful, more abundant, 
            and more enriching life.
          </p>
          <b>
            <div>
              This set includes:
              <ul>
                <li>I Ching Oracle Cards (Large) 80 x 140 mm</li>
                <li>Portable Energy Lamp (Rechargeable through USB Type C head)</li>
              </ul>
            </div>
          </b>
        </>,
      ribbon: 'Best Seller',
      category: 'I Ching Oracle Cards',
    },
    {
      id: 3,
      name: 'The Essential Set: I Ching Oracle Cards (Large) + Energy Lamp + Playing Mat',
      price: 1088,
      imgPath: ['/I Ching Cards n Gameboard.jpg', '/I Ching Cards.jpg', '/Energy Lamp.JPG'],
      description:
        <>
          <p>
            The wisdom of the I Ching outlines 64 life situations, guiding how to strategically navigate 
            through various circumstances. Leveraging this wisdom, we have developed a unique feng shui 
            method that enables individuals to harness the energy field and elevate their quality of life.
          </p>
          <p>
            Our system empowers users to easily apply feng shui principles, cultivating a balanced and 
            harmonious living environment. This will guide you towards a more beautiful, more abundant, 
            and more enriching life.
          </p>
          <b>
            <div>
              This set includes:
              <ul>
                <li>I Ching Oracle Cards (Large) 80 x 140 mm</li>
                <li>Portable Energy Lamp (Rechargeable through USB Type C head)</li>
                <li>Playing Mat 60 x 60 mm</li>
              </ul>
            </div>
          </b>
        </>,
      category: 'I Ching Oracle Cards',
    },
    {
      id: 4,
      name: 'I Ching Oracle Cards (Large)',
      price: 599,
      imgPath: ['/I Ching Cards.jpg'],
      description:
        <>
          <p>
            The wisdom of the I Ching outlines 64 life situations, guiding how to strategically navigate 
            through various circumstances. Leveraging this wisdom, we have developed a unique feng shui 
            method that enables individuals to harness the energy field and elevate their quality of life.
          </p>
          <p>
            Our system empowers users to easily apply feng shui principles, cultivating a balanced and 
            harmonious living environment. This will guide you towards a more beautiful, more abundant, 
            and more enriching life.
          </p>
          <b><li>I Ching Oracle Cards (Large) 80 x 140 mm</li></b>
        </>,
      category: 'I Ching Oracle Cards',
    },
    {
      id: 5,
      name: 'I Ching Oracle Cards (Small)',
      price: 399,
      imgPath: ['/I Ching Cards.jpg'],
      description:
        <>
          <p>
            The wisdom of the I Ching outlines 64 life situations, guiding how to strategically navigate 
            through various circumstances. Leveraging this wisdom, we have developed a unique feng shui 
            method that enables individuals to harness the energy field and elevate their quality of life.
          </p>
          <p>
            Our system empowers users to easily apply feng shui principles, cultivating a balanced and 
            harmonious living environment. This will guide you towards a more beautiful, more abundant, 
            and more enriching life.
          </p>
          <b><li>I Ching Oracle Cards (Small) 57 x 87 mm</li></b>
        </>,
      category: 'I Ching Oracle Cards',
    },
    {
      id: 6,
      name: 'Energy Lamp',
      price: 399,
      imgPath: ['/Energy Lamp.JPG'],
      description:
        <>
          <p>
            The wisdom of the I Ching outlines 64 life situations, guiding how to strategically navigate 
            through various circumstances. Leveraging this wisdom, we have developed a unique feng shui 
            method that enables individuals to harness the energy field and elevate their quality of life.
          </p>
          <p>
            Our system empowers users to easily apply feng shui principles, cultivating a balanced and 
            harmonious living environment. This will guide you towards a more beautiful, more abundant, 
            and more enriching life.
          </p>
          <b><li>Portable Energy Lamp (Rechargeable through USB Type C head)</li></b>
        </>,
      category: 'I Ching Oracle Cards',
    },
    {
      id: 7,
      name: 'Playing Mat',
      price: 299,
      imgPath: ['/I Ching Cards n Gameboard.jpg'],
      description:
        <>
          <p>
            The wisdom of the I Ching outlines 64 life situations, guiding how to strategically navigate 
            through various circumstances. Leveraging this wisdom, we have developed a unique feng shui 
            method that enables individuals to harness the energy field and elevate their quality of life.
          </p>
          <p>
            Our system empowers users to easily apply feng shui principles, cultivating a balanced and 
            harmonious living environment. This will guide you towards a more beautiful, more abundant, 
            and more enriching life.
          </p>
          <b><li>Playing Mat 60 x 60 mm</li></b>
        </>,
      category: 'I Ching Oracle Cards',
    },
  ],
  servicesData: [
    {
      id: 1,
      name: 'Subconscious Integration Healing',
      price: 1800,
      duration: '60 minutes',
      imgPath: ['/energy_reform_landscape.webp'],
      description: 'Subconscious Integration Healing is a holistic approach that incorporates chakras—energy centers linked to emotions and mental states. This practice assesses chakras to identify imbalances affecting psychological and physical well-being. By connecting with the universe to clear and balance these centers, clients facilitate energy flow and release negative patterns, enhancing emotional health and aligning the subconscious with conscious intentions for a comprehensive healing experience.',
      descriptionDetails:
        <>
          <div>
            60-minute healing service includes:
            <ul>
              <li>Individual consultation</li>
              <li>Assisting clients to identify the root causes of issues</li>
              <li>Addressing various pain and emotional issues</li>
              <li>Achieving balance and harmony with the universe</li>
              <li>Creating what you desire</li>
            </ul>
          </div>
        </>,
      category: 'Subconscious Integration',
    },
    {
      id: 2,
      name: 'Feng Shui Home / Office Analysis',
      price: 15,
      priceUnit: 'per sq. ft',
      priceNotes: 'Min. charge $6000',
      duration: '',
      imgPath: ['/fengshui.jpeg'],
      description: 'Feng shui is a practice from ancient China that reveals how to balance the energies of any given space to assure the health and good fortune for people inhabiting it. It offers accurate interpretations by integrating the client’s feng shui life chart with the layout of their home, arranging annual flying stars, aligning with the "Nine Cycles," balancing spatial orientation, yin and yang, and the five elements, to enhance wealth, relationships, and health.',
      descriptionDetails:
        <>
          <div>
            Feng Shui analysis service includes:
            <ul>
              <li>Accurate predictions with Feng Shui Flying Stars</li>
              <li>Integration of client’s feng shui life chart with home layout</li>
              <li>Arrangement of annual flying stars</li>
              <li>Alignment with the "Nine Cycles"</li>
              <li>Balancing spatial orientation, yin and yang, and the five elements</li>
              <li>Enhancement of wealth, relationships, and health</li>
            </ul>
          </div>
        </>,
    },
    {
      id: 3,
      name: 'Ba Zi Fate Analysis',
      price: 2200,
      duration: '60 minutes',
      imgPath: ['/ba_zi.png'],
      description: 'Ba Zi is a form of Chinese Astrology that reveals the secrets of a person\'s fate and destiny. It is also known as the "Four Pillars of Destiny".',
    },
    {
      id: 4,
      name: 'Iching Cards Fortune-telling',
      price: 980,
      duration: '30 minutes',
      imgPath: ['/iching_cards_analysis.png'],
      description: 'Iching is an ancient Chinese divination text and the oldest of the Chinese classics. Possessing a history of more than two and a half millennia of commentary and interpretation, the I Ching is an influential text read throughout the world, providing inspiration to the worlds of religion, psychoanalysis, business, literature, and art.',
    },
    {
      id: 5,
      name: 'Singing Bowl Healing Workshop (Group)',
      price: 380,
      priceUnit: 'per person',
      priceNotes: 'Pick your own time for 4 people or more',
      duration: '60 minutes',
      imgPath: ['/singing_bowls.jpeg'],
      description: 'Human body is made up mostly by water. With the sounds waves generated by the singing bowls, the physical, mental and spiritual stress and anxiousness can be relieved. Our singing bowls therapy also comes with energies analysis, with the help of finding the essential oil that you best needed, the healing results can be enhanced even more.',
      category: 'Singing Bowls',
    },
  ],
  classesData: [
    {
      id: 1,
      name: 'Spiritual Communication Class',
      description: 'Do you want to know why the animals in your home are exhibiting strange behaviors? Or are you curious about their perspective on their current life? If animals could speak, what would they tell you? Are you interested in hearing them? Before human language developed, we primarily used brainwaves, telepathy, and body language for communication. However, as language matured, our innate communication abilities gradually weakened or disappeared. Through this course, we reopen the door to spiritual communication, enabling us to communicate with animals and even plants. Communication can bring a positive effect to relationships. Through spiritual connection, you can understand the thoughts of animals and others, engage in counseling and thought exchange, and build consensus, making relationships more harmonious.',
      price: 3400,
      imgPath: ['/spiritual_communication_class1.webp'],
      duration: '4 hours',
      category: 'Spiritual Communication',
    },
    {
      id: 2,
      name: 'Iching Cards Fortune-telling Class (Beginner)',
      description: 'In life, we face countless choices, but whether they\'re right or wrong only becomes clear in the future. IChing Cards provide guidance when decision making is difficult. IChing is one of the foundational texts of Chinese culture, respected as a form of divination, yet it also contains universal philosophy, making it a philosophical work with a complete system. It also articulates human survival philosophy. IChing Cards present the essence and philosophy of each hexagram through 64 simple illustrations, empowering users to make decisions with ease, thus solving their issues.',
      descriptionDetails:
        <>
          <b>
            <div>
              Beginner class：
              <ul>
                <li>Origins and background of the Eight Trigrams</li>
                <li>Explanations of the 64 hexagrams</li>
                <li>Simple and easy learning of the 64 hexagrams through images</li>
                <li>Applications of IChing Cards, including Feng Shui energy and Annual luck</li>
              </ul>
            </div>
          </b>
        </>,
      price: 6280,
      imgPath: ['/Iching_card_class1.png'],
      duration: '10 hours',
    },
    {
      id: 3,
      name: 'Iching Cards Fortune-telling Class (Advanced)',
      description: 'In life, we face countless choices, but whether they\'re right or wrong only becomes clear in the future. IChing Cards provide guidance when decision making is difficult. IChing is one of the foundational texts of Chinese culture, respected as a form of divination, yet it also contains universal philosophy, making it a philosophical work with a complete system. It also articulates human survival philosophy. IChing Cards present the essence and philosophy of each hexagram through 64 simple illustrations, empowering users to make decisions with ease, thus solving their issues.',
      descriptionDetails:
        <>
          <b>
            <div>
              Advanced Class:
              <ul>
                <li>Using the 64 hexagrams of the IChing, along with numbers, characters, and time, to predict fortune</li>
                <li>Application and practice</li>
              </ul>
            </div>
          </b>
        </>,
      price: 3400,
      imgPath: ['/Iching_card_class1.png'],
      duration: '5 hours',
    },
    {
      id: 4,
      name: 'Feng Shui Flying Stars Course',
      description: 'In life, fate, luck, and feng shui all play important roles. Among the three, feng shui holds the third position, underscoring its importance. Feng shui actually refers to the cosmic energy that influences people and events on Earth. If we can understand and apply this cosmic energy, we can achieve favorable outcomes and avoid adverse ones! This will significantly enhance personal luck, allowing things to proceed smoothly with half the effort!',
      descriptionDetails:
        <>
          <b>
            <div>
              Course Content:
              <ul>
                <li>What is Feng Shui Flying Stars</li>
                <li>How to calculate life hexagrams / Feng Shui chart</li>
                <li>Three Cycles and Nine Periods vs. Two Cycles and Eight Periods</li>
                <li>How to select Feng Shui remedies</li>
                <li>Explanation of Double Stars Interpretation / Thirty Cases of Residential Feng Shui</li>
                <li>How to apply Feng Shui Flying Stars to seek fortune and avoid misfortune</li>
                <li>Practice session</li>
              </ul>
            </div>
          </b>
        </>,
      price: 12000,
      imgPath: ['/feng_shui_class.png'],
      duration: '20 hours',
    },
    {
      id: 5,
      name: 'BaZi Astrology Course (Basic)',
      description: 'The core of astrology lies at the moment a baby is born. Simply put, a person’s destiny is determined by the combination of the Heavenly Stems and Earthly Branches of their birth year, month, day, and hour, known as the “Ba Zi” (Eight Characters). These eight characters not only represent a person\'s destiny but also serve as the foundation of their life script. By learning astrology methods and observing the five elements within the birth chart, we can skillfully use Ba Zi astrology to manage emotional health, improve relationships, and plan life. Once we deeply understand our own life script, we can interpret it in ways that make our lives more abundant and colorful.',
      descriptionDetails:
        <>
          <b>
            <div>
              Course Content:
              <ul>
                <li>Origins and basics of Ba Zi</li>
                <li>Relationships between the five elements and their interactions</li>
                <li>Calculating Ba Zi’s major cycles / Auspicious and inauspicious aspects</li>
                <li>The relationship between Ba Zi and elements' positions</li>
                <li>Ten Gods relationships and structures</li>
                <li>Determining likes and dislikes of elements in Ba Zi</li>
                <li>Five elements and diseases</li>
                <li>Case studies of celebrities’ Ba Zi (online course: 6 hours total)</li>
              </ul>
            </div>
          </b>
        </>,
      price: 8400,
      imgPath: ['/ba_zi_class1.png'],
      duration: '24 hours',
    },
    {
      id: 6,
      name: 'BaZi Astrology Course (Advanced)',
      description: 'The core of astrology lies at the moment a baby is born. Simply put, a person’s destiny is determined by the combination of the Heavenly Stems and Earthly Branches of their birth year, month, day, and hour, known as the “Ba Zi” (Eight Characters). These eight characters not only represent a person\'s destiny but also serve as the foundation of their life script. By learning astrology methods and observing the five elements within the birth chart, we can skillfully use Ba Zi astrology to manage emotional health, improve relationships, and plan life. Once we deeply understand our own life script, we can interpret it in ways that make our lives more abundant and colorful.',
      descriptionDetails:
        <>
          <b>
            <div>
              Course Content:
              <ul>
                <li>Practicing Ba Zi interpretation</li>
                <li>Study of name analysis and naming</li>
                <li>How to select auspicious dates</li>
                <li>Practical application of the Tung Shing (Chinese almanac)</li>
                <li>Real Ba Zi case studies (online course: 6 hours total)</li>
              </ul>
            </div>
          </b>
        </>,
      price: 8400,
      imgPath: ['/ba_zi_class2.webp'],
      duration: '24 hours',
    },
  ],
  testimonialsData: [
    {
      id: 1,
      text: 'I highly recommend the feng shui consultation. It brought a positive shift in my living space and energy.',
      name: 'Jane Doe',
    },
    {
      id: 2,
      text: 'The spiritual soothing sessions have been incredibly calming and uplifting. I feel more connected to my inner self.',
      name: 'John Smith',
    },
    {
      id: 3,
      text: 'The SpiritualScience Institute\'s approach to holistic wellness is exceptional. I have experienced profound changes in my life.',
      name: 'Emily Chen',
    },
    {
      id: 4,
      text: 'The SpiritualScience Institute\'s approach to holistic wellness is exceptional. I have experienced profound changes in my life.',
      name: 'Emily Chen',
    },
    {
      id: 5,
      text: 'The SpiritualScience Institute\'s approach to holistic wellness is exceptional. I have experienced profound changes in my life.',
      name: 'Emily Chen',
    },
  ],
  iChingCardsData: [
    {
      id: 1,
      imgPath: '/I Ching Cards Hexagram1.jpeg',
      title: 'Hexagram 1: The Creation',
      subTitle: 'A SCENE OF A SOVEREIGN RULER; THE MEANING OF SELF-STRENGTHENING AND PERSEVERANCE.',
      overview: 'Successful in career, with a distinguished position.',
      relationships: 'Strong-willed and competitive, easily leading to conflicts.',
      career: 'Possessing outstanding abilities, able to bear responsibility independently.',
      wealth: 'Wealth is abundant, in a prosperous period.',
      advice: 'For the one who has obtained this hexagram, the problem is not one’s ability, but rather the greatest challenge is how to maintain the current position. Continuously creating one\'s own value is extremely important, so that long-term stability and success can be achieved.',
    },
    {
      id: 2,
      imgPath: '/I Ching Cards Hexagram2.jpeg',
      title: 'Hexagram 2: The Receptive',
      subTitle: 'A SCENE OF HARMONIZING WITH ALL THINGS; THE MEANING OF OVERCOMING THE STRONG WITH THE SOFT.',
      overview: 'Go with the flow, steadily and stably advance. Those who are low-key and humble often gain more benefits, and those who are tolerant and inclusive also receive more returns.',
      relationships: 'Mutual understanding and inclusion between the two parties, allowing the relationship to develop steadily.',
      career: 'Steadily and solidly work hard, respect the existing system, and success will definitely be achieved.',
      wealth: 'Suitable for pursuing legitimate wealth, not for speculative trading.',
      advice: 'The humble and yielding will gain blessings, while the overly aggressive often end up with nothing. For established goals and things you want to achieve, you should maintain perseverance and persistence - this is the way to ultimate success. Being too impatient and eager for quick results can easily lead to failure. It is more suitable to follow others, rather than taking on major responsibilities alone.',
    },
    {
      id: 3,
      imgPath: '/I Ching Cards Hexagram3.jpeg',
      title: 'Hexagram 3: Difficulty at the Beginnings',
      subTitle: 'A SCENE OF THE SPRING WOOD REGENERATING; THE IMPLICATION OF A DIFFICULT START.',
      overview: 'It is difficult to start any endeavor. During challenging times, it is appropriate to cultivate one\'s abilities, establish a solid foundation, and prepare for future success.',
      relationships: 'A difficult beginning. One wave after another comes, amidst wind and rain, with an unclear future.',
      career: 'Starting a business is difficult, one should focus more on cultivating one\'s own abilities and establishing a solid foundation.',
      wealth: 'Requires more cautious financial management.',
      advice: 'This hexagram represents "difficulty", but does not mean failure. If one can accumulate strength and overcome the "difficulty at the start", the future still holds limitless possibilities.',
    },
    {
      id: 4,
      imgPath: '/I Ching Cards Hexagram4.jpeg',
      title: 'Hexagram 4: Enlightenment',
      subTitle: 'A SCENE OF ENLIGHTENING THE IGNORANT; THE IMPLICATION OF A BEWILDERED SEARCH.',
      overview: 'Seeking advice from others can help facilitate the progress of matters.',
      relationships: 'Requires more time to understand and get to know the other person.',
      career: 'The current abilities are insufficient to handle the situation. More learning and seeking advice from others is needed.',
      wealth: 'Should invest cautiously and avoid taking risks.',
      advice: 'The abilities are insufficient to handle crises, the best strategy is to seek advice from those with experience and wisdom. But before seeking advice, one should prepare well, approach with sincerity, and avoid repeatedly asking the same questions.',
    },
    {
      id: 5,
      imgPath: '/I Ching Cards Hexagram5.jpeg',
      title: 'Hexagram 5: Waiting',
      subTitle: 'A SCENE OF DENSE CLOUDS BUT NO RAIN; THE IMPLICATION OF PATIENTLY AWAITING THE RIGHT MOMENT.',
      overview: 'The dragon is stranded in shallow waters, the timing is not yet right, it is best to patiently wait for the right opportunity.',
      relationships: 'Requires patience in waiting and communication.',
      career: 'Although there is ability, the right opportunity has not yet presented itself.',
      wealth: 'Patiently wait for returns, until negative factors are resolved, the financial fortunes will naturally improve.',
      advice: 'Patiently wait for the right opportunity to arrive, then make a full effort, and ultimate success will be achieved. Awaiting the opportune moment is the better strategy.',
    },
    {
      id: 6,
      imgPath: '/I Ching Cards Hexagram6.jpeg',
      title: 'Hexagram 6: Conflict',
      subTitle: 'A SCENE OF GOING AGAINST THE TIDE; THE IMPLICATION OF GROUNDLESS DISPUTES ARISING.',
      overview: 'Endless arguments over right and wrong.',
      relationships: 'Mired in conflict and quarrels.',
      career: 'Facing competition and disputes, also legal risks.',
      wealth: 'Need to handle financial matters cautiously, avoid getting embroiled in controversies and dangers.',
      advice: 'Be mindful that blessings and calamities are intertwined - what is gained may not necessarily be a blessing, what is lost may not necessarily be a curse. Excessive stubbornness is perilous, one should not go to extremes or be obstinate. The ability to renounce conflict is a auspicious sign, as peace and tranquillity are blessings. But if one insists on pursuing things to the end, even if victory is achieved, it may still bring subsequent misfortunes.',
    },
    {
      id: 7,
      imgPath: '/I Ching Cards Hexagram7.jpeg',
      title: 'Hexagram 7: The Army',
      subTitle: 'A SCENE OF BEING ON THE EDGE OF A PRECIPICE; THE INTENTION OF INFLUENCING THE MASSES THROUGH VIRTUE.',
      overview: 'The responsibility is heavy; the concerns are grave. But the righteous path will ultimately triumph over evil.',
      relationships: 'Both parties are unyielding, requiring one side to be willing to take on a guiding and supportive role, otherwise conflict will be difficult to avoid.',
      career: 'The responsibility is great, the challenges are formidable. Persistent, unwavering struggle is required, with long-term cultivation before any achievement can manifest.',
      wealth: 'Hardship yields rewards, one should seek righteous wealth, avoid speculative ventures. Only through arduous effort can one obtain worldly fortune.',
      advice: 'In leading military campaigns, one must first have a just cause, acting in accordance with reason and law, with a proper mandate. In hiring personnel, one should select experienced veterans, and never employ the inexperienced.',
    },
    {
      id: 8,
      imgPath: '/I Ching Cards Hexagram8.jpeg',
      title: 'Hexagram 8: Union',
      subTitle: 'A SCENE OF THE STARS SUPPORTING THE MOON; THE INTENTION OF ACHIEVING STABILITY AND PROGRESS.',
      overview: 'Go with the flow, live in harmony. Treat people with compassion, bless and benefit the masses.',
      relationships: 'The male\'s status is esteemed; the female is obedient and virtuous. Husband and wife are harmonious, the family prospers. Unmarried women must beware of third parties intervening.',
      career: 'Receiving the support of influential people, the business will achieve success.',
      wealth: 'Cooperative investments are advantageous, proceed step-by-step, mutually beneficial.',
      advice: 'Always think of others, broadly cultivate good relationships, and you will gain the goodwill of the people. Beware of arrogance, single-minded pursuit of profit, and acting recklessly, or else you will end up alienated by all.',
    },
    {
      id: 9,
      imgPath: '/I Ching Cards Hexagram9.jpeg',
      title: 'Hexagram 9: Gradual Accumulation',
      subTitle: 'A SCENE OF CLOUDY SKIES WITHOUT RAIN; THE INTENTION OF CULTIVATING AND ACCUMULATING STRENGTH.',
      overview: 'Small matters progress smoothly, like dripping water forming a river, continue to work hard and you will ultimately achieve great success.',
      relationships: 'Requires patience to accumulate feelings and experience.',
      career: 'Carefully prepare and wait for the right opportunity to come.',
      wealth: 'Elusive and unpredictable, the best strategy is to be defensive rather than aggressive.',
      advice: 'The future is full of uncertainty, so for now one should retreat and hold back, let go of grand aspirations, live in the present, and focus on achievable small goals right in front of you.',
    },
    {
      id: 10,
      imgPath: '/I Ching Cards Hexagram10.jpeg',
      title: 'Hexagram 10: Caution',
      subTitle: 'A SCENE OF WALKING ON THIN ICE; THE INTENT OF SEEKING SUCCESS AMIDST DANGER.',
      overview: 'Treat the ruler like a tiger, be cautious in speech and action, and keep a low profile.',
      relationships: 'A difficult-to-please "tigress"; must be treated with mutual respect.',
      career: 'Must strictly adhere to rules and regulations, do everything according to the rules, pay attention to the division of responsibilities between superiors and subordinates, or else major errors will surely occur.',
      wealth: 'Go with the flow of the times, seize opportunities when the time is right.',
      advice: 'In all matters, one must be extremely cautious and careful, walk on eggshells, and strictly follow the rules - only then can one succeed and thrive. Pay special attention to interpersonal relationships, clearly distinguish between superiors and subordinates, and strictly abide by the proper etiquette.',
    },
    {
      id: 11,
      imgPath: '/I Ching Cards Hexagram11.jpeg',
      title: 'Hexagram 11: Social Harmony',
      subTitle: 'A SCENE OF OVERALL PROSPERITY AND PEACE; AN INDICATION OF HARMONIOUS RELATIONSHIPS BETWEEN SUPERIORS AND SUBORDINATES.',
      overview: 'The affairs and life are smooth and prosperous, indicating a highly auspicious situation.',
      relationships: 'The couple is deeply in love and their relationship is stable, a perfect match.',
      career: 'Work is carried out efficiently and cleanly, with harmonious cooperation among colleagues.',
      wealth: 'Assets are abundant, and the financial situation is stable and abundant.',
      advice: 'Although everything is currently in good order, one should not be complacent and must remain vigilant. Maintain alertness and constantly be prepared for risk management, to avoid falling into crisis. Only by doing so can the current favorable situation be maintained in the long term, with blessings that last.',
    },
    {
      id: 12,
      imgPath: '/I Ching Cards Hexagram12.jpeg',
      title: 'Hexagram 12: Standstill',
      subTitle: 'A SCENE OF BLOCKAGE AND OBSTRUCTION; DISCORD BETWEEN YIN AND YANG.',
      overview: 'The current situation is blocked and obstructed, facing many difficulties and divisions.',
      relationships: 'The two parties are unable to communicate effectively and understand each other.',
      career: 'The career progression is not smooth, facing many obstacles and setbacks.',
      wealth: 'Currently encountering economic difficulties and financial losses.',
      advice: 'In addition to waiting for opportunities and awaiting a turn for the better, one must proactively review and adjust one\'s own behavior and plans. At the same time, actively seek new solutions to overcome the current difficulties. Through persistent and tireless efforts, flexible response, and maintaining a calm and composed attitude, one will be able to overcome the current difficulties, find solutions, and ultimately make progress.',
    },
    {
      id: 13,
      imgPath: '/I Ching Cards Hexagram13.jpeg',
      title: 'Hexagram 13: Fellowship',
      subTitle: 'A SCENE OF GENTLEMEN WITH THE SAME HEART; THE MEANING OF JOINT COOPERATION.',
      overview: 'Forming alliances with others, assisting each other to achieve success.',
      relationships: 'Harmony in the relationship, conjugal harmony, mutual care and support, creating a beautiful life for each other.',
      career: 'Conducive to team collaboration, working together to achieve goals. With mutual cooperation, success will be attained.',
      wealth: 'Benefiting from good interpersonal relationships, which is advantageous for acquiring wealth. Also skilled at maintaining interpersonal networks, able to capitalize on opportunities, leading to abundant financial resources.',
      advice: 'This is an extremely auspicious hexagram. It encourages you to have steadfast confidence and emphasize collaboration and mutual assistance with others. Through teamwork, you will surely be able to smoothly realize your goals and obtain fruitful returns. At the same time, you should also learn to share the outcomes with others and give back to society.',
    },
    {
      id: 14,
      imgPath: '/I Ching Cards Hexagram14.jpeg',
      title: 'Hexagram 14: Bountiful',
      subTitle: 'A SCENE OF THE SUN SHINING HIGH IN THE SKY; THE MEANING OF A BOUNTIFUL HARVEST FROM HARD WORK.',
      overview: 'Auspicious omens, all matters prosperous. Fortune like the sun at its zenith, great luck and great benefit.',
      relationships: 'Sweet and loving, a blissful and fulfilling romance.',
      career: 'Propitious stars shining, career successful.',
      wealth: 'Wealth and resources abundant, gains and profits bountiful.',
      advice: 'The "Great Abundance" hexagram is an extremely auspicious symbol, all matters will be greatly blessed. This is an encouragement for you to firmly believe in your own potential, bravely pursue your dreams and realize your goals. As long as you approach with diligent work, excellent leadership, and a generous spirit, you will surely achieve brilliance and reap abundant rewards. At the same time, you should also learn to share your accomplishments and wealth with others, contributing your part to society.',
    },
    {
      id: 15,
      imgPath: '/I Ching Cards Hexagram15.jpeg',
      title: 'Hexagram 15: Modesty',
      subTitle: 'A SCENE OF A HIDDEN GEM IN A QUIET VALLEY; THE MEANING OF BEING HUMBLE AND ACCOMMODATING IN ONE\'S CONDUCT.',
      overview: 'In all matters, be humble and cautious, learn to retreat in order to advance; to hold back in order to attack, and this will allow for successful developments.',
      relationships: 'One must learn to yield and understand the needs of the other party.',
      career: 'Achieve success without complacency, humbly retreat, need to act prudently and avoid taking risks.',
      wealth: 'Steady and sustained wealth, the best strategy is to seek wealth through stable and steady methods.',
      advice: 'The humility hexagram represents cultivating oneself, an attitude of using virtue to influence others. Because of modest, one becomes richer; because of humility, one becomes loftier; achieving success without complacency, one becomes greater. With such an attitude, one will gain more wisdom, growth, and richness in interpersonal relationships.',
    },
    {
      id: 16,
      imgPath: '/I Ching Cards Hexagram16.jpeg',
      title: 'Hexagram 16: Preparation',
      subTitle: 'A SCENE OF THUNDER SHAKING THE EARTH; THE MEANING OF SEEING NEW OPPORTUNITIES IN AFFAIRS.',
      overview: 'Successful developments, acting in accordance with public sentiment and public opinion will lead to success.',
      relationships: 'A pleasant and harmonious relationship. But man’s side should avoid the troubles of romantic entanglements.',
      career: 'Smooth progress and achieving success.',
      wealth: 'Increased income and stable wealth.',
      advice: 'When facing difficulties, remain alert and guard against potential risks. At the same time, maintain an optimistic mindset, believe in one\'s ability to overcome challenges and find solutions. Please adapt to changes, maintain harmony and balance, and approach challenges with a positive attitude. With such an attitude, you will be able to overcome difficulties and create even better results.',
    },
    {
      id: 17,
      imgPath: '/I Ching Cards Hexagram17.jpeg',
      title: 'Hexagram 17: Following',
      subTitle: 'A SCENE OF SAILING WITH THE FLOW OF THE WATER; THE MEANING OF GOING WITH THE FLOW AND BEING CONTENT WITH ONE\'S CIRCUMSTANCES.',
      overview: 'Since gains and losses are impermanent, one should be tranquil and non-striving, go with the flow of nature, seize the appropriate opportunities, and choose the most advantageous situations to proceed.',
      relationships: 'Husband and wife in harmony.',
      career: 'There are dilemmas where it is difficult to choose, do not be too concerned with gains and losses, just go along with the natural flow.',
      wealth: 'Harmonious attitude generates wealth, should not rashly engage in investments outside one\'s expertise, should be more practical.',
      advice: 'In all matters, one should go with the natural flow, act according to circumstances, do what makes oneself content, do not over-strive. But one must not mistake going with the flow as acting casually, or else affairs will deteriorate.',
    },
    {
      id: 18,
      imgPath: '/I Ching Cards Hexagram18.jpeg',
      title: 'Hexagram 18: Decay',
      subTitle: 'A SCENE OF DECAY AND PESTS; THE MEANING OF NOT ADVANCING THEN RETREATING.',
      overview: 'There has already appeared a corrupt and stagnant downturn, one must be careful not to be cheated and deceived.',
      relationships: 'Influenced by bewitchment, confused and infatuated, one must definitely be careful not to be cheated.',
      career: 'Dangers and traps abound, absolutely cannot act rashly and recklessly.',
      wealth: 'Utterly dismal, also need to be careful not to be cheated.',
      advice: 'The Gu hexagram faces a deep-rooted and intractable problem, not just solving the immediate issues. So while striving to solve the immediate problems, it is more important to re-formulate new plans and establish a brand new future.',
    },
    {
      id: 19,
      imgPath: '/I Ching Cards Hexagram19.jpeg',
      title: 'Hexagram 19: Approach',
      subTitle: 'A SCENE OF LOOKING DOWN FROM A HIGH VANTAGE POINT; THE MEANING OF THE RIGHTEOUS WAY GRADUALLY FLOURISHING.',
      overview: 'The superior should personally go down to inspect and urge on the subordinates, understanding the sentiments of the people. With interaction and exchange, matters can be smoothly carried out.',
      relationships: 'There are signs that one party is behaving in an overly forceful manner. One should learn to adopt a gentle and yielding attitude.',
      career: 'The prospects are auspicious, with the help of an important person. The space for development is very large, as long as you work diligently, you will surely achieve results.',
      wealth: 'The omen for legitimate wealth is auspicious, one should not overly pursue illegitimate wealth.',
      advice: 'Please maintain a joyful heart, and also maintain a gentle attitude and approach when paying attention to and urging on matters. With the help of an important person, you will be able to complete your tasks, and you must seize the right opportunity to succeed.',
    },
    {
      id: 20,
      imgPath: '/I Ching Cards Hexagram20.jpeg',
      title: 'Hexagram 20: Panorama',
      subTitle: 'A SCENE OF UNPREDICTABLE WINDS AND CLOUDS; THE MEANING OF OBSERVING THINGS IN DEPTH.',
      overview: 'All matters should be observed and learned about diligently and sincerely, which will naturally lead to gains.',
      relationships: 'This is a relationship of distant admiration and one-sided love.',
      career: 'One should carefully observe the way one\'s superiors handle things, as this will be helpful for one\'s own development.',
      wealth: 'One should consider things cautiously and not be overly aggressive.',
      advice: 'The key to this auspicious hexagram is the ability to get close to the essence of things, and to carefully observe their development and changes, enriching oneself through this process. On the contrary, if one is unable to observe closely, it will be an ominous sign. But even if one is able to get close, if one is merely an outsider looking on with curiosity, that too will be in vain.',
    },
    {
      id: 21,
      imgPath: '/I Ching Cards Hexagram21.jpeg',
      title: 'Hexagram 21: Punishment',
      subTitle: 'A SCENE OF SEVERE PUNISHMENT AND STRICT LAWS; THE MEANING OF PREVENTING PROBLEMS BEFORE THEY OCCUR.',
      overview: 'There is antagonism and competition between people, which may lead to conflicts and disruptions.',
      relationships: 'There is friction, requiring mediation. Be aware of potential third-party interference.',
      career: 'There are malicious people causing trouble within the company, with internal conflicts and disputes.',
      wealth: 'Investments are unfavourable.',
      advice: 'In this situation, the most important thing is to avoid acting impulsively or emotionally, and to avoid getting dragged into unnecessary verbal disputes. Instead, calmly analyse the situation and take appropriate measures to eliminate the root of the problems, minimizing potential damage. This requires rational analysis and wise actions.',
    },
    {
      id: 22,
      imgPath: '/I Ching Cards Hexagram22.jpeg',
      title: 'Hexagram 22: Beauty',
      subTitle: 'A SCENE OF SUPERFICIAL GRANDEUR; THE MEANING OF POLISHED AND DECORATED.',
      overview: 'The affairs appear glamorous on the surface, but lack substance.',
      relationships: 'Insincere interpersonal relationships.',
      career: 'One needs to demonstrate talent and charisma in order to achieve success.',
      wealth: 'Do not invest in popular projects, as conservative and cautious is the best approach.',
      advice: 'Please focus on cultivating your external image and internal cultivation, continually improving yourself and pursuing excellence. At the same time, maintain self-confidence and self-esteem, and foster inner calmness and balance. Through this attitude, you will be able to exhibit your beauty and charm, in order to achieve harmony between the internal and external.',
    },
    {
      id: 23,
      imgPath: '/I Ching Cards Hexagram23.jpeg',
      title: 'Hexagram 23: Falling Apart',
      subTitle: 'A SCENE OF THE YIN STRIPPING THE YANG; THERE WILL BE SOME LOSSES.',
      overview: 'The affairs are not auspicious, the fortune is weak and depressed, and one should not act rashly.',
      relationships: 'There is a lack of fidelity in romantic affairs, with many lovers and a lack of restraint. Be careful not to lose both people and money.',
      career: 'Be cautious of encountering companies or situations that aim to swindle money.',
      wealth: 'A total loss of capital.',
      advice: 'In this unfavorable situation, the positive energy is about to be depleted. This is not the time to take major action. The best choice now is to observe the situation calmly, wait for the turning of the tide, and restart when the circumstances enter a new cycle. This requires patience and acting at the right time.',
    },
    {
      id: 24,
      imgPath: '/I Ching Cards Hexagram24.jpeg',
      title: 'Hexagram 24: Recovery',
      subTitle: 'A SCENE OF RENEWAL; THE INTENTION TO REPAIR THE DILAPIDATED.',
      overview: 'The adversity is gradually receding, and good fortune is gradually arriving.',
      relationships: 'There are complex situations or a return to the past.',
      career: 'Seek out new opportunities, as now is the ideal time.',
      wealth: 'The parts that were previously restricted are gradually being resolved.',
      advice: 'In the midst of adversity, maintain resilience and hope. Reorganize and rebuild yourself and gain wisdom and insights from past experiences. Please maintain hope and confidence, believing that the difficulties are only temporary and there will be turning points and improvements in the future. Through this perspective, you will be able to overcome the challenges, experience revival and rebirth, and welcome new opportunities and challenges. Persisting and positively facing the difficulties will bring you greater growth and success.',
    },
    {
      id: 25,
      imgPath: '/I Ching Cards Hexagram25.jpeg',
      title: 'Hexagram 25: Innocence',
      subTitle: 'A SCENE OF SUDDEN AND UNEXPECTED DISASTERS; THE IMPORTANCE OF MAINTAINING UPRIGHTNESS AND TRANQUILITY.',
      overview: 'Unavoidable disasters, the wisest approach is to remain calm and composed, accepting the situation with equanimity. Improper actions will only invite even greater calamities.',
      relationships: 'Misunderstandings outnumber mutual understanding, favourable circumstances have turned unfavourable, beset by constant turmoil.',
      career: 'Unexpected troubles keep piling up, beyond your ability to fully prepare for.',
      wealth: 'The more you invest and manage your finances, the heavier your financial losses will become.',
      advice: 'When confronted with disasters, do not descend into bitterness and resentment, nor resort to reckless behaviour, as that will only exacerbate the situation beyond recovery. Maintain your composure and adhere steadfastly to propriety - this is how you will find a way through the difficulties. Beware of succumbing to greed and unrealistic ambitions that exceed your actual capabilities.',
    },
    {
      id: 26,
      imgPath: '/I Ching Cards Hexagram26.jpeg',
      title: 'Hexagram 26: Amassing',
      subTitle: 'A SCENE OF A BOAT SAILING IN SHALLOW WATER; THE MEANING OF ACCUMULATING SMALL THINGS TO ACHIEVE GREATNESS.',
      overview: 'Brimming with vitality, accumulate your strength. When the time is ripe, you will be able to accomplish great things.',
      relationships: 'Your romantic and professional lives will both flourish, complementing each other.',
      career: 'You will be highly valued, achieving success and gaining renown.',
      wealth: 'Steady progress, with boundless development prospects ahead.',
      advice: 'Your career prospects are excellent. Dare to forge ahead, and you will be granted important opportunities. You must make a concerted effort and persevere relentlessly in pursuing your goals. Through continually accumulating wisdom and experience, you will be able to grow and thrive in success and prosperity, well-prepared for future challenges. Maintaining a prudent and calm attitude will also assist you in attaining greater achievements in your career.',
    },
    {
      id: 27,
      imgPath: '/I Ching Cards Hexagram27.jpeg',
      title: 'Hexagram 27: Nourishment',
      subTitle: 'A SCENE OF CAUTIOUS SPEECH AND DIET; THE MEANING OF RESTING AND NOURISHING THE SPIRIT.',
      overview: 'One should maintain a low-key and humble approach, avoiding excessive ostentation.',
      relationships: 'One should pay attention to and nourish the emotional needs of one\'s partner.',
      career: 'One needs to consolidate one\'s basic capabilities, guard against verbal disputes, and continuously enrich one\'s own skills.',
      wealth: 'In investment and finance, one should lean towards a conservative approach and choose low-risk methods.',
      advice: 'If the timing for undertaking matters is not yet ripe, it is best to retreat and cultivate oneself. In all aspects, one should maintain a calm and self-disciplined attitude, be careful to avoid potential verbal disputes, whether in speech or diet. Maintaining a restrained and cautious mindset will be helpful in avoiding unnecessary troubles.',
    },
    {
      id: 28,
      imgPath: '/I Ching Cards Hexagram28.jpeg',
      title: 'Hexagram 28: Overload',
      subTitle: 'A SCENE OF OVERWHELMED; THE MEANING OF OVERBURDENED.',
      overview: 'The pressure is too great, difficult to bear. It is advisable to seek alternative paths.',
      relationships: 'Too much accommodation and tolerance, the relationship cannot last long.',
      career: 'Have the generals but lack the troops, difficult to accomplish great things. Though physically and mentally prepared, still beset by turbulence.',
      wealth: 'There is financial pressure and hardship, requiring careful financial planning and prudent investment.',
      advice: 'One should not force things, nor should one submit to circumstances for the sake of superficial harmony, nor should one blindly accept everything just to be a good person. When facing matters, one must act according to one\'s capabilities, and wisely judge when to advance or retreat. If one rashly takes on challenges beyond one\'s abilities, the result will inevitably be toil without reward.',
    },
    {
      id: 29,
      imgPath: '/I Ching Cards Hexagram29.jpeg',
      title: 'Hexagram 29: Abysmal',
      subTitle: 'A SCENE OF PERILOUS PITFALLS; THE MEANING OF OMINOUS DANGERS ENCIRCLING.',
      overview: 'Both internally and externally, there are dangers, the situation is hazardous and arduous.',
      relationships: 'Trapped in an impasse, difficult to break through.',
      career: 'There are formidable difficulties in both advancement and retreat.',
      wealth: 'Fallen into hardship, an omen of financial losses. Losing a little when hoping to gain much, it is advisable to cut losses early, lest one exhaust one\'s family wealth.',
      advice: 'In the face of this adversity, one needs to maintain a calm and resolute attitude, in order to overcome the daunting challenges and achieve growth.',
    },
    {
      id: 30,
      imgPath: '/I Ching Cards Hexagram30.jpeg',
      title: 'Hexagram 30: Bright',
      subTitle: 'A SCENE OF A BRIGHT, SUNNY DAY; THE MEANING OF ILLUMINATING AND FAR-REACHING.',
      overview: 'Knowing the way of softness and moderation, all matters will be auspicious and felicitous. If one loses this, then many affairs will not go smoothly.',
      relationships: 'Displaying one\'s most charming appearance, mutual affection is ignited, in the midst of a sweet honeymoon period.',
      career: 'With a broad network of connections and the help of influential people, everything proceeds favourably. But one must be down-to-earth, not aiming for overly grand ambitions.',
      wealth: 'Sales figures are still acceptable, but actual profits still await improvement.',
      advice: 'This hexagram has auspicious omens, but with certain conditions. If one can proceed step-by-step, with softness and moderation, then naturally all will go well; otherwise, there will be variables. One must also be attentive that the external appearance is not overly beautiful and unsubstantial, avoiding falling into the trap of cunning and extravagance; or that one does not become overly clever, lacking in comprehensive wisdom.',
    },
    {
      id: 31,
      imgPath: '/I Ching Cards Hexagram31.jpeg',
      title: 'Hexagram 31: Courtship',
      subTitle: 'A SCENE OF HARMONIOUS INTERACTION BETWEEN THE SEXES; THE MEANING OF UNIMPEDED COMMUNICATION.',
      overview: 'Peach blossoms are in full bloom, popularity is high, make good use of the power of harmony, and all will go well.',
      relationships: 'The man and woman are in loving harmony; the marriage is auspicious and felicitous.',
      career: 'Receiving help from influential people, all matters proceed smoothly and easily.',
      wealth: 'Wealth is growing, the economic situation is improving. Selecting investment projects that truly resonate with you will be more advantageous.',
      advice: 'This indicates you are already in sync with the circumstances. Whether your inner thoughts are of good or ill, what the individual is thinking about is likely already happening in the real world.',
    },
    {
      id: 32,
      imgPath: '/I Ching Cards Hexagram32.jpeg',
      title: 'Hexagram 32: Endurance',
      subTitle: 'A SCENE OF CONSTANT MARITAL HARMONY; THE MEANING OF UNREMITTING EFFORT.',
      overview: 'Stable and steady, flowing like a gentle stream.',
      relationships: 'Stable and happy, able to grow old together.',
      career: 'Stable, with each fulfilling their role, but lacking in reform and innovation, lacking the drive for growth.',
      wealth: 'Continuous stable growth in wealth, the economy remains prosperous.',
      advice: 'If what you hope for is stability, then this hexagram is a great blessing. But if you yearn for challenge and growth, then this hexagram contains the hidden obstacle of future growth. For being overly stable will lead to a lack of reform and innovation.',
    },
    {
      id: 33,
      imgPath: '/I Ching Cards Hexagram33.jpeg',
      title: 'Hexagram 33: Retreat',
      subTitle: 'A SCENE OF PRESERVING WISDOM; THE MEANING OF WITHDRAWING TO THE SECLUDED SPRING AND FOREST.',
      overview: 'The auspiciousness of the Retreat hexagram lies in being able to distance oneself from the fray, distancing from right and wrong, rather than from any great accomplishments.',
      relationships: 'The partner is not suitable, it\'s best to avoid them if possible.',
      career: 'Unable to achieve success, one can only seek to preserve oneself. Retreat is the best course of action.',
      wealth: 'There will be economic contraction and restraint, it\'s best to take a temporary rest.',
      advice: 'When sketchy people gain power, do not think about achieving success and glory. The faster and further you can retreat, the better. Letting go of fame and fortune, and seeking seclusion in the deep mountains, is the best path forward - do not cling stubbornly to your own views.',
    },
    {
      id: 34,
      imgPath: '/I Ching Cards Hexagram34.jpeg',
      title: 'Hexagram 34: Awesome',
      subTitle: 'A SCENE OF THUNDEROUS LIGHTNING; THE MEANING OF A FORMIDABLE PRESENCE.',
      overview: 'It is slightly auspicious, with an impressive presence, but if your fame becomes too high, you must be able to withstand the test.',
      relationships: 'While the words express deep love, the heart is not truly invested, and it may end in abandonment.',
      career: 'It is advisable to maintain a low-key and conservative approach, staying level-headed. Avoid ostentatious actions, for that will lead to failure.',
      wealth: 'The outward appearance is glamorous, but inwardly there is a sense of dissatisfaction.',
      advice: 'This omen represents thunderous rumbling that shakes the earth, a majestic scene with an irresistible momentum. However, in all matters, one should remain calm, carefully analyze the situation, and avoid reckless actions. This way, you can avoid unnecessary mistakes and disasters.',
    },
    {
      id: 35,
      imgPath: '/I Ching Cards Hexagram35.jpeg',
      title: 'Hexagram 35: Promotion',
      subTitle: 'A SCENE OF LUSH SPLENDOR; THE MEANING OF THE MINISTER MEETS THE LORD.',
      overview: 'It is auspicious, you can boldly move forward, and will succeed in all endeavours.',
      relationships: 'You will receive the love and admiration of the opposite sex.',
      career: 'You will be highly valued, able to fully utilize your talents, and experience three consecutive promotions with a raise in salary.',
      wealth: 'You need not take any risks, and will be able to enjoy stable and continuous profits.',
      advice: 'This is a greatly auspicious omen, your intelligence and abilities will be recognized. This is an extremely fortunate period, you can unleash yourself and boldly forge ahead. Whether in relationships, career, or wealth, you will experience excellent performance and development. As long as you maintain a prudent yet proactive attitude, you will surely achieve great accomplishments in all areas.',
    },
    {
      id: 36,
      imgPath: '/I Ching Cards Hexagram36.jpeg',
      title: 'Hexagram 36: Darkening of the Light',
      subTitle: 'A SCENE OF DARKNESS PREVAILS; THE MEANING OF ABANDONING LIGHT FOR DARKNESS.',
      overview: 'In the dark environment, all affairs are unable to proceed smoothly. This is an adverse situation of being mired in difficulty.',
      relationships: 'Everything is not going well. The situation is unstable, it is best to retreat and protect oneself.',
      career: 'Despite the multitude of setbacks, it is wise to exercise prudence and self-preservation. One should absolutely refrain from reckless actions.',
      wealth: 'Income does not cover expenses, inevitably leading to gradual depletion of resources.',
      advice: 'The situation is chaotic, whether seeking wealth or career, there is no progress. At this stage, one should not overly pursue fame and fortune, but rather make self-preservation and survival the primary mission. Maintaining wisdom and self-preservation is a wise strategy.',
    },
    {
      id: 37,
      imgPath: '/I Ching Cards Hexagram37.jpeg',
      title: 'Hexagram 37: The Family',
      subTitle: 'A SCENE OF HARMONIZING THE FAMILY AND GOVERNING THE STATE; THE MEANING OF BEARING FRUIT FROM BLOSSOMS.',
      overview: 'Good interpersonal relationships lead to good fortune.',
      relationships: 'A happy marriage, with a wise and virtuous wife who can help her husband prosper.',
      career: 'With the help of influential people, one receives the care and support of superiors. All matters naturally proceed smoothly and seamlessly.',
      wealth: 'With the help of influential people, one\'s financial fortunes flourish.',
      advice: 'When the family is united, their power is as strong as steel. Upholding ethics and fulfilling one\'s responsibilities, benefiting both oneself and others. With a team spirit in all matters, one\'s abilities are amplified beyond the ordinary. However, if one is selfish and self-interested, it is inevitable that they will be abandoned by their loved ones.',
    },
    {
      id: 38,
      imgPath: '/I Ching Cards Hexagram38.jpeg',
      title: 'Hexagram 38: Opposition',
      subTitle: 'A SCENE OF CONFLICTING POSITIONS; THE MEANING OF THE CLASH OF WILLS.',
      overview: 'There is discord among people. If you can accomplish tasks independently, there may be some good fortune. Otherwise, efforts should be made to resolve interpersonal issues.',
      relationships: 'Both parties have conflicting values, so it might be better to break up sooner rather than later.',
      career: 'Beware of malicious individuals spreading rumours and having poor interpersonal relationships. You may need to struggle alone.',
      wealth: 'When pursuing wealth, disputes and conflicts are common. Prosperity comes from maintaining harmony.',
      advice: 'Discord among people does not necessarily mean a negative outcome. It is important to learn the wisdom of accommodating and harmonizing, respecting each other\'s differences, and maintaining peaceful coexistence. Transforming "differences" into "harmony" is a valuable lesson to learn.',
    },
    {
      id: 39,
      imgPath: '/I Ching Cards Hexagram39.jpeg',
      title: 'Hexagram 39: Limping',
      subTitle: 'A SCENE OF A CHALLENGING JOURNEY; THE MEANING OF THE DIFFICULTY OF MOVING FORWARD.',
      overview: 'The path ahead is filled with obstacles and challenges, so it is crucial not to act rashly.',
      relationships: 'Currently, the romantic situation is stagnant, and there is no progress.',
      career: 'Facing difficulties and challenges, it is wise to recognize the obstacles and consider stepping back to focus on learning and personal growth.',
      wealth: 'Beware of the risk of financial losses and prioritize preserving what you have.',
      advice: 'It is not advisable to take hasty actions in all aspects. Despite the hardships on the road ahead, perseverance can lead to eventual success.',
    },
    {
      id: 40,
      imgPath: '/I Ching Cards Hexagram40.jpeg',
      title: 'Hexagram 40: Deliverance',
      subTitle: 'A SCENE OF ESCAPE FROM THE SYMBOL OF MISFORTUNE; THE MEANING OF THE POSSIBILITY OF RESOLVING DIFFICULTIES.',
      overview: 'Misfortune is diminishing, and good fortune is about to arrive.',
      relationships: 'Previous misunderstandings and barriers can be resolved. For couples who have been in a long-term relationship, marriage is a viable option. Otherwise, there is a possibility of breaking up without the chance for reconciliation.',
      career: 'The difficult path has been traversed, and now is the time for a fresh start.',
      wealth: 'There will be a rebound in financial fortunes after hitting rock bottom.',
      advice: 'When facing various situations, it is advisable to take proactive action, as difficulties can naturally be overcome in the short term. However, it is important to remember not to act rashly when the timing is not right, and it is equally unwise to create unnecessary troubles when there are no pressing matters.',
    },
    {
      id: 41,
      imgPath: '/I Ching Cards Hexagram41.jpeg',
      title: 'Hexagram 41: Loss',
      subTitle: 'A SCENE OF THE PRINCIPLE OF SACRIFICING SHORT-TERM GAINS FOR LONG-TERM BENEFITS; THE CONCEPT OF SACRIFICING FIRST AND GAINING LATER.',
      overview: 'As long as you possess a long-term perspective, are willing to make small sacrifices temporarily, and manage resources and energy wisely, you can still expect brighter days ahead.',
      relationships: 'Love that demands sacrifices and incurs financial losses.',
      career: 'It is advisable to sacrifice personal interests for the sake of higher-ups and aim for bigger achievements in the long run.',
      wealth: 'There may be financial losses or setbacks.',
      advice: 'When facing various situations, it is important to have a long-term perspective and not solely focus on immediate gains and losses. By letting go of narrow-minded thinking, you can enjoy smooth progress without enduring unnecessary setbacks. However, if you only prioritize immediate benefits and neglect the bigger picture, it can lead to significant unfavorable consequences.',
    },
    {
      id: 42,
      imgPath: '/I Ching Cards Hexagram42.jpeg',
      title: 'Hexagram 42: Benevolence',
      subTitle: 'A SCENE OF THE BALANCE BETWEEN LOSS AND GAIN; THE ASSISTANCE OF INFLUENTIAL INDIVIDUALS.',
      overview: 'Favorable outcomes await in various aspects of life, with success in romance and prosperity in both official and financial matters.',
      relationships: 'A harmonious union between a man and a woman, seemingly destined.',
      career: 'Dedicate yourself to work, seize multiple opportunities, and focus on self-improvement. Recognition from superiors will pave the way for future advancements.',
      wealth: 'Smooth sailing, with financial prosperity.',
      advice: 'This hexagram signifies the balance between loss and gain. Seize opportunities and benefit from the assistance of influential individuals. Help others within your capabilities to establish positive and extensive relationships. Whether in work, career, or relationships, success and mutual benefits can be achieved. Avoid selfishness and solely pursuing personal enjoyment without giving back. If one only prioritizes oneself without willingness to contribute, good fortune will eventually fade away.',
    },
    {
      id: 43,
      imgPath: '/I Ching Cards Hexagram43.jpeg',
      title: 'Hexagram 43: Resolution',
      subTitle: 'A SCENE OF A FLOOD-LIKE DISASTER; THE IMPORTANCE OF DECISIVE DECISION-MAKING.',
      overview: 'Faced with challenging individuals or issues, it is necessary to bravely confront them and make quick decisions.',
      relationships: 'Dealing with a difficult and stubborn partner, it is advisable to establish clear boundaries.',
      career: 'There may be conflicts and power struggles in the workplace. If possible, employing benevolence to win people\'s hearts can be effective, but caution must be exercised to avoid backfiring.',
      wealth: 'It is important to carefully consider and assess risks. When necessary, take decisive action and know when to cut losses.',
      advice: 'Be resolute in your inner self, but employ tactful means. This hexagram reminds you to make important decisions quickly in order to resolve problems.',
    },
    {
      id: 44,
      imgPath: '/I Ching Cards Hexagram44.jpeg',
      title: 'Hexagram 44: Temptation',
      subTitle: 'A SCENE OF THE ENCOUNTER BETWEEN MEN AND WOMEN; THE MEANING OF THE EBB AND FLOW OF GATHERINGS.',
      overview: 'This is merely a temporary encounter that cannot last.',
      relationships: 'It is only a brief encounter and should not be taken seriously. For women, being unfaithful or overly dominant is not suitable for marriage.',
      career: 'There will be occasional strokes of luck. Women have smoother career paths, while men need to follow the guidance of women.',
      wealth: 'There may be short-term good fortune, but in the long run, it will bring unfavorable results. Favorable for women.',
      advice: 'Since this is just an "encounter," any good fortune that arises is only temporary, and long-term plans should not be based on it. This hexagram is exclusive to women, and men should not take the lead in various matters; they may need to listen to the instructions of women. Women, on the other hand, could grasp the entire situation. Women may encounter helpful individuals, but they should be cautious of romantic entanglements.',
    },
    {
      id: 45,
      imgPath: '/I Ching Cards Hexagram45.jpeg',
      title: 'Hexagram 45: Congregation',
      subTitle: 'A SCENE OF THE CARP JUMPING OVER THE DRAGON GATE; THE MEANING OF THE GATHERING OF ELITES.',
      overview: 'Seize the advantages of favorable timing, favorable location, and harmonious relationships to enjoy increasing fame.',
      relationships: 'Welcomed by many, frequently surrounded by people.',
      career: 'Benefiting from the assistance of influential people, everything goes smoothly.',
      wealth: 'Accumulating wealth from various sources, prosperous financial fortune.',
      advice: 'The Carp Jumping over the Dragon Gate signifies that personal affairs can progress smoothly. It brings benefits to uniting people for collaborative endeavors or seeking the wisdom of respected elders. In all aspects of life, it is advisable to take grand and magnificent actions rather than being overly frugal. This hexagram is favorable for long-distance travel but not for withdrawing or clinging to old ways.',
    },
    {
      id: 46,
      imgPath: '/I Ching Cards Hexagram46.jpeg',
      title: 'Hexagram 46: Growth',
      subTitle: 'A SCENE OF THE WIND RISING FROM THE GROUND; THE MEANING OF THE TRANSFORMATION FROM WEAKNESS TO STRENGTH.',
      overview: 'It is advisable to be proactive. With divine assistance, everything will go smoothly.',
      relationships: 'Mutual accommodation and understanding lead to an increase in affection.',
      career: 'Staying grounded and steadily growing will lead to stability. If there are influential people supporting you, it can accelerate your promotion.',
      wealth: 'Financial fortune is stable and on the rise in terms of regular income, but it may not be through windfalls or speculative gains.',
      advice: 'Growth is inherently slow and requires time. However, with patience, success is guaranteed one day. If you can find influential people to assist you, it can expedite progress. Avoid being overly ambitious without the necessary skills.',
    },
    {
      id: 47,
      imgPath: '/I Ching Cards Hexagram47.jpeg',
      title: 'Hexagram 47: Adversity',
      subTitle: 'A SCENE OF A DRAGON SWIMMING IN SHALLOW WATER; THE MEANING OF DIFFICULTIES AND OBSTACLES.',
      overview: 'It indicates being in a state of extreme poverty and destitution.',
      relationships: 'If there is no fate, it is best not to force it.',
      career: 'Progress is difficult, and there seems to be no prospects.',
      wealth: 'Financial fortune is in a state of extreme poverty and destitution, with a risk of bankruptcy or even debt.',
      advice: 'We should uphold moral principles and act cautiously in order to seek alternatives in difficult situations. In such circumstances, excessive words not only lead to failure but also invite humiliation. Only by demonstrating noble character regardless of poverty or wealth and accumulating over time will we find opportunities for a turnaround.',
    },
    {
      id: 48,
      imgPath: '/I Ching Cards Hexagram48.jpeg',
      title: 'Hexagram 48: The Well',
      subTitle: 'A SCENE OF DRAWING WATER TO NURTURE THE PEOPLE; THE MEANING OF HAVING WELL-ORGANIZED SYSTEMS.',
      overview: 'Mutual assistance and mutual benefit are advantageous, leading to a state of peace and harmony. Resources must be developed, and talents must be nurtured and recommended in order to fully utilize their abilities and resources.',
      relationships: 'Supporting each other mutually makes a good couple.',
      career: 'Cultivating one\'s professional skills consistently will naturally lead to success.',
      wealth: 'Financial fortune is average, and it is advisable to adopt a conservative approach.',
      advice: 'The key lies in maintaining clarity and integrity like a well. When the water is clear, people will gather around, and when one possesses virtue and goodness, people will be willing to follow. Approach everything with a mindset of mutual assistance and cultivate positive relationships. Emphasize teamwork, cooperation, and synergy. Manifest personal value in everyday life and contribute to society, and blessings will naturally follow. Collaboration and resource-sharing are necessary. Beware of seeking immediate gains, as disasters often arise from such actions.',
    },
    {
      id: 49,
      imgPath: '/I Ching Cards Hexagram49.jpeg',
      title: 'Hexagram 49: Reform',
      subTitle: 'A SCENE OF THE PROCESS OF REFINING GOLD; THE MEANING OF BREAKING OLD AND ESTABLISHING NEW.',
      overview: 'Good fortune comes from innovation and there are no excessive faults, while misfortune arises from clinging to the old.',
      relationships: 'Old relationships are like dreams, and new love is on the horizon.',
      career: 'Success can only be achieved through reform. There may be hardships initially, but hard work will lead to eventual success.',
      wealth: 'Financial fortune lies in seeking innovation for wealth, bringing hope.',
      advice: 'If one can introspect and undergo self-reform, it will bring auspiciousness. Refusing to embrace innovation, clinging to the old and hoping for others to bring change will result in a revolution. After successful reform, it is necessary to employ a policy of reconciliation and gentle means to soothe public sentiment, in order to achieve overall harmony and success.',
    },
    {
      id: 50,
      imgPath: '/I Ching Cards Hexagram50.jpeg',
      title: 'Hexagram 50: The Cauldron',
      subTitle: 'A SCENE OF A COOKING VESSEL; THE MEANING OF THE NEED TO DISCARD THE OLD AND EMBRACE THE NEW.',
      overview: 'It is highly auspicious. Success can be achieved by working together with unity and cooperation, leading to the emergence of new opportunities. There will be influential people offering assistance.',
      relationships: 'For those who are seeking love, a new romantic relationship may arise quickly. For those who are already in a relationship, beware of the possibility of a third party entering.',
      career: 'There will be influential people supporting you, leading to success and obtaining positions of authority.',
      wealth: 'Financial fortune is favorable through cooperative efforts, bringing prosperity.',
      advice: 'If you approach matters with a steady and sincere attitude, keeping your promises and gaining the recognition and support of others, success will be inevitable. However, avoid superficial appearances and internal deception, as well as excessive pursuit of achievements and vanity. Otherwise, temporary success may ultimately end in failure.',
    },
    {
      id: 51,
      imgPath: '/I Ching Cards Hexagram51.jpeg',
      title: 'Hexagram 51: Shock',
      subTitle: 'A SCENE OF THUNDER SHAKING THE LAND; THE MEANING OF A STATE OF FEARFUL RESILIENCE.',
      overview: 'There may be initial fear followed by eventual joy. Change is unpredictable, with potential for both positive and negative outcomes.',
      relationships: 'There is a risk of emotional upheaval. However, if handled with caution and care, the situation can be resolved.',
      career: 'Unexpected changes may occur. It is advised to be cautious and unfearful, as this leads to favourable outcomes.',
      wealth: 'Financial fortune resembles a roller coaster ride, with unpredictable ups and downs.',
      advice: 'When facing changes, it is important to be cautious and alert due to fear. Handle matters with care and prudence to avoid danger. By doing so, you can navigate through potential difficulties and find blessings. Conversely, if you become numb and indifferent to changes, there may be subsequent disasters.',
    },
    {
      id: 52,
      imgPath: '/I Ching Cards Hexagram52.jpeg',
      title: 'Hexagram 52: Blockage',
      subTitle: 'A SCENE OF A MOUNTAIN PASS CLOSED OFF; THE MEANING OF THE NEED FOR CAUTIOUS AND STRATEGIC PROGRESS.',
      overview: 'There is no progress made, spinning in circles, and achieving nothing.',
      relationships: 'Passing by each other without being able to meet, facing multiple obstacles.',
      career: 'Experiencing stagnation and facing great difficulties in development.',
      wealth: 'Financial fortune is stagnant, and remaining still is better than acting.',
      advice: 'When it is time to stop, one should stop and avoid acting recklessly. Staying within one\'s boundaries and striving to avoid mistakes is the best strategy.',
    },
    {
      id: 53,
      imgPath: '/I Ching Cards Hexagram53.jpeg',
      title: 'Hexagram 53: Development',
      subTitle: 'A SCENE OF THE RISING SUN IN THE EAST; THE MEANING OF THE IMPORTANCE OF GRADUAL PROGRESS.',
      overview: 'Diligent efforts and progress can lead to achievements, but it is important to proceed gradually and step by step.',
      relationships: 'Starting as friends, relationships can steadily develop.',
      career: 'Setting goals in stages and following a systematic and gradual approach can lead to accomplishments.',
      wealth: 'Financial fortune is improving, and it is suitable to seek steady progress.',
      advice: 'Avoid rushing to find shortcuts and refrain from impulsive actions. Adhere to principles and follow a step-by-step approach, just like observing proper etiquette, to achieve success in all endeavors.',
    },
    {
      id: 54,
      imgPath: '/I Ching Cards Hexagram54.jpeg',
      title: 'Hexagram 54: The Bride',
      subTitle: 'A SCENE OF A GIRL PURSUING A MAN; THE MEANING OF THE LOSS OF PROPER OPPORTUNITIES.',
      overview: 'Being tormented by various grievances, the heart is filled with unresolved issues, and there is an inability to calm the anger and sadness.',
      relationships: 'It is a period of bitter longing, where sacrifices and injuries are endured by oneself.',
      career: 'The scope of work and position do not align, inevitably leading to feelings of resentment.',
      wealth: 'Beware of compromising principles for the sake of wealth.',
      advice: 'In every situation, it is important to adhere to moral principles and conform to social norms. Before taking action, we should first understand the objective environment and emotions around us and proceed with caution to avoid the consequences of acting solely on one\'s own wishes and desires.',
    },
    {
      id: 55,
      imgPath: '/I Ching Cards Hexagram55.jpeg',
      title: 'Hexagram 55: Abundance',
      subTitle: 'A SCENE OF ABUNDANCE AND GRANDEUR; THE MEANING OF PROSPERITY AND SUCCESS.',
      overview: 'There is great gain and prosperous harvest, indicating smooth and prosperous times.',
      relationships: 'Being passionately in love, causing envy in others.',
      career: 'Being in a commanding position, reaching the peak of success.',
      wealth: 'Financial fortune is abundant.',
      advice: 'The moment of abundance is exhilarating and enviable. While making good use of others, we need to continuously strive and maintain humility in order to sustain the state of success and abundance. However, throughout history, there has always been a law that extreme conditions bring about opposite effects. Therefore, we must remain vigilant and adhere to the path of moderation. It is important to enjoy happiness while exercising restraint. If we become arrogant, greedy, or even betray our principles, sowing the seeds of various negative causes, we will face self-destruction when our good fortune runs out.',
    },
    {
      id: 56,
      imgPath: '/I Ching Cards Hexagram56.jpeg',
      title: 'Hexagram 56: The Wanderer',
      subTitle: 'A SCENE OF BEING WITHOUT A FIXED ABODE; THE DESIRE TO WANDER IN SEARCH OF STABILITY.',
      overview: 'There is a sense of restlessness and frequent setbacks, with an uncertain future.',
      relationships: 'It may require enduring hardships and should be cherished.',
      career: 'There is instability, and a challenging path lies ahead.',
      wealth: 'It is not favourable, so it is important to prioritize preserving wealth.',
      advice: 'This represents an unstable situation where external conditions and circumstances are unfavourable. Small matters may go smoothly, but major ones may bring misfortune. The path to good fortune lies in being patient and observing more rather than taking action.',
    },
    {
      id: 57,
      imgPath: '/I Ching Cards Hexagram57.jpeg',
      title: 'Hexagram 57: Penetrating',
      subTitle: 'A SCENE OF DRIFTING WITH THE WIND; THE MEANING OF CONSTANT CHANGE.',
      overview: 'Success can be achieved by following the flow and adapting to the circumstances. As the tides of fortune change, we should approach with caution.',
      relationships: 'The emotional situation may be unpredictable. It is important to pay attention to details and express affection.',
      career: 'It is advisable to go with the flow and seek guidance from reliable sources.',
      wealth: 'It can change in an instant, so it is prudent to be conservative.',
      advice: 'Approach everything with a flexible attitude, gradually moving forward, as it increases the chances of success. Adapt to external changes while maintaining inner stability. Utilize mature and tactful strategies, avoiding losing oneself and always remember to maintain a sense of direction to avoid being exploited by others.',
    },
    {
      id: 58,
      imgPath: '/I Ching Cards Hexagram58.jpeg',
      title: 'Hexagram 58: The Joyous',
      subTitle: 'A SCENE OF ENJOYING WITH OTHERS; THE MEANING OF BOTH PRAISE AND CRITICISM.',
      overview: 'Having good interpersonal relationships and being able to motivate others with joy will lead to success in all endeavours.',
      relationships: 'The relationship is harmonious.',
      career: 'Popularity is high, particularly suitable for the entertainment industry.',
      wealth: 'It is good, and one\'s mood is positive.',
      advice: 'Joy is a powerful force that drives people forward, and if utilized properly, individuals may even risk their lives for it. However, it is important to avoid flattery and insincere words. Getting carried away by joy can easily lead to inappropriate remarks and potential trouble.',
    },
    {
      id: 59,
      imgPath: '/I Ching Cards Hexagram59.jpeg',
      title: 'Hexagram 59: Dispersion',
      subTitle: 'A SCENE OF WIND BLOWING OVER WATER; THE MEANING OF A STATE OF INSTABILITY AND DISPERSAL.',
      overview: 'In terms of current success, one should be cautious as luck may be turning weak.',
      relationships: 'Lovers, who were once passionately in love, may suddenly become distant.',
      career: 'Unexpected changes lead to a loss of strength. Progress may be slow, and success may not be achieved in the short term as everyone lacks enthusiasm.',
      wealth: 'Financial fortune experiences fluctuations and variations, requiring careful management and rational decision-making.',
      advice: 'Challenges can be overcome and resolved. However, achieving success in various aspects is relatively difficult, primarily due to people\'s easily scattered thoughts. It is important to address this issue with targeted measures, prioritizing overall interests, temporarily suppressing emotions, and wholeheartedly fulfilling responsibilities and commitments. Avoiding a negative attitude is crucial. By doing so, some achievements can be attained.',
    },
    {
      id: 60,
      imgPath: '/I Ching Cards Hexagram60.jpeg',
      title: 'Hexagram 60: Limitation',
      subTitle: 'A SCENE OF THE FORMATION OF A POND THROUGH WATER STORAGE; THE IMPORTANCE OF SELF-DISCIPLINE AND SELF-EXAMINATION.',
      overview: 'Appropriate moderation leads to minor success. Without moderation, it will result in misfortune. It is necessary to adhere to boundaries and regulations with careful attention and also harness the power of adjustment.',
      relationships: 'Timing and balance are crucial; avoid being too hasty or impulsive.',
      career: 'Starting from a lower position and steadily progressing is the path to success.',
      wealth: 'Practice prudence and manage expenses. Maintain an optimistic yet cautious approach, avoiding excessive greed.',
      advice: 'Insufficient moderation can lead to disorder and chaos, while excessive moderation can result in rigidity and stinginess, ultimately leading to poverty. Therefore, we should pay attention to achieving a balance and the middle way. In the long run, establishing systems and structures is the best approach to moderation.',
    },
    {
      id: 61,
      imgPath: '/I Ching Cards Hexagram61.jpeg',
      title: 'Hexagram 61: Inner Truth',
      subTitle: 'A SCENE OF SINCERITY TOWARDS OTHERS; THE VALUE OF KEEPING ONE\'S WORD.',
      overview: 'Those who prioritize honesty and integrity will experience smooth fortune and receive assistance from influential individuals.',
      relationships: 'A loyal partner built on mutual trust and reliance.',
      career: 'By engaging in genuine and sincere work, one will gradually enter a favorable and prosperous path.',
      wealth: 'Strong prospects for regular income, while speculative gains are unlikely.',
      advice: 'Embrace sincerity in your interactions with others and consistently foster a pleasant atmosphere and collaborative relationships. With this approach, success can be achieved in all endeavors. It is crucial to avoid betraying the trust others place in you, being dishonest, or displaying insincere intentions, as such behavior will inevitably lead to self-destruction in the long run.',
    },
    {
      id: 62,
      imgPath: '/I Ching Cards Hexagram62.jpeg',
      title: 'Hexagram 62: Excess',
      subTitle: 'A SCENE OF EXCESSIVE GREED LEADS TO LOSS; THE MEANING OF OVERCOMPENSATION FOR PAST MISTAKES.',
      overview: 'Pay attention to interpersonal relationships, choose words and actions carefully, and avoid offending others. It is advisable to retreat and use a passive approach to handle situations.',
      relationships: 'Give both parties time and space and discuss matters calmly after cooling down.',
      career: 'It is beneficial to take a step back in all matters, as pushing forward or climbing higher may bring negative outcomes. Harmony should be valued in all endeavors.',
      wealth: 'Be cautious of financial losses due to strained relationships.',
      advice: 'When mistakes are identified, it is important to correct them promptly and return to the right path for success in all matters. During the process of correction, it is advisable to remind others in a gentle and tactful manner, while carefully observing their reactions and being mindful of their feelings. By timely minimizing the significance of major issues and treating minor issues as insignificant, one can avoid negative consequences that may lead to disharmony.',
    },
    {
      id: 63,
      imgPath: '/I Ching Cards Hexagram63.jpeg',
      title: 'Hexagram 63: After Completion',
      subTitle: 'A SCENE OF HARMONY BETWEEN YIN AND YANG; THE CONNECTION BETWEEN UPPER AND LOWER.',
      overview: 'Favorable alignment of timing, favorable conditions, and harmonious relationships lead to success in all endeavors.',
      relationships: 'A solid foundation of emotions and a perfect match between partners.',
      career: 'The time-space environment is in alignment, important personnel and strategies are well coordinated, and they resonate with the people. This leads to the success of the plan, achieving desired outcomes, and accomplishing great achievements.',
      wealth: 'Prosperity in financial matters.',
      advice: 'One should not be complacent and only focus on the present state of perfection, as troubles may arise if one lacks foresight.',
    },
    {
      id: 64,
      imgPath: '/I Ching Cards Hexagram64.jpeg',
      title: 'Hexagram 64: Before Completion',
      subTitle: 'A SCENE OF IMBALANCE BETWEEN YIN AND YANG; THE MEANING OF LACK OF COMMUNICATION BETWEEN UPPER AND LOWER.',
      overview: 'The desired outcome is difficult to achieve at the moment; there is a significant gap between the current situation and success.',
      relationships: 'Whether it\'s unrequited love or a painful love, it\'s not suitable. Everyone is going in opposite directions.',
      career: 'Success has not been achieved yet, so continuous effort is still required.',
      wealth: 'There is no significant financial luck; be cautious about financial loss.',
      advice: 'When encountering disagreements, it is important not to stubbornly cling to one\'s own opinions. Instead, it is crucial to embrace each other, seek common ground in differences, and communicate without barriers in order to achieve goals.',
    },
  ],
};
