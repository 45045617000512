import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useParams } from 'react-router-dom';
import { useCart } from '../../../contexts/CartContext';
import { flyToCart } from '../../shared/utils';
import Button from '../../../shared/Button';
import IchingOracleCardsInfo from '../../../categories/IchingOracleCards';
import { useTranslation } from '../../../contexts/TranslationContext';
import './ProductDetails.css';

const IMG_CAROUSEL_SETTINGS = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true
};

const ProductDetails = ({ triggerHeader }) => {
  const { translate, getConstants } = useTranslation();
  const productData = getConstants('productData');
  const { productId } = useParams();
  const detailsRef = useRef(null);
  const { addToCart } = useCart();
  const [flyToCartStyles, setFlyToCartStyles] = useState({});
  const [showFlyAnimation, setShowFlyAnimation] = useState(false);

  const product = productData.find(
    (product) => product.id === parseInt(productId));
  const { name, price, description, imgPath, category } = product;

  const handleAddToCart = () => {
    const quantity = document.querySelector('#quantity').value;
    const sanitizedQuantity = parseInt(quantity);
    if (isNaN(sanitizedQuantity) || sanitizedQuantity < 1) return;
    addToCart(product, sanitizedQuantity);
    flyToCart(
      detailsRef.current,
      setFlyToCartStyles,
      setShowFlyAnimation,
      triggerHeader
    );
  };

  return (
    <div className="product-details-container">
      <div className="product-header">
        <div className="product-carousel" ref={detailsRef}>
          {imgPath && imgPath.length > 1 ? (
            <Slider {...IMG_CAROUSEL_SETTINGS}>
              {imgPath.map((path, index) => (
                <div key={index}>
                  <img
                    className="carousel-image"
                    src={process.env.PUBLIC_URL + path}
                    alt={name + ' image ' + index}
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <img
              className="product-image"
              src={imgPath[0]}
              alt={name}
            />
          )}
        </div>
        <div className="product-info">
          <h1>{name}</h1>
          <p>${price}</p>
          <div className="product-purchase">
            <div className="product-quantity">
              <label htmlFor="quantity">{translate('productDetailsPage.productQuantity.label')}</label>
              <input id="quantity" type="number" min="1" defaultValue="1" />
            </div>
            <Button className="add-to-cart-btn" onClick={handleAddToCart}>
              {translate('productDetailsPage.addToCartBtn')}
            </Button>
            {showFlyAnimation && (
              <img
                src={process.env.PUBLIC_URL + imgPath[0]}
                alt="Fly to cart"
                className="fly-to-cart"
                style={flyToCartStyles}
              />
            )}
          </div>
        </div>
      </div>

      <div className="product-description">
        <div className="product-tabs">
          <div className="tab product-info-tab">
            <h3>{translate('productDetailsPage.productInfo.header')}</h3>
            {description}
          </div>
          <div className="tab">
            <h3>{translate('productDetailsPage.shippingInfo.header')}</h3>
            <p>{translate('productDetailsPage.shippingInfo.content')}</p>
          </div>
        </div>
        {category && category === 'I Ching Oracle Cards' && <IchingOracleCardsInfo />}
      </div>
    </div>
  );
};

export default ProductDetails;
