export const getServicesEmails = (params) => {
  const emailSubject = `${params.name}的服務預約 Has New Service Appointment Request`;
  const emailContent = `<p>您好 易靈科學研究所：</p>
    <p>&nbsp;</p>
    <p>您收到了一個來自 ${params.name} 的<strong>${params.zhType}</strong>服務預約請求。以下是有關此次服務預約的詳細信息。請盡快聯繫客戶確認服務預約的日期和時間。&nbsp;</p>
    <ul>
      <li style="font-weight: bold;"><strong>客戶姓名：${params.name}</strong></li>
      <li style="font-weight: bold;"><strong>電子郵件：${params.email}</strong></li>
      <li style="font-weight: bold;"><strong>電話：${params.phone}</strong></li>
      <li style="font-weight: bold;"><strong>服務類型：${params.zhType}</strong></li>
    </ul>
    <p>給您的留言：</p>
    <blockquote>
    <p><strong>${params.message}</strong></p>
    </blockquote>
    <p>&nbsp;</p>
    <p>祝好，<br>EmailJS團隊</p>
    <p>&nbsp;</p>
    <hr />
    <p>&nbsp;</p>
    <p>Hello SpiritualScience Institute,</p>
    <p>&nbsp;</p>
    <p>You got a new <strong>${params.enType}</strong> Service Appointment Request from ${params.name}. Below is the information regarding this service appointment. Please reach out to the client to confirm dates and times of the service appointment as soon as possible.&nbsp;</p>
    <ul>
      <li style="font-weight: bold;"><strong>Client name: ${params.name}</strong></li>
      <li style="font-weight: bold;"><strong>Email: ${params.email}</strong></li>
      <li style="font-weight: bold;"><strong>Phone: ${params.phone}</strong></li>
      <li style="font-weight: bold;"><strong>Service type: ${params.enType}</strong></li>
    </ul>
    <p>Message for you:</p>
    <blockquote>
    <p><strong>${params.message}</strong></p>
    </blockquote>
    <p>&nbsp;</p>
    <p>Best wishes,<br>EmailJS team</p>
    <p>&nbsp;</p>
  `;

  const emailAutoReplySubject = `已收到您的服務預約 Your Service Appointment Request has been received`;
  const emailAutoReplyContent = `<p>您好 ${params.name}：</p>
    <p>&nbsp;</p>
    <p>您的<strong>${params.zhType}</strong>服務預約請求已成功發送。我們將儘快聯繫您確認服務預約的日期和時間。如有任何問題，請隨時聯繫我們。感謝您！</p>
    <p>您的聯絡資料：</p>
    <ul>
      <li style="font-weight: bold;"><strong>姓名：${params.name}</strong></li>
      <li style="font-weight: bold;"><strong>電子郵件：${params.email}</strong></li>
      <li style="font-weight: bold;"><strong>電話：${params.phone}</strong></li>
      <li style="font-weight: bold;"><strong>服務類型：${params.zhType}</strong></li>
    </ul>
    <p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;"><strong>留言:</strong><br><strong>${params.message}</strong></p>
    <p>&nbsp;</p>
    <p>祝好，<br>易靈科學研究所</p>
    <p><span style="font-size: 10pt;">電子郵件：<a href="mailto:cs@ichingspiritual.com">cs@ichingspiritual.com</a></span></p>
    <p>&nbsp;</p>
    <hr />
    <p>&nbsp;</p>
    <p>Hello ${params.name},</p>
    <p>&nbsp;</p>
    <p>Your <strong>${params.enType}</strong> service appointment request has been successfully sent. We will contact you to confirm service appointment dates and times shortly. In the meantime, please feel free to reach out to us for any questions. Thank you!</p>
    <p>Your contact Information:</p>
    <ul>
      <li style="font-weight: bold;"><strong>Name: ${params.name}</strong></li>
      <li style="font-weight: bold;"><strong>Email: ${params.email}</strong></li>
      <li style="font-weight: bold;"><strong>Phone: ${params.phone}</strong></li>
      <li style="font-weight: bold;"><strong>Service type: ${params.enType}</strong></li>
    </ul>
    <p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;"><strong>Message:</strong><br><strong>${params.message}</strong></p>
    <p>&nbsp;</p>
    <p>Best wishes,<br>SpiritualScience Institute</p>
    <p><span style={{ fontSize: '10pt' }}>Email: <a href="mailto:cs@ichingspiritual.com">cs@ichingspiritual.com</a></span></p>
    <p>&nbsp;</p>
  `;

  return {
    info: {
      subject: emailSubject,
      content: emailContent,
    },
    autoReplyInfo: {
      subject: emailAutoReplySubject,
      content: emailAutoReplyContent,
    },
  };
};
