import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import { useCart } from '../../../contexts/CartContext';
import { flyToCart } from '../utils';
import { displayNumWithCommas } from '../../../utils';
import Button from '../../../shared/Button';
import { useTranslation } from '../../../contexts/TranslationContext';
import './ProductQuickView.css';

const IMG_CAROUSEL_SETTINGS = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
};

const ProductQuickView = ({ product, triggerHeader, toggleQuickView }) => {
  const { translate } = useTranslation();
  const { addToCart } = useCart();
  const [flyToCartStyles, setFlyToCartStyles] = useState({});
  const [showFlyAnimation, setShowFlyAnimation] = useState(false);
  const quickViewRef = useRef(null);
  const { id, name, price, imgPath, ribbon } = product;

  const onFlyToCartEnd = () => {
    toggleQuickView();
    triggerHeader();
  };

  const handleAddToCart = () => {
    const quantity = document.querySelector('.quantity-input').value;
    const sanitizedQuantity = parseInt(quantity);
    if (isNaN(sanitizedQuantity) || sanitizedQuantity < 1) return;
    addToCart(product, sanitizedQuantity);
    flyToCart(
      quickViewRef.current,
      setFlyToCartStyles,
      setShowFlyAnimation,
      onFlyToCartEnd
    );
  };

  return (
    <div ref={quickViewRef} className="product-quick-view">
        <div className='quick-view-img-container'>
          <div className="quick-view-product-carousel">
            {imgPath && imgPath.length > 1 ? (
              <Slider {...IMG_CAROUSEL_SETTINGS}>
                {imgPath.map((path, idx) => (
                  <div key={idx}>
                    <img
                      src={process.env.PUBLIC_URL + path}
                      alt={`${name} + ' image' + ${idx}`}
                      className="carousel-image"
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              <img src={process.env.PUBLIC_URL + imgPath[0]} alt={name} className="carousel-image" />
            )}
          </div>
          {ribbon && <div className="quick-view-ribbon">{ribbon}</div>}
        </div>
        <div className="quick-view-texts">
          <h2 className="quick-view-header">{name}</h2>
          <p className="quick-view-price">${displayNumWithCommas(price)}</p>
          <div className="quantity-container">
            <label>{translate('shared.quickViewModal.quantityLabel')}:</label>
            <input type="number" className="quantity-input" defaultValue={1} min={1} />
          </div>
          <div className="quick-view-buttons">
            <Button className="add-to-cart-button" onClick={handleAddToCart}>{translate('shared.quickViewModal.addToCartButton')}</Button>
            <a href={`/product/${id}`} className="view-details-link">{translate('shared.quickViewModal.viewDetailsLink')}</a>
          </div>
        </div>
        {showFlyAnimation && (
          <img
            src={process.env.PUBLIC_URL + imgPath[0]}
            alt="Fly to cart"
            className="fly-to-cart"
            style={flyToCartStyles}
          />
        )}
    </div>
  );
};

export default ProductQuickView;
