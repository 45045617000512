import React from 'react';
import ClassCard from '../../shared/ClassCard/ClassCard';
import './ClassesPage.css';
import { useTranslation } from '../../contexts/TranslationContext';

const ClassesPage = () => {
  const { translate, getConstants } = useTranslation();
  const classesData = getConstants('classesData');

  return (
    <div className="classes-page">
      <div className='classes-page-header'>
        <h1>{translate('classesPage.header.title')}</h1>
        <p>{translate('classesPage.header.subtitle')}</p>
      </div>
      <div className="classes-page-list">
        {classesData.map((classItem, index) => (
          <ClassCard key={index} classData={classItem} isDisplayOnHover={false} />
        ))}
      </div>
    </div>
  );
};

export default ClassesPage;
