import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../shared/Button';
import { displayNumWithCommas } from '../../utils';
import {
  MAX_HOME_SERVICES_INITIAL_DESCRIPTION,
  MAX_SERVICES_PAGE_INITIAL_DESCRIPTION,
} from '../../../constants/constants';
import { useTranslation } from '../../contexts/TranslationContext';
import './Services.css';

const Service = ({ service }) => {
  const { translate } = useTranslation();
  const {
    id,
    name,
    price,
    priceUnit,
    priceNotes,
    imgPath,
    description,
    descriptionDetails,
    duration,
  } = service;

  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  const expandReadMore = (e) => {
    e.stopPropagation();
    setIsExpanded(true);
  };

  const onServiceCardClick = () => {
    navigate(`/service/${id}`);
  };

  const handleBookNow = (e) => {
    e.stopPropagation();
    const serviceData = { name: name, id: id };
    navigate('/services/book-now', { state: serviceData });
  };

  const displayDescription = () => {
    if (!isExpanded) {
      if (description.length <= MAX_HOME_SERVICES_INITIAL_DESCRIPTION) {
        return description;
      }
      return `${description.substring(0, MAX_HOME_SERVICES_INITIAL_DESCRIPTION)}...`;
    } else {
      if (description.length > MAX_SERVICES_PAGE_INITIAL_DESCRIPTION) {
        return `${description.substring(0, MAX_SERVICES_PAGE_INITIAL_DESCRIPTION)}...`;
      }
      return description;
    }
  };

  return (
    <div className="service" onClick={onServiceCardClick}>
      <img src={process.env.PUBLIC_URL + imgPath[0]} alt={"Service " + id} />
      <div className="service-content">
        <h3>{name}</h3>
        <h5>{displayDescription()}</h5>
        {description.length > MAX_HOME_SERVICES_INITIAL_DESCRIPTION && !isExpanded &&
        <button className="read-more-btn" onClick={expandReadMore}>{translate('home.services.readMore.button')}</button>}
        {descriptionDetails && <h5>{descriptionDetails}</h5>}
        <hr className='service-divider' />
        {duration && <p>{duration}</p>}
        <p>${displayNumWithCommas(price)} {priceUnit ? priceUnit : ''} {priceNotes ? `; ${priceNotes}` : ''}</p>
        <Button
          className="service-button"
          size="small"
          onClick={handleBookNow}
        >
          {translate('home.services.bookNow.button')}
        </Button>
      </div>
    </div>
  );
};

const Services = () => {
  const { translate, getConstants } = useTranslation();
  const servicesData = getConstants('servicesData');

  return (
    <section id="services" className="services">
      <h2>{translate('home.services.title')}</h2>
      <div className="service-list">
        {servicesData.map(service => (
          <Service key={service.id} service={service} />
        ))}
      </div>
    </section>
  );
};

export default Services;
