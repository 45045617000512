import React from 'react';
import ClassCard from '../../shared/ClassCard/ClassCard';
import { useTranslation } from '../../contexts/TranslationContext';
import './Classes.css';

const Classes = () => {
  const { translate, getConstants } = useTranslation();
  const classesData = getConstants('classesData');

  return (
    <section className="classes">
      <div className="classes-header">
        <h2>{translate('home.classes.title')}</h2>
        <p>{translate('home.classes.subtitle')}</p>
      </div>
      <div className="classes-container">
        <div className="classes-grid-container">
          {classesData.map(classItem => (
            <ClassCard key={classItem.id} classData={classItem} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Classes;
