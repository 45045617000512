import React from 'react';
import ReactPlayer from 'react-player';
import { useTranslation } from '../../contexts/TranslationContext';
import './SingingBowls.css';

const SingingBowls = () => {
  const { translate } = useTranslation();

  return (
    <div className="singing-bowls-section">
      <h2 className="singing-bowls-header">
        {translate('products.category.singingBowls.header')}
      </h2>
      <div className="react-player">
        <ReactPlayer
          url={process.env.PUBLIC_URL + '/singing_bowls_service.mp4'}
          controls={true}
          width='50%'
          height='100%'
        />
      </div>
    </div>
  );
};

export default SingingBowls;