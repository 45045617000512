import React from 'react';
import ReactPlayer from 'react-player';
import YouTube from 'react-youtube';
import { useTranslation } from '../../contexts/TranslationContext';
import './IchingCardsInstructionsPage.css';

const IchingCardsInstructionsAboutSection = () => {
  const { translate } = useTranslation();

  return (
    <div className="iching-cards-instructions-page-section">
      <div className="iching-cards-instructions-page-text-column">
        <h3>{translate('products.iChingCardsInstructions.about.title').toUpperCase()}</h3>
        <p>{translate('products.iChingCardsInstructions.about.content.p1')}</p>
        <p>{translate('products.iChingCardsInstructions.about.content.p2')}</p>
        <p>{translate('products.iChingCardsInstructions.about.content.p3')}</p>
        <p>{translate('products.iChingCardsInstructions.about.content.p4')}</p>
      </div>
      <div className="iching-cards-instructions-page-video-column react-player">
        <ReactPlayer
          url={process.env.PUBLIC_URL + '/I Ching 1 mins video.MP4'}
          controls={true}
          width='100%'
          height='60%'
        />
      </div>
    </div>
  );
};

const IchingCardsInstructionsHowToSection = () => {
  const options = {
    height: '60%',
    width: '100%',
    playerVars: {
      autoplay: 1,
      controls: 1,
    },
  };

  const _onReady = (event) => {
    event.target.pauseVideo();
  };

  const { translate } = useTranslation();

  return (
    <div className='iching-cards-instructions-page-section'>
      <div className="iching-cards-instructions-page-video-column">
        <div className="youtube-player-container">
          <YouTube videoId="F8LjnvWI0vI" options={options} onReady={_onReady}/>
        </div>
        <div className="youtube-player-container">
          <YouTube videoId="AmU1eq20SgY" options={options} onReady={_onReady}/>
        </div>
      </div>
      <div className="iching-cards-instructions-page-text-column">
        <h3>{translate('products.iChingCardsInstructions.instructions.title').toUpperCase()}</h3>
        <p>{translate('products.iChingCardsInstructions.instructions.content.p1')}</p>
        <p>{translate('products.iChingCardsInstructions.instructions.content.p2')}</p>
        <h3>{translate('products.iChingCardsInstructions.steps.title').toUpperCase()}</h3>
        <ol>
          <li>{translate('products.iChingCardsInstructions.steps.1')}</li>
          <li>{translate('products.iChingCardsInstructions.steps.2')}</li>
          <li>{translate('products.iChingCardsInstructions.steps.3')}</li>
          <li>{translate('products.iChingCardsInstructions.steps.4')}</li>
          <li>{translate('products.iChingCardsInstructions.steps.5')}</li>
        </ol>
        <p>{translate('products.iChingCardsInstructions.steps.p1')}</p>
      </div>
    </div>
  );
};

const IchingCardsInstructionsCardSpreadSection = () => {
  const { translate } = useTranslation();

  return (
    <div className="iching-card-spread-container">
      <h3>{translate('products.iChingCardsInstructions.cardSpread.title').toUpperCase()}</h3>
      <div className="iching-card-spread-grid-container">
        <div className="iching-card-spread-grid">
          <h4>{translate('products.iChingCardsInstructions.cardSpread.oneCardDivination.title')}</h4>
          <ul><li>{translate('products.iChingCardsInstructions.cardSpread.oneCardDivination.content')}</li></ul>
          <span className="iching-cards-instructions-page-card">
            <img src={process.env.PUBLIC_URL + '/Iching card 1.svg'} alt="Card 1" />
          </span>
        </div>
        <div className="iching-card-spread-grid">
          <h4>{translate('products.iChingCardsInstructions.cardSpread.twoCardDivination.title')}</h4>
          <ul><li>{translate('products.iChingCardsInstructions.cardSpread.twoCardDivination.content.header')}</li></ul>
          <p>{translate('products.iChingCardsInstructions.cardSpread.twoCardDivination.content.p1')}</p>
          <p>{translate('products.iChingCardsInstructions.cardSpread.twoCardDivination.content.p2')}</p>
          <div className="iching-card-spread-cards-container">
            <span className="iching-cards-instructions-page-card">
              <img src={process.env.PUBLIC_URL + '/Iching card A.svg'} alt="Card A" />
            </span>
            <span className="iching-cards-instructions-page-card">
              <img src={process.env.PUBLIC_URL + '/Iching card B.svg'} alt="Card B" />
            </span>
          </div>
        </div>
        <div className="iching-card-spread-grid">
          <h4>{translate('products.iChingCardsInstructions.cardSpread.threeCardDivination.title')}</h4>
          <ul>
            <li>{translate('products.iChingCardsInstructions.cardSpread.threeCardDivination.content.li1')}</li>
            <li>{translate('products.iChingCardsInstructions.cardSpread.threeCardDivination.content.li2')}</li>
          </ul>
          <p>{translate('products.iChingCardsInstructions.cardSpread.threeCardDivination.content.p1')}</p>
          <p>{translate('products.iChingCardsInstructions.cardSpread.threeCardDivination.content.p2')}</p>
          <p>{translate('products.iChingCardsInstructions.cardSpread.threeCardDivination.content.p3')}</p>
          <div className="iching-card-spread-cards-container grid-flex">
            <span className="iching-cards-instructions-page-card vertical-writing">
              <img src={process.env.PUBLIC_URL + '/Iching card Past.svg'} alt="Card Past" />
            </span>
            <span className="iching-cards-instructions-page-card vertical-writing">
              <img src={process.env.PUBLIC_URL + '/Iching card Present.svg'} alt="Card Present" />
            </span>
            <span className="iching-cards-instructions-page-card vertical-writing">
              <img src={process.env.PUBLIC_URL + '/Iching card Future.svg'} alt="Card Future" />
            </span>
            <span className="iching-cards-instructions-page-card vertical-writing">
              <img src={process.env.PUBLIC_URL + '/Iching card Problem.svg'} alt="Card Problem" />
            </span>
            <span className="iching-cards-instructions-page-card vertical-writing">
              <img src={process.env.PUBLIC_URL + '/Iching card Why.svg'} alt="Card Why" />
            </span>
            <span className="iching-cards-instructions-page-card vertical-writing">
              <img src={process.env.PUBLIC_URL + '/Iching card Result.svg'} alt="Card Result" />
            </span>
          </div>
        </div>
      </div>
      <div className="iching-card-spread-section-container">
        <h4>{translate('products.iChingCardsInstructions.cardSpread.fengShuiDivination.title')}</h4>
        <ul>
          <li>{translate('products.iChingCardsInstructions.cardSpread.fengShuiDivination.content.li1')}</li>
          <li>{translate('products.iChingCardsInstructions.cardSpread.fengShuiDivination.content.li2')}</li>
          <li>{translate('products.iChingCardsInstructions.cardSpread.fengShuiDivination.content.li3')}</li>
          <li>{translate('products.iChingCardsInstructions.cardSpread.fengShuiDivination.content.li4')}</li>
        </ul>
        <div className="iching-card-spread-grid-container">
          <div className="iching-card-spread-grid">
            <div className="iching-card-spread-cards-container grid-flex fengshui-method-cards-container">
              <span className="iching-cards-instructions-page-card vertical-writing">
                <img src={process.env.PUBLIC_URL + '/Iching card SE.svg'} alt="Card SE" />
              </span>
              <span className="iching-cards-instructions-page-card vertical-writing">
                <img src={process.env.PUBLIC_URL + '/Iching card S.svg'} alt="Card S" />
              </span>
              <span className="iching-cards-instructions-page-card vertical-writing">
                <img src={process.env.PUBLIC_URL + '/Iching card SW.svg'} alt="Card SW" />
              </span>
              <span className="iching-cards-instructions-page-card vertical-writing">
                <img src={process.env.PUBLIC_URL + '/Iching card E.svg'} alt="Card E" />
              </span>
              <span className="iching-cards-instructions-page-card vertical-writing">
                <img src={process.env.PUBLIC_URL + '/Iching card Middle.svg'} alt="Card Middle" />
              </span>
              <span className="iching-cards-instructions-page-card vertical-writing">
                <img src={process.env.PUBLIC_URL + '/Iching card W.svg'} alt="Card W" />
              </span>
              <span className="iching-cards-instructions-page-card vertical-writing">
                <img src={process.env.PUBLIC_URL + '/Iching card NE.svg'} alt="Card NE" />
              </span>
              <span className="iching-cards-instructions-page-card vertical-writing">
                <img src={process.env.PUBLIC_URL + '/Iching card N.svg'} alt="Card N" />
              </span>
              <span className="iching-cards-instructions-page-card vertical-writing">
                <img src={process.env.PUBLIC_URL + '/Iching card NW.svg'} alt="Card NW" />
              </span>
            </div>
          </div>
          <div className="iching-card-spread-grid">
            <img src={process.env.PUBLIC_URL + '/home_fengshui.png'} alt="Home Feng Shui with Energy Lamps" />
          </div>
          <div className="iching-card-spread-grid">
            <img src={process.env.PUBLIC_URL + '/Iching Cards Inside.jpeg'} alt="Iching Cards Example" />
          </div>
        </div>
      </div>
      <div className="iching-card-spread-section-container">
        <h4>{translate('products.iChingCardsInstructions.cardSpread.astrologyDivination.title')}</h4>
        <ul>
          <li>{translate('products.iChingCardsInstructions.cardSpread.astrologyDivination.content.li1')}</li>
          <li>
            {translate('products.iChingCardsInstructions.cardSpread.astrologyDivination.content.li2.main')}
            <ul>
              <li>{translate('products.iChingCardsInstructions.cardSpread.astrologyDivination.content.li2.life')}</li>
              <li>{translate('products.iChingCardsInstructions.cardSpread.astrologyDivination.content.li2.parents')}</li>
              <li>{translate('products.iChingCardsInstructions.cardSpread.astrologyDivination.content.li2.psychic')}</li>
              <li>{translate('products.iChingCardsInstructions.cardSpread.astrologyDivination.content.li2.residence')}</li>
              <li>{translate('products.iChingCardsInstructions.cardSpread.astrologyDivination.content.li2.career')}</li>
              <li>{translate('products.iChingCardsInstructions.cardSpread.astrologyDivination.content.li2.friends')}</li>
              <li>{translate('products.iChingCardsInstructions.cardSpread.astrologyDivination.content.li2.travel')}</li>
              <li>{translate('products.iChingCardsInstructions.cardSpread.astrologyDivination.content.li2.health')}</li>
              <li>{translate('products.iChingCardsInstructions.cardSpread.astrologyDivination.content.li2.wealth')}</li>
              <li>{translate('products.iChingCardsInstructions.cardSpread.astrologyDivination.content.li2.children')}</li>
              <li>{translate('products.iChingCardsInstructions.cardSpread.astrologyDivination.content.li2.spouse')}</li>
              <li>{translate('products.iChingCardsInstructions.cardSpread.astrologyDivination.content.li2.siblings')}</li>
            </ul>
          </li>
          <li>{translate('products.iChingCardsInstructions.cardSpread.astrologyDivination.content.li3')}</li>
          <li>{translate('products.iChingCardsInstructions.cardSpread.astrologyDivination.content.li4')}</li>
          <li>{translate('products.iChingCardsInstructions.cardSpread.astrologyDivination.content.li5')}</li>
        </ul>
        <div className="iching-card-spread-grid-container">
          <div className="iching-card-spread-grid">
            <img src={process.env.PUBLIC_URL + '/Iching card cloth.jpeg'} alt="Astrology Divination Image" />
          </div>
        </div>
      </div>
    </div>
  );
};

const IchingCardsInstructionsPage = () => {
  const { translate } = useTranslation();

  return (
    <div className="iching-cards-instructions-page">
      <div className="iching-cards-instructions-page-header">
        <h1>{translate('products.iChingCardsInstructions.title')}</h1>
      </div>
      <IchingCardsInstructionsAboutSection />
      <IchingCardsInstructionsHowToSection />
      <IchingCardsInstructionsCardSpreadSection />
      <div className='iching-cards-instructions-page-section'>
        <h3 className='iching-cards-instructions-page-next-steps'>
          {translate('products.iChingCardsInstructions.nextStepsGuide.text')}
          <a href="/products/i-ching-cards-explanations">{translate('products.iChingCardsInstructions.nextStepsGuide.link')}</a>
        </h3>
      </div>
    </div>
  );
};

export default IchingCardsInstructionsPage;
