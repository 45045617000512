import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { ThemeProvider, createTheme } from "@mui/material";
import Button from '../shared/Button';
import { useTranslation } from '../contexts/TranslationContext';
import { getContactUsEmails } from '../../emailTemplates/contactUsEmails';
import './ContactUsPage.css';

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          // Standard variant TextField
          "& .MuiInput-root": {
            color: "#2e2e2e",
            fontFamily: "Lato, sans-serif",
            fontWeight: "bold",
            "&:before": {
              borderColor: "#2e2e2e",
              borderWidth: "2px",
            },
            "&:after": {
              borderColor: "#ED5B20",
              borderWidth: "2px",
            },
          },
          "& .MuiInputLabel-standard": {
            color: "#2e2e2e",
            fontFamily: "Lato, sans-serif",
            fontWeight: "100",
            "&.Mui-focused": {
              color: "#ED5B20",
            },
          },
        },
      },
    },
  },
});

const ContactUsPage = () => {
  const { translate } = useTranslation();
  const [successToasterOpen, setSuccessToasterOpen] = useState(false);
  const [errorToasterOpen, setErrorToasterOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');

  const handleSuccessToasterClose = () => {
    setSuccessToasterOpen(false);
  };

  const handleErrorToasterClose = () => {
    setErrorToasterOpen(false);
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const onPhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const onMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const params = {
      name,
      email,
      phone: phoneNumber,
      message,
    };

    const templateParams = {
      clientData: params,
      emails: getContactUsEmails(params),
    };

    emailjs
      .send('cs_private_email', 'cs_notification', templateParams, {
        publicKey: 'w08V_HWwnOiEdWyYD',
      })
      .then(
        (response) => {
          setSuccessToasterOpen(true);
        },
        (err) => {
          console.log('FAILED...', err);
          setErrorToasterOpen(true);
        },
      );
  };

  return (
    <div className="contact-us-page">
      <div className="contact-us-page-header">
        <h1>{translate('contactUsPage.header.title')}</h1>
        <p>{translate('contactUsPage.header.subtitle')}</p>
      </div>
      {successToasterOpen && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          onClose={handleSuccessToasterClose}
        >
          <Alert
            onClose={handleSuccessToasterClose}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {translate('contactUsPage.successToaster')}
          </Alert>
        </Snackbar>
      )}
      {errorToasterOpen && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          onClose={handleErrorToasterClose}
        >
          <Alert
            onClose={handleErrorToasterClose}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {translate('contactUsPage.errorToaster')}
          </Alert>
        </Snackbar>
      )}
      <div className="contact-us-page-content">
        <form className="contact-us-page-form">
          <div className="contact-us-page-info">
            <div>
              <WhatsAppIcon />
              <PhoneIcon />
              <h4> {translate('home.contactUs.phoneNumber')}</h4>
            </div>
            <div>
              <MailOutlineIcon />
              <h4> {translate('home.contactUs.email')}</h4>
            </div>
          </div>
          <div className="contact-us-page-fields-container">
            <ThemeProvider theme={theme}>
              <TextField
                required
                id="name-input"
                label={translate('contactUsPage.form.name')}
                type="text"
                variant="standard"
                color="secondary"
                className="contact-us-page-form-field"
                value={name}
                onChange={onNameChange}
              />
              <TextField
                required
                id="email-input"
                label={translate('contactUsPage.form.email')}
                type="email"
                variant="standard"
                color="secondary"
                className="contact-us-page-form-field"
                value={email}
                onChange={onEmailChange}
              />
              <TextField
                required
                id="phone-number-input"
                label={translate('contactUsPage.form.phoneNumber')}
                type="tel"
                variant="standard"
                color="secondary"
                helperText={translate('contactUsPage.form.phoneNumberHelperText')}
                className="contact-us-page-form-field"
                value={phoneNumber}
                onChange={onPhoneNumberChange}
              />
              <TextField
                required
                id="message-input"
                label={translate('contactUsPage.form.message')}
                type="multiline"
                variant="standard"
                color="secondary"
                className="contact-us-page-form-field"
                value={message}
                onChange={onMessageChange}
              />
            </ThemeProvider>
            <Button
              type="submit"
              onClick={onSubmit}
              className="contact-us-page-form-submit-button"
            >
              {translate('contactUsPage.form.submitButton')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactUsPage;
