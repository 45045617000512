import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from '../contexts/TranslationContext';
import CartIcon from './CartIcon';
import './Header.css';

const Header = ({ showBriefly }) => {
  const { translate } = useTranslation();
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showTemporarily, setShowTemporarily] = useState(false);

  const navigate = useNavigate();

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (!showTemporarily) {
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        // Scrolling down
        setIsScrollingDown(true);
      } else if (currentScrollY < lastScrollY) {
        // Scrolling up
        setIsScrollingDown(false);
      }
    }

    setLastScrollY(currentScrollY);
  };

  const showHeaderBriefly = () => {
    setShowTemporarily(true);
    setTimeout(() => setShowTemporarily(false), 1500); // Adjust duration as needed
  };

  useEffect(() => {
    if (showBriefly) showHeaderBriefly();
  }, [showBriefly]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [lastScrollY, showTemporarily]);


  // Check if the window is resized to mobile size
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const handleLogoImgClick = () => {
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (e) => {
    e.stopPropagation();
    if (e.target.className === 'menu-overlay') {
      toggleMenu(); // Close when clicking outside
    }
  };

  return (
    <>
      <header className={`header ${isScrollingDown ? 'header-hidden' : 'header-visible'} ${showTemporarily ? 'header-temporary' : ''}`}>
        <div className="logo">
          <img
            src={process.env.PUBLIC_URL + '/name_card_crop.png'}
            alt="SpiritualScience Institute logo"
            onClick={handleLogoImgClick}
          />
          <h2>{translate('company.title')}</h2>
        </div>
        <CartIcon />
        <div className="hamburger-menu" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        {isMenuOpen && isMobile && <div className="menu-overlay" onClick={handleClickOutside}></div>}
        <nav className={`navbar ${isMenuOpen ? 'navbar-open' : ''}`}>
          <ul>
            <li><Link to="/" onClick={toggleMenu}>{translate('header.nav.home')}</Link></li>
            <li className="nav-item-expandable">
              <Link to="/about" onClick={toggleMenu}>{translate('header.nav.about.label')}</Link>
              <div className="dropdown-content">
                <ul>
                  <li>
                    <Link to="/about" onClick={toggleMenu}>
                      {translate('header.nav.about.dropdown.ourMission')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/about/background-story" onClick={toggleMenu}>
                      {translate('header.nav.about.dropdown.backgroundAndStory')}
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item-expandable">
              <Link to="/products" onClick={toggleMenu}>{translate('header.nav.products.label')}</Link>
              <div className="dropdown-content">
                <ul>
                  <li>
                    <Link to="/products" onClick={toggleMenu}>
                      {translate('header.nav.products.dropdown.allProducts')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/i-ching-cards-instructions" onClick={toggleMenu}>
                      {translate('header.nav.products.dropdown.iChingCardsInstructions')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/i-ching-cards-explanations" onClick={toggleMenu}>
                      {translate('header.nav.products.dropdown.iChingCardsExplanations')}
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item-expandable">
              <Link to="/services" onClick={toggleMenu}>{translate('header.nav.services.label')}</Link>
              <div className="dropdown-content">
                <ul>
                  <li>
                    <Link to="/services" onClick={toggleMenu}>
                      {translate('header.nav.services.dropdown.allServices')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/more-info" onClick={toggleMenu}>
                      {translate('header.nav.services.dropdown.moreInfo')}
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item-expandable">
              <Link to="/classes" onClick={toggleMenu}>{translate('header.nav.classes.label')}</Link>
              <div className="dropdown-content">
                <ul>
                  <li>
                    <Link to="/classes" onClick={toggleMenu}>
                      {translate('header.nav.classes.dropdown.allClasses')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/classes/more-info" onClick={toggleMenu}>
                      {translate('header.nav.classes.dropdown.moreInfo')}
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li><Link to="/testimonials" onClick={toggleMenu}>{translate('header.nav.testimonials')}</Link></li>
            <li><Link to="/contact" onClick={toggleMenu}>{translate('header.nav.contact')}</Link></li>
          </ul>
          {isMenuOpen && isMobile && (<span className="hamburger-menu-close-button" onClick={toggleMenu}>×</span>)}
        </nav>
      </header>
    </>
  );
};

export default Header;
