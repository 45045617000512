import React, { useState } from 'react';
import { Routes, Route } from "react-router-dom";
import ScrollToTop from './ScrollToTop';
import { CartProvider } from './components/contexts/CartContext';
import { TranslationProvider } from './components/contexts/TranslationContext';
import LanguageSwitcher from './components/LanguageSwitcher';
import Header from './components/Header/Header';
import Home from './components/Home';
import Footer from './components/Footer/Footer';
import AboutUsPage from './components/about/AboutUsPage/AboutUsPage';
import ProductsPage from './components/products/ProductsPage';
import ProductDetails from './components/products/ProductsPage/ProductDetails';
import ServicesPage from './components/services/ServicesPage';
import ServiceDetails from './components/services/ServicesPage/ServiceDetails';
import ClassesPage from './components/classes/ClassesPage';
import ClassDetails from './components/classes/ClassesPage/ClassDetails';
import TestimonialsPage from './components/TestimonialsPage';
import ContactUsPage from './components/ContactUsPage';
import IchingCardsInstructionsPage from './components/products/IchingCardsInstructionsPage';
import IchingCardsExplanationPage from './components/products/IchingCardsExplanationPage';
import MoreServicesInfoPage from './components/services/MoreServicesInfoPage';
import MoreClassesInfoPage from './components/classes/MoreClassesInfoPage';
import ShoppingCartPage from './components/products/shopping/ShoppingCartPage';
import CheckoutPage from './components/products/shopping/CheckoutPage';
import OrderConfirmationPage from './components/products/shopping/OrderConfirmationPage';
import OrderCancellationPage from './components/products/shopping/OrderCancellationPage';
import ApptPage from './components/shared/ApptPage';
import ApptConfirmationPage from './components/shared/ApptConfirmationPage';
import ApptCancellationPage from './components/shared/ApptCancellationPage';
import BackgroundStoryPage from './components/about/BackgroundAndStory';
import './App.css';

function App() {
  const [showBriefly, setShowBriefly] = useState(false);

  const showHeaderBriefly = () => {
    setShowBriefly(true);
    setTimeout(() => setShowBriefly(false), 1500); // Adjust delay as needed
  };

  return (
    <div className="App">
      <TranslationProvider>
        <CartProvider>
          <Header showBriefly={showBriefly} />
          <LanguageSwitcher />
          <ScrollToTop />
          <Routes>
            <Route path='/' element={<Home triggerHeader={showHeaderBriefly} />} />
            <Route path='/about' element={<AboutUsPage />} />
            <Route path='/products' element={<ProductsPage triggerHeader={showHeaderBriefly} />} />
            <Route path='/product/:productId' element={<ProductDetails triggerHeader={showHeaderBriefly} />} />
            <Route path='/services' element={<ServicesPage />} />
            <Route path='/service/:serviceId' element={<ServiceDetails />} />
            <Route path='/classes' element={<ClassesPage />} />
            <Route path='/class/:classId' element={<ClassDetails />} />
            <Route path='/testimonials' element={<TestimonialsPage />} />
            <Route path='/contact' element={<ContactUsPage />} />
            <Route path='/products/i-ching-cards-instructions' element={<IchingCardsInstructionsPage />} />
            <Route path='/products/i-ching-cards-explanations' element={<IchingCardsExplanationPage />} />
            <Route path='/services/more-info' element={<MoreServicesInfoPage />} />
            <Route path='/classes/more-info' element={<MoreClassesInfoPage />} />
            <Route path='/cart' element={<ShoppingCartPage />} />
            <Route path='/checkout' element={<CheckoutPage />} />
            <Route path='/order-confirmation' element={<OrderConfirmationPage />} />
            <Route path='/order-cancellation' element={<OrderCancellationPage />} />
            <Route path='/services/book-now' element={<ApptPage apptType="service" />} />
            <Route path='/services/booking-confirmation' element={<ApptConfirmationPage apptType="service" />} />
            <Route path='/services/booking-cancellation' element={<ApptCancellationPage apptType="service" />} />
            <Route path='/classes/book-now' element={<ApptPage apptType="class" />} />
            <Route path='/classes/booking-confirmation' element={<ApptConfirmationPage apptType="class" />} />
            <Route path='/classes/booking-cancellation' element={<ApptCancellationPage apptType="class" />} />
            <Route path='/about/background-story' element={<BackgroundStoryPage />} />
          </Routes>
          <Footer />
        </CartProvider>
      </TranslationProvider>
    </div>
  );
}

export default App;
