import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import { useTranslation } from '../../contexts/TranslationContext';
import './ContactUs.css';

const ContactUs = () => {
  const { translate } = useTranslation();

  return (
    <section className="contact-us">
      <h2>{translate('home.contactUs.title')}</h2>
      <hr className='contact-us-divider' />
      <div className="contact-content">
        <div className="contact-details">
          <div>
            <WhatsAppIcon />
            <PhoneIcon />
            <p> {translate('home.contactUs.phoneNumber')}</p>
          </div>
          <div>
            <MailOutlineIcon />
            <p> {translate('home.contactUs.email')}</p>
          </div>
          <div>
            <LaptopMacIcon />
            <p> {translate('home.contactUs.website')}</p>
          </div>
        </div>
        <p>{translate('home.contactUs.location')}</p>
      </div>
    </section>
  );
};

export default ContactUs;
