import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from '../../contexts/TranslationContext';
import DetailsView from '../../shared/DetailsViewPage';
import SpiritualCommunication from '../../categories/SpiritualCommunication';

const ClassDetails = () => {
  const { translate, getConstants } = useTranslation();
  const classesData = getConstants('classesData');
  const { classId } = useParams();
  const classItem = classesData.find(
    (classItem) => classItem.id === parseInt(classId));

  const navigate = useNavigate();
  const onClassDetailsViewBtnClicked = (e) => {
    e.stopPropagation();
    const classData = { name: classItem.name, id: classItem.id };
    navigate('/classes/book-now', { state: classData });
  };

  return (
    <div className="class-details-view-container">
      <DetailsView
        data={classItem}
        detailsViewBtnText={translate('classDetailsPage.bookNow.button')}
        onDetailsViewBtnClicked={onClassDetailsViewBtnClicked}
      />
      {classItem.category && classItem.category === 'Spiritual Communication' &&
        <SpiritualCommunication />}
    </div>
  );
};

export default ClassDetails;
