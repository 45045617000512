import React from 'react';
import { useTranslation } from '../../contexts/TranslationContext';
import './BackgroundStoryPage.css';

const Brandstory = () => {
  const { translate } = useTranslation();

  return (
    <div className="brand-story-container">
      <div className="brand-story-content-container">
        <h3>{translate('about.backgroundAndStory.brandStory.title').toUpperCase()}</h3>
        <h4>{translate('about.backgroundAndStory.brandStory.content.p1')}</h4>
        <h4>{translate('about.backgroundAndStory.brandStory.content.p2')}</h4>
        <h4>{translate('about.backgroundAndStory.brandStory.content.p3')}</h4>
      </div>
    </div>
  );
};

const LogoBackgroundStory = () => {
  const { translate } = useTranslation();

  return (
    <div className='logo-background-story-container'>
      <div className="logo-background-story-img-container">
        <img src={process.env.PUBLIC_URL + '/name_card_crop.png'} alt="SpiritualScience Institute Logo" />
      </div>
      <div className="logo-background-story-content-container">
        <h3>{translate('about.backgroundAndStory.logoBackgroundStory.content.title').toUpperCase()}</h3>
        <p>{translate('about.backgroundAndStory.logoBackgroundStory.content.p1')}</p>
        <div className="logo-background-story-text-section">
          <h4>{translate('about.backgroundAndStory.logoBackgroundStory.content.p2.bold')}</h4>
          <p>{translate('about.backgroundAndStory.logoBackgroundStory.content.p2.text')}</p>
        </div>
        <div className="logo-background-story-text-section">
          <h4>{translate('about.backgroundAndStory.logoBackgroundStory.content.p3.bold')}</h4>
          <p>{translate('about.backgroundAndStory.logoBackgroundStory.content.p3.text')}</p>
        </div>
        <p>{translate('about.backgroundAndStory.logoBackgroundStory.content.p4')}</p>
      </div>
    </div>
  );
};

const BackgroundStoryPage = () => {
  const { translate } = useTranslation();

  return (
    <div className="background-story-page">
      <section className="background-story-page-header">
        <h2>{translate('about.backgroundAndStory.preTitle')}</h2>
        <h1>{translate('about.backgroundAndStory.title')}</h1>
      </section>
      <Brandstory />
      <LogoBackgroundStory />
    </div>
  );
};

export default BackgroundStoryPage;
