import React from 'react';
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player';
import { useTranslation } from '../../contexts/TranslationContext';
import './MoreServicesInfoPage.css';

const MoreServicesInfoPage = () => {
  const options = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1,
      controls: 1,
    },
  };

  const _onReady = (event) => {
    event.target.pauseVideo();
  };

  const { translate } = useTranslation();

  return (
    <div className="more-services-info-page">
      <div className="more-services-info-page-header">
        <h1>{translate('services.moreServicesInfo.title')}</h1>
        <h3>{translate('services.moreServicesInfo.subTitle')}</h3>
      </div>
      <div className="more-services-info-page-content-container">
        <div className="more-services-info-page-content">
          <div className="youtube-player-container">
            <YouTube videoId="2ncMS49-jZU" options={options} onReady={_onReady}/>
          </div>
          <hr />
          <div className="more-services-info-page-section">
            <h2>{translate('services.moreServicesInfo.content.subconsciousIntegration.header')}</h2>
            <h5>{translate('services.moreServicesInfo.content.subconsciousIntegration.intro.caption')}</h5>
            <div className="youtube-player-container">
              <YouTube videoId="L2T7w6wnBBA" options={options} onReady={_onReady}/>
            </div>
            <h5>{translate('services.moreServicesInfo.content.subconsciousIntegration.caseSharing.caption')}</h5>
            <div className="youtube-player-container">
              <YouTube videoId="hCDrt2tiQBA" options={options} onReady={_onReady}/>
            </div>
          </div>
          <div className="more-services-info-page-section">
            <h2>{translate('services.moreServicesInfo.content.singingBowls.header')}</h2>
            <h5>{translate('services.moreServicesInfo.content.singingBowls.caption')}</h5>
            <div className="react-player">
              <ReactPlayer
                url={process.env.PUBLIC_URL + '/singing_bowls_service.mp4'}
                controls={true}
                width='70%'
                height='70%'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreServicesInfoPage;
