import React from 'react';
import ServiceCard from '../../shared/ServiceCard/ServiceCard';
import { useTranslation } from '../../contexts/TranslationContext';
import './ServicesPage.css';

const Services = () => {
  const { translate, getConstants } = useTranslation();
  const servicesData = getConstants('servicesData');

  return (
    <div className="services-container">
      <h1>{translate('servicesPage.header.title')}</h1>
      <div className="services-grid">
        {servicesData.map((service, index) => (
          <ServiceCard key={index} service={{ ...service, index }} />
        ))}
      </div>
    </div>
  );
};

export default Services;
