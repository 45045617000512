import React, {
  createContext,
  useContext,
  useState,
  useEffect
} from 'react';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  // Initialize `cartItems` with sessionStorage value if available
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = window.sessionStorage.getItem('MY_CART_ITEMS_SPIRITUALSCIENCE_INS');
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });

  // Update sessionStorage whenever cartItems change
  useEffect(() => {
    window.sessionStorage.setItem('MY_CART_ITEMS_SPIRITUALSCIENCE_INS', JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (product, quantity = 1) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find(item => item.id === product.id);
      if (existingItem) {
        return prevItems.map(item => item.id === product.id
          ? { ...item, quantity: item.quantity + quantity }
          : item
        );
      }
      return [...prevItems, { ...product, quantity }];
    });
  };

  const removeFromCart = (productId) => {
    setCartItems((prevItems) => prevItems.filter(item => item.id !== productId));
  };

  const updateQuantity = (productId, quantity) => {
    setCartItems((prevItems) =>
      prevItems.map(item => item.id === productId ? { ...item, quantity } : item)
    );
  };

  const clearCart = () => setCartItems([]);

  const totalAmount = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, updateQuantity, clearCart, totalAmount }}>
      {children}
    </CartContext.Provider>
  );
};
