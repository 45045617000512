import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../../../components/contexts/CartContext';
import { displayNumWithCommas } from '../../../utils';
import { flyToCart } from '../utils';
import QuickViewModal from '../../../shared/QuickViewModal/QuickViewModal';
import ProductQuickView from '../ProductQuickView';
import Button from '../../../shared/Button';
import { useTranslation } from '../../../contexts/TranslationContext';
import './ProductGrid.css';

const ProductGrid = ({ product, isDisplayOnHover = true, triggerHeader }) => {
  const { translate } = useTranslation();
  const { id, name, price, imgPath, ribbon } = product;

  const [showQuickView, setShowQuickView] = useState(false);
  const [isImageHovered, setIsImageHovered] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [flyToCartStyles, setFlyToCartStyles] = useState({});
  const [showFlyAnimation, setShowFlyAnimation] = useState(false);

  const productRef = useRef(null);
  const navigate = useNavigate();
  const { addToCart } = useCart();

  useEffect(() => {
    const detectTouchDevice = () => {
      setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
    };
    detectTouchDevice();
  }, []);

  useEffect(() => {
    const detectTouchDevice = () => {
      if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
        setIsTouchDevice(true);
      }
    };
    detectTouchDevice();
  }, []);

  const toggleQuickView = () => {
    setShowQuickView(!showQuickView);
  };

  const handleQuickViewClick = (e) => {
    e.stopPropagation();
    toggleQuickView();
  };

  const handleImageClick = (e) => {
    e.stopPropagation();
    if (!isTouchDevice) {
      toggleQuickView();
    } else {
      navigate(`/product/${id}`);
    }
  };

  const handleProductClick = () => {
    navigate(`/product/${id}`);
  };

  const handleAddToCart = (e) => {
    e.stopPropagation();
    addToCart(product);
    flyToCart(
      productRef.current,
      setFlyToCartStyles,
      setShowFlyAnimation,
      triggerHeader
    );
  };

  return (
    <div className="product" onClick={handleProductClick} ref={productRef}>
      <div
        className="product-image-container"
        onMouseEnter={() => setIsImageHovered(true)}
        onMouseLeave={() => setIsImageHovered(false)}
      >
        <img
          src={process.env.PUBLIC_URL + imgPath[0]}
          alt={"Product " + id}
          className={`product-image ${isImageHovered ? 'zoom-in' : ''}`}
          onClick={handleImageClick}
        />
        <div className="quick-view-banner" onClick={handleQuickViewClick}>
          {translate('shared.productGrid.quickViewBanner')}
        </div>
        {ribbon && <div className="product-ribbon">{ribbon}</div>}
      </div>
      <h3>{name}</h3>
      <hr className="product-grid-divider" />
      <p className="product-price">${displayNumWithCommas(price)}</p>
      <Button
        className={`product-button ${isDisplayOnHover ? 'display-on-hover' : ''}`}
        size="small"
        onClick={handleAddToCart}
      >
        {translate('shared.productGrid.addToCartButton')}
      </Button>

      {!isTouchDevice && showQuickView && (
        <QuickViewModal
          content={<ProductQuickView product={product} triggerHeader={triggerHeader} toggleQuickView={toggleQuickView} />}
          onClose={toggleQuickView}
        />
      )}

      {showFlyAnimation && (
        <img
          src={process.env.PUBLIC_URL + imgPath[0]}
          alt="Fly to cart"
          className="fly-to-cart"
          style={flyToCartStyles}
        />
      )}
    </div>
  );
};

export default ProductGrid;
