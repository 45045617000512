import React from 'react';
import ReactPlayer from 'react-player';
import { useTranslation } from '../../contexts/TranslationContext';
import './SpiritualCommunication.css';

const SpiritualCommunication = () => {
  const { translate } = useTranslation();

  return (
    <div className="spiritual-communication-section">
      <h2>
        {translate('products.category.spiritualCommunication.header')}
      </h2>
      <div className="react-player">
        <ReactPlayer
          url={process.env.PUBLIC_URL + '/inner-child-tvb.mp4'}
          controls={true}
          width='100%'
          height='100%'
        />
      </div>
    </div>
  );
};

export default SpiritualCommunication;