import React from 'react';
import { useTranslation } from '../../contexts/TranslationContext';
import Header from './Header';
import ProductsList from './ProductsList';
import './ProductsPage.css';

const ProductsPage = ({ triggerHeader }) => {
  const { getConstants } = useTranslation();
  const productData = getConstants('productData');

  return (
    <div className="products-page">
      <Header />
      <ProductsList products={productData} triggerHeader={triggerHeader} />
    </div>
  );
};

export default ProductsPage;