import React from 'react';
import { useTranslation } from '../../contexts/TranslationContext';
import './Hero.css';

const Hero = () => {
  const { translate } = useTranslation();

  return (
    <div className="hero">
      <div className="hero-text">
        <h1>{translate('home.hero.title')}</h1>
        <p>{translate('home.hero.subtitle')}</p>
      </div>
    </div>
  );
};

export default Hero;
