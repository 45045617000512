import React from 'react';
import { useTranslation } from '../contexts/TranslationContext';
import './LanguageSwitcher.css';

const LanguageSwitcher = () => {
  const { switchLanguage, language } = useTranslation();

  return (
    <div className="language-switcher">
      <ul>
        <li className={language === 'en' ? '' : 'unselected'}>
          <button
            onClick={() => switchLanguage('en')}
            disabled={language === 'en'}
          >
            ENG
          </button>
        </li>
        <li className={language === 'zh' ? '' : 'unselected'}>
          <button
            onClick={() => switchLanguage('zh')}
            disabled={language === 'zh'}
          >
            中文
          </button>
        </li>
      </ul>
    </div>
  );
};

export default LanguageSwitcher;
