import React from 'react';
import { useTranslation } from '../../contexts/TranslationContext';
import './AboutUsPage.css';

const AboutUs = () => {
  const { translate } = useTranslation();

  return (
    <div className="about-us-page">
      <section className="about-us-header">
        <h2>{translate('aboutUsPage.preTitle')}</h2>
        <h1>{translate('aboutUsPage.title')}</h1>
        <p>{translate('aboutUsPage.subtitle')}</p>
      </section>

      <section className="grid-layout">
        <div className="grid-item image-column">
          <img
            src={process.env.PUBLIC_URL + '/pallas_profile_pic_crop.jpeg'}
            alt="Founder image"
            className="person-image"
          />
        </div>
        <div className="grid-item text-column">
          <h2>{translate('aboutUsPage.ourMission.title')}</h2>
          <p>{translate('aboutUsPage.ourMission.content.p1')}</p>
          <p>{translate('aboutUsPage.ourMission.content.p2')}</p>
        </div>

        <div className="grid-item text-column">
          <h2>{translate('aboutUsPage.aboutPallas.title')}</h2>
          <p>{translate('aboutUsPage.aboutPallas.content.p1')}</p>
          <ul>
            <li>{translate('aboutUsPage.aboutPallas.content.list.item1')}</li>
            <li>{translate('aboutUsPage.aboutPallas.content.list.item2')}</li>
            <li>{translate('aboutUsPage.aboutPallas.content.list.item3')}</li>
            <li>{translate('aboutUsPage.aboutPallas.content.list.item4')}</li>
            <li>{translate('aboutUsPage.aboutPallas.content.list.item5')}</li>
            <li>{translate('aboutUsPage.aboutPallas.content.list.item6')}</li>
            <li>{translate('aboutUsPage.aboutPallas.content.list.item7')}</li>
            <li>{translate('aboutUsPage.aboutPallas.content.list.item8')}</li>
          </ul>
        </div>
        <div className="grid-item image-column">
        <img src={process.env.PUBLIC_URL + '/about-us-collage.png'} alt="Pallas' journey" className="large-image" />
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
