import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from '../../contexts/TranslationContext';
import DetailsView from '../../shared/DetailsViewPage';
import SubconsciousIntegration from '../../categories/SubconsciousIntegration';
import SingingBowls from '../../categories/SingingBowls';

const ServiceDetails = () => {
  const { translate, getConstants } = useTranslation();
  const servicesData = getConstants('servicesData');
  const { serviceId } = useParams();
  const service = servicesData.find(
    (service) => service.id === parseInt(serviceId));
  
  const navigate = useNavigate();
  const onServiceDetailsViewBtnClicked = (e) => {
    e.stopPropagation();
    const serviceData = { name: service.name, id: service.id };
    navigate('/services/book-now', { state: serviceData });
  };

  return (
    <div className="service-details-view-container">
      <DetailsView
        data={service}
        detailsViewBtnText={translate('servicesPage.serviceDetails.bookNow.button')}
        onDetailsViewBtnClicked={onServiceDetailsViewBtnClicked}
      />
      {service.category && service.category === 'Subconscious Integration' &&
        <SubconsciousIntegration />}
      {service.category && service.category === 'Singing Bowls' &&
        <SingingBowls />}
    </div>
  );
};

export default ServiceDetails;
